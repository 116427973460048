import React, { useEffect, useMemo, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { todoService } from "app/services";
import moment from "moment";
import TodoStore from "./store";
import { useTable } from "app/hooks/useTable";
import { ITodo } from "app/services/todo.service";
import { GridTable } from "app/partials/layout/GridTable";
import { useIntl } from "react-intl";
import { hasPermission, PermissionsGate } from "app/permission/PermissionsGate";
import ShowTodo from "app/pages/Todos/Modal/show";
import ConfirmDialog from "app/partials/dialogues/confirmDialogue";
import { usePermissions } from "../../hooks/useRole";
import TodoFilter from "./filter";
import { IUser } from "../../interfaces";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import EditIcon from "../../partials/icons/EditIcon";
import QuickCards from "./quickCards";
import DeleteIcon from "../../partials/icons/DeleteIcon";
import UserAvatar from "../../partials/layout/UserAvatar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const Todos = () => {
  const intl = useIntl();
  const permissions = usePermissions();

  let { search } = useLocation();
  let query = useMemo(() => new URLSearchParams(search), [search]);

  const id = query.get("id");
  const category = query.get("category") ?? "";
  const todos = query.get("todos") ?? "";
  const events = query.get("events") ?? "";
  const not_expired = query.get("not_expired") ?? "";
  const expired = query.get("expired") ?? "";
  const { user: user_auth } = useSelector(({ auth }: RootState) => auth);

  let _query: { name: string; value: string | number }[] = [
    {
      name: "per_page",
      value: 5,
    },
  ];

  if (expired) {
    _query.push({
      name: "expired",
      value: expired,
    });
  }

  if (not_expired) {
    _query.push({
      name: "not_expired",
      value: not_expired,
    });
  }

  if (events) {
    _query.push({
      name: "events",
      value: events,
    });
  }

  if (todos) {
    _query.push({
      name: "todos",
      value: todos,
    });
  }

  if (category) {
    _query.push({
      name: "category",
      value: category,
    });
  }

  const { data, xhrLoading, refresh, remove, updateQuery, setQuery, queries } =
    useTable<ITodo>({
      fetch: todoService.getAll,
      delete: todoService.delete,
      query: _query,
    });

  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      align: "center",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.USERS" }),
      field: "users",
      renderCell: (params) => {
        return params.row.users?.map((user: IUser) => {
          if (!user) return;
          return <UserAvatar user={user} />;
        });
      },
      minWidth: 205,
    },
    // {
    //   headerName: intl.formatMessage({ id: "STANDARD.STATUS" }),
    //   field: "status",
    //   renderCell: (params) => {
    //     return (
    //       <div className="d-flex flex-row align-items-center">
    //         <i
    //           className="fa fa-genderless mr-1 fs-40"
    //           style={{ color: params.row?.status?.color }}
    //         />
    //         <span>{params.row?.status?.title[intl.locale] ?? ""}</span>
    //       </div>
    //     );
    //   },
    //   minWidth: 220,
    // },
    {
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      field: "title",
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row align-items-center">
            {/*<i*/}
            {/*  className="fa fa-genderless mr-1 fs-40"*/}
            {/*  style={{ color: params.row?.category?.color }}*/}
            {/*/>*/}

            <Tooltip title={params.row?.title}>
              <Typography variant="inherit">
                {params.row?.title?.substring(0, 16) + ".."}
              </Typography>
            </Tooltip>
          </div>
        );
      },
      minWidth: 220,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "date",
      renderCell: (params) => {
        return (
          <div className="kt-timeline-v2 todo-timeline ps ps--active-y">
            <div className="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-success" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  {params.row?.date
                    ? moment.unix(params.row?.date).local().format("DD/MM/YYYY")
                    : intl.formatMessage({
                        id: "NO_DATA",
                        defaultMessage: "No data",
                      })}
                </div>
              </div>
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-danger todo-timeline-circle-icon" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  {params.row?.deadline_date
                    ? moment
                        .unix(params.row?.deadline_date)
                        .local()
                        .format("DD/MM/YYYY")
                    : intl.formatMessage({
                        id: "NO_DATA",
                        defaultMessage: "No data",
                      })}
                </div>
              </div>
            </div>
          </div>
        );
      },
      minWidth: 180,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.TYPE" }),
      field: "type",
      renderCell: (params) => {
        return (
          <Chip
            label={
              params.row?.type?.title && params.row?.type?.title[intl.locale]
            }
            sx={{
              bgcolor: "primary.main",
              color: "#fff",
              height: 28,
              width: 66,
              borderRadius: "8px",
            }}
          />
        );
      },
      minWidth: 120,
    },
    {
      field: "category",
      headerName: intl.formatMessage({ id: "STANDARD.CATEGORY" }),
      renderCell: (params) => {
        return params.row?.category?.title ? (
          <Tooltip title={params.row?.category?.title[intl.locale] ?? ""}>
            <Typography variant="inherit">
              {params.row?.category?.title[intl.locale]
                ? params.row?.category?.title[intl.locale]?.substring(0, 16) +
                  ".."
                : ""}
            </Typography>
          </Tooltip>
        ) : null;
      },
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"todos"} scope={"read"}>
              <IconButton
                onClick={() => show(params.row?._id)}
                data-cy-class={"todo_show"}
                data-cy={params.row?._id}
              >
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"todos"} scope={"delete"}>
              {user_auth?.role?.slug === "moderator" ||
              user_auth?.role?.slug === "super-admin" ||
              params.row?.created_by?._id === user_auth?._id ? (
                <IconButton
                  onClick={() => handleClick(params.row?._id)}
                  data-cy-class={"todo_remove"}
                  data-cy={params.row?._id}
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                <></>
              )}
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [todoId, setTodoId] = useState<string | null>(id);

  const show = (_id: string) => {
    setTodoId(_id);
  };

  const [confirmId, setConfirmId] = useState<string | null>(null);

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };
  const history = useHistory();

  return (
    <>
      <PermissionsGate section={"todos"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete todo?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(confirmId);
            }}
          >
            Are you sure you want to delete this todo?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"todos"} scope={"read"}>
        {todoId ? (
          <ShowTodo
            _id={todoId}
            open={Boolean(todoId)}
            setDialogue={() => {
              setTodoId(null);
              query.delete("id");
              history.replace({
                search: query.toString(),
              });
            }}
            onDone={refresh}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"todos"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "todos", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <TodoStore
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,
            // quickCards: [
            //   {
            //     title: intl.formatMessage({ id: "QUICK_CARD_TODOS" }),
            //     onClick: (isActive: boolean) =>
            //       resetCardQuery(isActive, "todos"),
            //     icon: "fi-sr-inbox",
            //     value: quickCardsCount ? quickCardsCount.todos : 0,
            //   },
            //   {
            //     title: intl.formatMessage({ id: "QUICK_CARD_EVENTS" }),
            //     onClick: (isActive: boolean) =>
            //       resetCardQuery(isActive, "events"),
            //     icon: "fi-sr-inbox",
            //     value: quickCardsCount ? quickCardsCount.events : 0,
            //   },
            //   {
            //     title: intl.formatMessage({ id: "QUICK_CARD_EXPIRED" }),
            //     onClick: (isActive: boolean) =>
            //       resetCardQuery(isActive, "expired"),
            //     icon: "fi-sr-time-delete",
            //     value: quickCardsCount ? quickCardsCount.expired : 0,
            //   },
            //   {
            //     title: intl.formatMessage({ id: "QUICK_CARD_NOT_STARTED" }),
            //     onClick: (isActive: boolean) =>
            //       resetCardQuery(isActive, "not_started"),
            //     icon: "fi-sr-calendar-clock",
            //     value: quickCardsCount ? quickCardsCount.not_started : 0,
            //   },
            // ],
            customQuickCards: (
              <QuickCards queries={queries} setQuery={setQuery} />
            ),
            filters: (props: { show: boolean }) => {
              return (
                <TodoFilter
                  show={props.show}
                  updateQuery={updateQuery}
                  queries={queries}
                  setQuery={setQuery}
                />
              );
            },
          }}
          query={(queries) => updateQuery(queries)}
          search={(queries) => updateQuery(queries)}
        />
      </PermissionsGate>
    </>
  );
};

export default Todos;
