import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { queryToString } from "../utils/http";

export interface IDocument {
  _id: string;
  name: string;
  type: "document" | "img" | "mp3";
  _created_at: string;
  _updated_at: string;
}

const INDEX = API_ROUTES.documents;
const SHOW = API_ROUTES.document;
const DELETE = API_ROUTES.document_delete;

export interface IDocumentService {
  get(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IDocument> | IRestApiError>;
  upload(data: any): Promise<IRestApiResource<any> | IRestApiError>;
  getOne(_id: string): Promise<IRestApiResource<any> | IRestApiError>;
  delete(_id: string): Promise<IRestApiCollection<IDocument> | IRestApiError>;
}

const documentService: IDocumentService = {
  async get(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(INDEX)}?${queriesString}`, "get");
  },

  async upload(data: any) {
    return http(getRoute(INDEX), "post", data);
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(DELETE).replace(
        ":document",
        _id.toString()
      )}${queriesString}`,
      "delete"
    );
  },

  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },
};

export { documentService };
