import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Controls from "app/widgets/uncontrolled";
import * as _setting from "app/store/ducks/setting.duck";
import { Skeleton } from "@mui/lab";
import { knowledgebaseService } from "app/services";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { RootState } from "app/store/store";
import Snackbar from "app/widgets/Snackbar";
import { TransitionProps } from "@mui/material/transitions";
import { FormStateValues } from "./store";
import { FormattedMessage, useIntl } from "react-intl";
import { Ii18nState } from "../../../store/ducks/i18n.duck";
import { ISettingsState } from "app/store/ducks/setting.duck";
import AttachmentsInfo from "../../Cases/show/parts/attachmentsInfo";
import moment from "moment/moment";
import { ISetting } from "../../../services/setting.service";
import { makeStyles } from "@material-ui/core";

interface IFormProps {
  knowledgebaseCategoryId: string;
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
  dropzone: {
    "& .container": {
      paddingRight: "0!important",
      paddingLeft: "0!important",
      "& div": {
        padding: "70px!important",
        borderColor: "#E6E6E6!important",
        borderRadius: 2,
        backgroundColor: "#E6E6E6",
        "& p": {
          marginBottom: "0px!important",
        },
      },
      "& aside": {
        display: "none",
      },
    },
  },
}));

const ShowKnowledgebaseItem = (props: IFormProps) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>();
  const classes = useStyles();

  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [item, setItem] = useState<any>(null);
  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    form.values.files = files?.attachments?.map((file: any) => file._id);
  }, [files]);

  useEffect(() => {
    knowledgebaseService
      .getOneItem(props.knowledgebaseCategoryId, props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: item } = data;
        setItem(item);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id, props.knowledgebaseCategoryId]);

  useEffect(() => {
    if (item) {
      form.values.title = item.title;
      form.values.description = item.description;

      form.values.files = files?.file_ids?.map((file: any) => file._id);

      setFiles(item.documents);
    }
  }, [item]);

  const handleClose = () => {
    props.setDialogue(false);
    setItem(null);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    knowledgebaseService
      .updateItem(props.knowledgebaseCategoryId, props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          boxShadow: "none",
          pr: 2,
        }}
      >
        <Toolbar>
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage
              id={"KNOWLEDGEBASEITEM.SHOW"}
              defaultMessage={"KNOWLEDGEBASEITEM.SHOW"}
            />
          </Typography>
          <Button
            form={"item_form"}
            autoFocus
            color="inherit"
            type={"submit"}
            sx={{
              backgroundColor: "#0D99FF",
              borderRadius: 2,
              color: "#ffffff",
            }}
          >
            <FormattedMessage
              id={"DIALOGUE.SAVE"}
              defaultMessage={"DIALOGUE.SAVE"}
            />
          </Button>
        </Toolbar>
      </Box>
      <DialogContent>
        <form
          id={"item_form"}
          onSubmit={(e) => form.handleSubmit(e, handleSave)}
        >
          <Grid container spacing={2} mb={2}>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && item ? (
                <Controls.Input
                  name={"title"}
                  label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                  defaultValue={item.title}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.title = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"title"}
                    label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && item ? (
                <Controls.Editor
                  name={"description"}
                  label={intl.formatMessage({ id: "STANDARD.DESCRIPTION" })}
                  data={item?.description}
                  onChange={(data: any) => {
                    form.values.description = data;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    rows={5}
                    name={"description"}
                    label={intl.formatMessage({ id: "STANDARD.DESCRIPTION" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && item ? (
                <Box className={classes.dropzone}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    Files
                  </Typography>
                  <AttachmentsInfo setParams={setFiles} params={item} />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Select
                    name={"files"}
                    options={[]}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSettings: () => dispatch(_setting.actions.get()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowKnowledgebaseItem);
