import React, { FC, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useIntl } from "react-intl";

type TextAreaComposerType = {
  message: string;
  sentMessage: Function;
};

export const TextArea: FC<TextAreaComposerType> = (props) => {
  const intl = useIntl();
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    if (!props.message) setValue("");
    else setValue(props.message);
  }, [props.message]);

  useEffect(() => {
    props.sentMessage(value);
  }, [value]);

  return (
    <TextField
      InputProps={{ disableUnderline: true }}
      fullWidth
      // style={{ height: "20px!important" }}
      placeholder={intl.formatMessage({ id: "MESSAGE.ENTER_MESSAGE" })}
      multiline
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      sx={{
        // maxHeight: "61.5px",
        overflowY: "auto",
        textarea: {
          resize: "none",
        },
      }}
      // inputRef={(input) => input && input.focus()}
      variant="standard"
    />
  );
};

export default TextArea;
