import React, { FC, useEffect, useRef, useState } from "react";
import { IColumns } from "../../../partials/layout/DataTable";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import { caseService } from "app/services";
import { useParams } from "react-router";
import moment from "moment";
import NoteStore from "../store/noteStore";
import { useTable } from "../../../hooks/subresource/useTable";
import { GridTable } from "../../../partials/layout/GridTable";
import ShowCaseNote from "./showNote";
import { useIntl } from "react-intl";
import { PermissionsGate } from "../../../permission/PermissionsGate";
import ConfirmDialog from "../../../partials/dialogues/confirmDialogue";
import EditIcon from "../../../partials/icons/EditIcon";
import { makeStyles } from "@material-ui/core";
import UserAvatar from "../../../partials/layout/UserAvatar";
import { ICase } from "../../../services/case.service";
import NoteFilter from "./filters/noteFilter";
import Snackbar from "../../../widgets/Snackbar";

const useStyles = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
    width: 500,
  },
}));

const Note: FC<ICase> = (props) => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const classes = useStyles();

  const { data, xhrLoading, refresh, updateQuery, queries, setQuery, remove } =
    useTable<any>({
      fetch: caseService.getNotes,
      delete: caseService.deleteNote,
      param: id,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });

  const [confirmId, setConfirmId] = useState<string | null>(null);
  const [focusType, setFocusType] = useState<string | undefined>(
    props.focus_type?.title[intl.locale]
  );
  const [focusArea, setFocusArea] = useState<string | undefined>(
    props.focus_area?.title[intl.locale]
  );
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);

  // @ts-ignore
  const columns: IColumns[] = [
    {
      field: "_id",
      headerName: "ID",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
    },
    {
      field: "title",
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      minWidth: 200,
    },
    {
      field: "_created_at",
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      valueGetter: (params) => {
        return moment
          .unix(params.row?._created_at)
          .local()
          .format("DD/MM/YYYY");
      },
      minWidth: 250,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.CREATED_BY" }),
      field: "created_by",
      renderCell: (params) => {
        return <UserAvatar user={params.row?.created_by} />;
      },
      minWidth: 220,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            {params.row?.description_org && (
              <PermissionsGate section={"notes"} scope={"read"}>
                <Tooltip
                  placement={"left-end"}
                  classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                  arrow
                  title={
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ mt: 1, mx: 2 }}>
                        <Grid container spacing={1}>
                          <Typography variant={"h6"} sx={{ width: "100%" }}>
                            {params.row?.title}
                          </Typography>
                          <Typography
                            variant={"body1"}
                            sx={{
                              wordBreak: "break-word",
                              overflowWrap: "break-word",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {params.row?.description_org}
                          </Typography>
                        </Grid>
                      </Box>
                    </Box>
                  }
                >
                  <IconButton
                    onClick={() => show(params.row?._id)}
                    data-cy-class={"case_note_show"}
                    data-cy-case-id={id}
                    data-cy-note-id={params.row?._id}
                  >
                    <i
                      style={{ display: "flex", color: "#2FFF93" }}
                      className="fi fi-sr-eye"
                    />
                  </IconButton>
                </Tooltip>
              </PermissionsGate>
            )}
            <PermissionsGate section={"notes"} scope={"read"}>
              <Tooltip
                placement={"left-end"}
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                arrow
                title={
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ mt: 1, mx: 2 }}>
                      <Grid container spacing={1}>
                        <Typography variant={"h6"} sx={{ width: "100%" }}>
                          {params.row?.title}
                        </Typography>
                        <Typography
                          variant={"body1"}
                          sx={{
                            wordBreak: "break-word",
                            overflowWrap: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {params.row?.description}
                        </Typography>
                      </Grid>
                    </Box>
                  </Box>
                }
              >
                <IconButton
                  onClick={() => show(params.row?._id)}
                  data-cy-class={"case_note_show"}
                  data-cy-case-id={id}
                  data-cy-note-id={params.row?._id}
                >
                  <i
                    style={{ display: "flex", color: "#FBB73C" }}
                    className="fi fi-sr-eye"
                  />
                </IconButton>
              </Tooltip>
            </PermissionsGate>
            <PermissionsGate section={"notes"} scope={"read"}>
              <IconButton
                onClick={() => {
                  show(params.row?._id);
                  setFocusType(props?.focus_type?.title[intl.locale]);
                  setFocusArea(props?.focus_area?.title[intl.locale]);
                }}
                data-cy-class={"case_note_show"}
                data-cy-case-id={id}
                data-cy-note-id={params.row?._id}
              >
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"notes"} scope={"delete"}>
              <IconButton
                onClick={() => handleClick(params.row?._id)}
                data-cy-class={"case_note_delete"}
                data-cy-case-id={id}
                data-cy-note-id={params.row?._id}
              >
                <DeleteIcon sx={{ color: "error.main" }} />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [noteId, setNoteId] = useState<string | null>(null);

  const show = (note_id: string) => {
    setNoteId(note_id);
  };

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  const handleExport = () => {
    caseService
      .exportNotes(
        id,
        queries?.filter((q) => q.name !== "per_page")
      )
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        const url = "data:application/pdf;base64," + data.data;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `act_${moment(new Date()).format("DD-MM-YYYY")}_${moment(
            new Date()
          ).format("HH:mm")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.DOWNLOADED" }));
      });
  };

  return (
    <>
      <PermissionsGate section={"notes"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete note?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(id, confirmId);
            }}
          >
            Are you sure you want to delete this note?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"notes"} scope={"read"}>
        {noteId ? (
          <ShowCaseNote
            _case={props._id}
            focus_type={focusType}
            focus_area={focusArea}
            _id={noteId}
            open={Boolean(noteId)}
            setDialogue={() => setNoteId(null)}
            onDone={refresh}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"notes"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel: (props: { open: boolean; close: () => void }) => {
              return (
                <NoteStore
                  _id={id}
                  open={props.open}
                  focus_type={focusType}
                  focus_area={focusArea}
                  setDialogue={props.close}
                  onDone={refresh}
                />
              );
            },
            filters: (props: { show: boolean }) => {
              return (
                <NoteFilter
                  show={props.show}
                  queries={queries}
                  setQuery={setQuery}
                  updateQuery={updateQuery}
                />
              );
            },
            exportPanel: () => handleExport(),
          }}
          search={(queries) => updateQuery(queries)}
          quickSearchToolbar={true}
        />
      </PermissionsGate>
    </>
  );
};

export default Note;
