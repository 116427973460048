import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";
import { Routes } from "./app/router/Routes";
import { LayoutSplashScreen, ThemeProvider } from "./_metronic";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./app/widgets/Snackbar";
import SocketProvider from "./app/providers/SocketProvider";
import ChatProvider from "./app/providers/ChatProvider";
import { BackdropUI } from "./app/widgets/Backdrop";
import SettingsLoading from "./app/providers/SettingsLoading";
import I18nProvider from "./app/providers/I18nProvider";
import {
  FixedRightSideBar,
  FixedSideButton,
} from "./app/partials/layout/FixedSideButton";
import moment from "moment/moment";
import { requestForToken } from "./firebase";
import { getAccessToken, getBusiness } from "./app/lib/http";
import { getRoute } from "./app/config/apiRoutes";
import NotificationModal from "./app/partials/dialogues/notification";

export default function App({ store, persistor, basename }) {
  // this is only for dev env. delete it from
  moment.updateLocale("en", { week: { dow: 1 } });

  const consoleError = console.error.bind(console);
  console.error = (message, ...args) => {
    if (typeof message === "string" && message.startsWith("[React Intl]")) {
      return;
    }
    consoleError(message, ...args);
  };

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        <SettingsLoading>
          <ThemeProvider>
            {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
            <React.Suspense fallback={<LayoutSplashScreen />}>
              {/* Override `basename` (e.g: `homepage` in `package.json`) */}
              <BrowserRouter basename={basename}>
                {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                <LastLocationProvider>
                  {/* Provide Metronic theme overrides. */}

                  <BackdropUI />
                  <FixedSideButton />
                  {/* Provide `react-intl` context synchronized with Redux state.  */}
                  <I18nProvider>
                    <SocketProvider>
                      <ChatProvider>
                        <SnackbarProvider
                          maxSnack={3}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <SnackbarUtilsConfigurator />
                          <NotificationModal />
                          <Routes />
                        </SnackbarProvider>
                      </ChatProvider>
                    </SocketProvider>
                  </I18nProvider>
                </LastLocationProvider>
              </BrowserRouter>
            </React.Suspense>
          </ThemeProvider>
        </SettingsLoading>
      </PersistGate>
    </Provider>
  );
}
