import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import * as _setting from "app/store/ducks/setting.duck";
import {
  Grid,
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  Typography,
  Box,
  Avatar,
  Button,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { RootState } from "app/store/store";
import { TransitionProps } from "@mui/material/transitions";
import { FormattedMessage, useIntl } from "react-intl";
import Controls from "../../widgets/uncontrolled";
import { Skeleton } from "@mui/lab";
import { makeStyles } from "@material-ui/core";
import Snackbar from "../../widgets/Snackbar";
import { Ii18nState } from "../../store/ducks/i18n.duck";
import { caseService } from "../../services";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";

interface IFormProps {
  _plan: string;
  _case: string;
  answer: string;
  question: { [key in string]: string };
  onDone: Function;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
}

type FormStateValues = {
  answer: string;
  //documents: Array<File>;
};

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  activityLine: {
    "&, &:before": {
      content: "",
      position: "absolute",
      left: "11px",
      width: "0.214rem",
      top: "42px",
      bottom: "5px",
      height: "calc(22% - 5px)",
      backgroundColor: "#E7E9F5",
    },
  },
}));

const EditPlanAnswers = (props: IFormProps) => {
  const form = useForm<FormStateValues>();
  const classes = useStyles();
  const intl = useIntl();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );

  const history = useHistory();

  const [data, setData] = useState<{
    _id: string;
    question: { [key in string]: string };
    answer: string;
    activities: any;
  }>();
  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    caseService
      .updatePlanAnswer(props._case, props._plan, props.answer, validated)
      .then((data) => {
        if ("error" in data) throw new Error(data.error.message);

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
        setTimeout(() => {
          props.setDialogue(false);
        }, 1000);
        props.onDone();
      });
  };

  useEffect(() => {
    if (data) {
      form.values.answer = data.answer;
    }
  }, [data]);

  useEffect(() => {
    caseService
      .getPlanAnswer(props._case, props._plan, props.answer)
      .then((data) => {
        if ("error" in data) throw new Error(data.error.message);

        const { data: answer } = data;

        setData(answer);
      });
  }, [props.answer, props.question]);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        sx={{
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#E0F2FF",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              <FormattedMessage id={"CASE.EDIT"} defaultMessage={"CASE.EDIT"} />
            </Typography>
            <Button
              autoFocus
              color="inherit"
              type={"submit"}
              form={"answer_form"}
              sx={{
                backgroundColor: "#0D99FF",
                borderRadius: 2,
                color: "#ffffff",
              }}
            >
              <FormattedMessage
                id={"DIALOGUE.SAVE"}
                defaultMessage={"DIALOGUE.SAVE"}
              />
            </Button>
          </Toolbar>
        </Box>
        <DialogContent>
          <form
            id={"answer_form"}
            onSubmit={(e) => {
              handleSave();
              e.preventDefault();
            }}
          >
            <Grid container sx={{ mb: 3 }}>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded && data ? (
                  <>
                    <Typography
                      variant={"body1"}
                      sx={{ mb: 0.5, color: "#6D6D6D", fontWeight: "500" }}
                    >
                      Question
                    </Typography>
                    <Typography
                      variant={"body1"}
                      sx={{ color: "#0D99FF", fontWeight: "500" }}
                    >
                      {data.question[intl.locale]} ?
                    </Typography>
                  </>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Select
                      name={"questions"}
                      options={[]}
                      onChange={() => {}}
                      label={""}
                    />
                  </Skeleton>
                )}
              </Grid>
            </Grid>
            <Grid container sx={{ mb: 3 }}>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded && data ? (
                  <>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      Answer
                    </Typography>
                    <Box className={classes.input}>
                      <Controls.Input
                        name={"answer"}
                        rows={5}
                        multiline={true}
                        defaultValue={data.answer}
                        label={""}
                        onChange={(e) => {
                          form.values.answer = e.target.value;
                        }}
                      />
                    </Box>
                  </>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"answer"}
                      onChange={() => {}}
                      label={""}
                    />
                  </Skeleton>
                )}
              </Grid>
            </Grid>
            <>
              <Typography sx={{ fontWeight: 500 }} variant={"body1"}>
                Recent Activities
              </Typography>
              {data?.activities && (
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <Box className=" kt-timeline-v2-forcase">
                    <Box className="kt-timeline-v2__items-forcase">
                      {data?.activities.map((activity: any) => {
                        const description = intl.formatMessage({
                          id: activity.description,
                        });
                        const descriptionArray = description.split("-");
                        const title = descriptionArray[0].replace(
                          "{who}",
                          activity.replacement.title
                        );
                        return (
                          <Box className="kt-timeline-v2__item">
                            <Box className="kt-timeline-v2__item-cricle">
                              <Avatar
                                variant="circular"
                                sx={{ width: 24, height: 24 }}
                                src={activity.replacement.avatar}
                                alt={title}
                              />
                            </Box>
                            <Box className="kt-timeline-v2__item-text kt-padding-top-5">
                              <Typography variant={"body1"}>
                                <b style={{ color: "#556EE6" }}>
                                  <a
                                    onClick={() => {
                                      history.push(
                                        `/users/${activity.replacement.id}`
                                      );
                                    }}
                                  >
                                    {title}
                                  </a>
                                </b>
                                {" - "} {descriptionArray[1]}
                              </Typography>
                              <Typography>
                                {moment
                                  .unix(activity.created_at)
                                  .local()
                                  .format("DD-MM-YYYY HH:mm")}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Grid>
              )}
            </>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: any;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSettings: () => dispatch(_setting.actions.get()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPlanAnswers);
