import React, { FC, useEffect, useState } from "react";
import { Avatar, Card, Typography } from "@mui/material";
import "react-circular-progressbar/dist/styles.css";
import { Box } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from "../../_metronic";
import ReactApexChart from "react-apexcharts";
import ChartCard from "./ChartCard";
import { useIntl } from "react-intl";
import { activitiesService } from "../services";
import { IActivities } from "app/services/activities.service";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { getDate } from "../pages/Chat/rooms/rooms";
import { makeStyles } from "@material-ui/core";
import Link from "@mui/material/Link";
import reactStringReplace from "react-string-replace";

interface IProps {
  for: string;
  for_id: string | undefined;
}

const useStyle = makeStyles(() => ({
  listItem: {
    "& .MuiListItemButton-root": {
      "&:hover": {
        backgroundColor: "#fff!important",
      },
      "&:active": {
        backgroundColor: "#fff!important",
      },
    },
  },
}));

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const Activities: FC<IProps> = (props) => {
  const intl = useIntl();
  const [activities, setActivities] = useState<IActivities[]>([]);
  const classes = useStyle();

  const Title = (replacement: any, index: number) => {
    if (replacement.is_link) {
      switch (replacement.type) {
        case "case":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/cases/${replacement.id}`}
            >
              {replacement.name || "Case Name"}
            </Link>
          );
        case "plan":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/cases/${replacement.case}?section=plan&plan=${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "transaction":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/cases/${replacement.case}?section=finance`}
            >
              {replacement.name}
            </Link>
          );
        case "contract":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/contracts?id=${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "project":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/projects?id=${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "task":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/tasks?id=${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "todo":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/todos?id=${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "incoming_offer":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/incoming-offers`}
            >
              {replacement.name}
            </Link>
          );
        case "note":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/notes`}
            >
              {replacement.name}
            </Link>
          );
        case "who":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/users/${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "column":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/kanban?project=${replacement.project}`}
            >
              {replacement.name}
            </Link>
          );
        default:
          return `Unexpected type: ${replacement.type}`;
      }
    } else {
      return replacement.name;
    }
  };

  const getReplaceDescriptions = (activity: any) => {
    let text = intl.formatMessage({ id: activity.lang_key });
    let replacements = activity.replacements;

    if (text) {
      replacements.forEach((replacement: any, index: number) => {
        // @ts-ignore
        text = reactStringReplace(text, `{${replacement.key}}`, () =>
          Title(replacement, index)
        );
      });
    }

    return text;
  };

  useEffect(() => {
    activitiesService
      .getAll([
        {
          name: "for",
          value: props.for,
        },
        {
          name: "id",
          value: props.for_id ?? "",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }
        const { data: activities } = data;

        setActivities(activities);
      });
  }, []);
  return (
    <ChartCard
      title={intl.formatMessage({
        id: "STANDARD.RECENT_ACTIVITIES",
        defaultMessage: "Resent activities",
      })}
      ratio={"100%"}
      body={
        <PerfectScrollbar
          options={perfectScrollbarOptions}
          style={{
            position: "relative",
            overflowY: "auto", // Ensures vertical scrolling
            width: "100%",
            maxHeight: "400px", // Adjust this value as needed to control scroll area height
            padding: "0 4px",
          }}
        >
          <Box className=" kt-timeline-v2-forcase">
            <Box className="kt-timeline-v2__items-forcase">
              {activities?.map((activity: any) => {
                return (
                  <ListItem
                    className={classes.listItem}
                    alignItems="flex-start"
                    sx={{ padding: "0px" }}
                  >
                    <ListItemButton
                      disableRipple={true}
                      disableTouchRipple={true}
                    >
                      <ListItemAvatar
                        sx={{
                          minWidth: "42px",
                          zIndex: 999,
                          padding: "8px 0px",
                          backgroundColor: "#fff",
                        }}
                        onClick={() => {
                          window.open(`/users/${activity?.user_id?._id}`);
                        }}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: "#0D99FF",
                            width: 32,
                            height: 32,
                          }}
                          src={activity.avatar ? activity.avatar : ""}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        sx={{ mt: "10px!important" }}
                        primary={
                          <Box sx={{ display: "flex" }}>
                            <Box component="div" sx={{ flex: 1 }}>
                              {getReplaceDescriptions(activity)}
                            </Box>
                            {/*<Box>*/}
                            {/*  <CircleIcon*/}
                            {/*    sx={{ width: 6, height: 6, color: "#0D99FF" }}*/}
                            {/*  />*/}
                            {/*</Box>*/}
                          </Box>
                        }
                        secondary={
                          <React.Fragment>
                            {getDate(activity._created_at)}
                          </React.Fragment>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </Box>
          </Box>
        </PerfectScrollbar>
      }
    />
  );
};

export default Activities;
