import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Controls from "app/widgets/uncontrolled";
import { Skeleton } from "@mui/lab";
import { userService } from "app/services";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { RootState } from "app/store/store";
import Snackbar from "app/widgets/Snackbar";
import { TransitionProps } from "@mui/material/transitions";
import { FormattedMessage, useIntl } from "react-intl";
import { ICaseDocumentFolder } from "../../../services/case.service";
import { makeStyles } from "@material-ui/core";
import AttachmentsInfo from "../../Cases/show/parts/attachmentsInfo";

interface IFormProps {
  user: string;
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: string;
  category: string | null;
  files: Array<string>;
  case: string | null;
  users: Array<string>;
};

const useStyles = makeStyles(() => ({
  dropzone: {
    "& .container": {
      paddingRight: "0!important",
      paddingLeft: "0!important",
      "& div": {
        padding: "70px!important",
        borderColor: "#E6E6E6!important",
        borderRadius: 2,
        backgroundColor: "#E6E6E6",
        "& p": {
          marginBottom: "0px!important",
        },
      },
      "& aside": {
        display: "none",
      },
    },
  },
}));

const ShowUserDocumentFolder = (props: IFormProps) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>();
  const classes = useStyles();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [documentFolder, setDocumentFolder] = useState<ICaseDocumentFolder>();
  const { folder_categories } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    form.values.files = files?.attachments?.map((file: any) => file._id);
  }, [files]);

  useEffect(() => {
    userService
      .getDocFolder(props.user, props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: documentFolder } = data;
        setDocumentFolder(documentFolder);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props.user, props._id]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    userService
      .updateDocFolder(props.user, props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (documentFolder) {
      form.values.title = documentFolder?.title;
      form.values.category = documentFolder?.category._id;
      form.values.files = files?.file_ids?.map((file: any) => file._id);

      setFiles(documentFolder.documents);
    }
  }, [documentFolder]);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#f5f5f5",
            boxShadow: "none",
            pr: 2,
          }}
        >
          <Toolbar>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              <FormattedMessage
                id={"CASE.DOCUMENT.SHOW"}
                defaultMessage={"CASE.DOCUMENT.SHOW"}
              />
            </Typography>
            <Button
              form={"user_document_form"}
              autoFocus
              color="inherit"
              type={"submit"}
              sx={{
                backgroundColor: "#0D99FF",
                borderRadius: 2,
                color: "#ffffff",
              }}
            >
              <FormattedMessage
                id={"DIALOGUE.SAVE"}
                defaultMessage={"DIALOGUE.SAVE"}
              />
            </Button>
          </Toolbar>
        </Box>
        <DialogContent>
          <form
            id={"user_document_form"}
            onSubmit={(e) => form.handleSubmit(e, handleSave)}
          >
            <Grid container spacing={2} mb={2}>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded && documentFolder ? (
                  <Controls.Input
                    name={"title"}
                    label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                    defaultValue={documentFolder?.title}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.title = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"title"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded && documentFolder ? (
                  <Controls.Select
                    options={folder_categories?.map((f_c) => {
                      return {
                        id: f_c._id.toString(),
                        title: f_c.title ? f_c.title[intl.locale] : "",
                      };
                    })}
                    formId={"category"}
                    defaultValue={documentFolder?.category?._id}
                    name={"category"}
                    label={intl.formatMessage({ id: "STANDARD.CATEGORY" })}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.category = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"category"}
                      label={intl.formatMessage({ id: "STANDARD.CATEGORY" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded && documentFolder ? (
                  <Box className={classes.dropzone}>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"STANDARD.FILES"}
                        defaultMessage={"STANDARD.FILES"}
                      />
                    </Typography>
                    <AttachmentsInfo
                      setParams={setFiles}
                      params={documentFolder}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"title"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowUserDocumentFolder;
