import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { IUrlQuery } from "app/interfaces";
import { Box, Divider, Grid, Typography } from "@mui/material";
import "antd/dist/antd.css";
import { accountingService } from "app/services";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { ISetting } from "app/services/setting.service";

const QuickCards = (props: {
  queries: IUrlQuery[] | undefined;
  setQuery: any;
  refreshCards: boolean;
}) => {
  const [clickedCard, setClickedCard] = useState<number | null>(null);
  const [currency, setCurrency] = useState<string>("");
  const [quickCardsCount, setQuickCardsCount] = useState<{
    total: { count: number; amount: number };
  }>();
  const { currencies } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const { business: _business } = useSelector(({ auth }: RootState) => auth);

  useEffect(() => {
    if (props.refreshCards) {
      getTasksCount();
    }
  }, [props.refreshCards]);

  useEffect(() => {
    const selected = currencies?.find(
      (r: ISetting) => r.slug === _business?.currency
    )?.symbol;

    if (selected) {
      setCurrency(selected);
    }
  }, [_business, currencies]);

  const getTasksCount = () => {
    accountingService.getExpenseQuickCardsCount(props.queries).then((data) => {
      if ("error" in data) throw new Error(data.error.message);

      const { data: counts } = data;

      setQuickCardsCount(counts);
    });
  };
  useEffect(() => {
    getTasksCount();
  }, []);
  useEffect(() => {
    getTasksCount();
  }, [props.queries]);

  const resetCardQuery = (
    isActive: boolean,
    name: "in_progress" | "total" | "completed" | "overdue"
  ) => {
    let _queries = props.queries?.filter(
      (q) =>
        q.name !== "overdue" &&
        q.name !== "completed" &&
        q.name !== "total" &&
        q.name !== "in_progress"
    );

    if (!_queries) return;

    if (isActive && props.queries && _queries) {
      let __queries = _queries.filter((q) => q.name !== name);
      props.setQuery(__queries);
    } else {
      props.setQuery([
        ..._queries,
        {
          name: name,
          value: "1",
        },
      ]);
    }
  };

  const handleClick = (index: number) => {
    if (clickedCard !== index) {
      setClickedCard(index);
    } else {
      setClickedCard(null);
    }
  };

  return (
    <>
      <Grid container spacing={1} sx={{ marginBottom: 3 }}>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={3}
          xl={3}
          className={`filter`}
          onClick={() => {
            handleClick(3);
            resetCardQuery(clickedCard === 3, "total");
          }}
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fff",
              height: 80,
              borderRadius: 2,
              padding: 2,
              gap: 2,
              cursor: "pointer",
              outline: clickedCard === 3 ? "1px solid #556EE6" : "",
            }}
          >
            <Box
              sx={{
                width: 50,
                height: 50,
                borderRadius: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                width="36"
                height="36"
                viewBox="0 0 36 36"
              >
                <path
                  fill="#8360D2"
                  d="m22,19.5c0,2.481-2.019,4.5-4.5,4.5H4.814c-1.162,0-2.192-.697-2.625-1.776-.432-1.079-.168-2.295.672-3.098l7.466-7.126L2.861,4.874c-.841-.803-1.104-2.02-.671-3.098.432-1.079,1.462-1.776,2.624-1.776h12.686c2.481,0,4.5,2.019,4.5,4.5,0,.829-.672,1.5-1.5,1.5s-1.5-.671-1.5-1.5-.673-1.5-1.5-1.5H5.243l8.293,7.915c.296.283.464.675.464,1.085s-.168.802-.464,1.085l-8.293,7.915h12.257c.827,0,1.5-.673,1.5-1.5s.672-1.5,1.5-1.5,1.5.672,1.5,1.5Z"
                />
              </svg>
            </Box>
            <Grid>
              <Typography
                sx={{ marginBottom: 1, fontWeight: 500, fontSize: 13 }}
                variant={"inherit"}
              >
                <FormattedMessage
                  id={"QUICK_CARD_TOTAL"}
                  defaultMessage={"Total"}
                />
              </Typography>
              <Typography
                sx={{ fontWeight: 500, fontSize: 16 }}
                variant={"inherit"}
              >
                {quickCardsCount ? quickCardsCount.total.count : 0}
              </Typography>
            </Grid>
            <Divider orientation={"vertical"} variant={"middle"} />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{ fontWeight: 500, fontSize: 16 }}
                variant={"inherit"}
              >
                {quickCardsCount ? quickCardsCount.total.amount : 0}{" "}
                {" " + currency}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default QuickCards;
