import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Controls from "../../../widgets/uncontrolled";
import { IFormUsersValues } from "../../../pages/Cases/store/parts/users";
import moment from "moment";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "../../../hooks/useForm";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { caseService, ICase } from "../../../services/case.service";
import { IUser } from "../../../interfaces";
import { TransitionProps } from "@mui/material/transitions";
import Snackbar from "../../../widgets/Snackbar";
import { aiservice, userService } from "../../../services";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import SelectUsers, { SelectedUsers } from "app/partials/SelectUsers";
import { DatePicker, Select, Space } from "antd";
import ConfirmDialog from "../confirmDialogue";
import Autocomplete from "@mui/material/Autocomplete";
import UserAvatar from "../../layout/UserAvatar";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

interface IFormProps {
  _id?: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  save: Function;
  case_needed: boolean;
  getUsers?: Function;
}

export type FormStateValues = {
  type: string;
  title: string | null;
  category: string;
  description: string;
  description_org: string;
  case: string;
  lead: string;
  users: Array<string>;
  date: string;
  deadline_date: string;
  status: string;
} & IFormUsersValues;

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "#ffffff",
  },
  tooltip: {
    backgroundColor: "#ffffff",
    minWidth: 330,
    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.25)",
    height: 136,
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
    padding: 0,
    display: "flex",
    alignItems: "center",
    borderRadius: 8,
  },
  iconButtonMessage: {
    "&:hover": {
      backgroundColor: "#556EE6",
    },
  },
  iconButtonProfile: {
    "&:hover": {
      backgroundColor: "#E1E6FB",
    },
  },
  popper: {
    left: "-5px!important",
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

const TodoStoreDialogue: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>();
  const { todo_types } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { todo_categories } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { todo_statuses } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const { roles } = useSelector((state: RootState) => state.permission);

  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );

  const { user } = useSelector(({ auth }: RootState) => auth);

  const notStartedStatus = todo_statuses
    ? todo_statuses.find(
        (todo_status) =>
          todo_status.slug === "not_started" ||
          todo_status.slug === "not-started"
      )?._id
    : "";

  useEffect(() => {
    if (notStartedStatus) form.values.status = notStartedStatus;
  }, [notStartedStatus]);

  const classes = useStyles();
  const [cases, setCases] = useState<Array<ICase>>();
  const [users, setUsers] = useState<
    Array<{
      label: string;
      values: Array<{
        id: string;
        title: string;
      }>;
    }>
  >();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndtDate] = useState<Date | null>(null);
  const [usersDialog, setUsersDialog] = useState<boolean | null>(false);
  const [s_users, setSelectedUsers] = useState<Array<SelectedUsers>>([]);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(true);
  const timeOut = useRef<any>();
  const [leads, setLeads] = useState<IUser[]>([]);
  const [userSearch, setUserSearch] = useState<string>("");
  const [editable, setEditable] = useState<boolean>(false);
  const [orgDesc, setOrgDesc] = useState<string>("");
  const [focusType, setFocusType] = useState<string>("");
  const [focusArea, setFocusArea] = useState<string>("");

  const sendQuery = (value: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      setUserSearch(value);
    }, 500);
  };

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
      {
        name: "keyword",
        value: userSearch,
      },
    ];

    userService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setLeads(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [userSearch]);

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
    ];

    userService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setLeads(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  useEffect(() => {
    if (s_users) form.values.users = s_users.map((user) => user._id);
  }, [s_users]);

  useEffect(() => {
    if (todo_types) {
      form.values.type = todo_types[0]._id;
    }

    if (todo_categories) {
      form.values.category = todo_categories[0]._id;
    }

    caseService
      .getAll([
        {
          name: "pagination",
          value: "0",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: cases } = data;
        setCases(cases);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  useEffect(() => {
    if (props.open) {
      if (todo_types) {
        form.values.type = todo_types[0]._id;
      }

      if (todo_categories) {
        form.values.category = todo_categories[0]._id;
      }

      if (notStartedStatus) form.values.status = notStartedStatus;
      userService
        .getAll([
          {
            name: "pagination",
            value: "0",
          },
          {
            name: "bounded_users",
            value: "1",
          },
        ])
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          const { data: users } = data;
          let modifiedUsers: Array<{
            label: string;
            values: Array<{
              id: string;
              title: string;
            }>;
          }> = [];
          roles.map((role) => {
            let _users: Array<{ id: string; title: string }> = [];
            let role_users = users.filter((user) => role._id === user.role._id);

            role_users.map((role_user) => {
              _users?.push({ id: role_user._id, title: role_user.fullname });
            });

            if (_users.length > 0)
              modifiedUsers?.push({
                label: role.title[intl.locale],
                values: [..._users],
              });
          });

          setUsers(modifiedUsers);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  }, [props.open]);

  useEffect(() => {
    if (!startDate) {
      form.values.date = moment.utc(new Date()).unix().toString();
    }

    if (!endDate) {
      form.values.deadline_date = moment.utc(new Date()).unix().toString();
    }
  }, []);

  const handleClose = () => {
    if (
      form.values.type ||
      form.values.title ||
      form.values.category ||
      form.values.description ||
      form.values.description_org ||
      form.values.case ||
      form.values.lead ||
      // form.values.date ||
      // form.values.deadline_date ||
      form.values.status ||
      (form.values.users && form.values.users.length > 0)
    ) {
      setConfirm(true);
    } else {
      props.setDialogue(false);
      setSelectedUsers([]);
    }
    form.values.description = "";
    form.clear();

    handleStop();
  };

  const save = () => {
    form.values.description_org = orgDesc;
    props.save(form.validate(), () => {
      form.clear();
      setStartDate(null);
      setEndtDate(null);
      setSelectedUsers([]);
    });
  };

  const setStatusField = (status: string) => {
    const slug =
      status &&
      todo_types?.find((todo_status) => todo_status._id === status)?.slug;

    if (slug === "event") {
      setStatus(false);
    } else {
      setStatus(true);
    }
  };

  const handleGrammar = () => {
    if (form.values.description.length < 1) {
      Snackbar.error(
        intl.formatMessage({
          id: "SNACKBAR.TEXTERROR",
          defaultMessage: "Please enter a text",
        })
      );
      return;
    }

    aiservice.grammar({ text: form.values.description }).then((data) => {
      setEditable(true);
      if (data.corrected_text) setOrgDesc(data.corrected_text);
    });
  };

  const handleHighLevelGrammar = () => {
    if (form.values.description.length < 1) {
      Snackbar.error(
        intl.formatMessage({
          id: "SNACKBAR.TEXTERROR",
          defaultMessage: "Please enter a text",
        })
      );
      return;
    }

    aiservice
      .highlevelgrammar({
        text: form.values.description,
        focus_area: focusArea,
        focus_type: focusType,
      })
      .then((data) => {
        setEditable(true);

        if (data.corrected_text) setOrgDesc(data.corrected_text);
      });
  };

  useEffect(() => {
    if (form.values.case) {
      const selectedCase = cases?.find((_case) => {
        _case._id = form.values.case;
      });

      if (selectedCase) {
        setFocusType(
          selectedCase.focus_type
            ? selectedCase.focus_type.title[intl.locale]
            : ""
        );
        setFocusArea(
          selectedCase.focus_area
            ? selectedCase.focus_area.title[intl.locale]
            : ""
        );
      }
    }
  }, [form.values.case]);

  const languageCodes: any = {
    en: "en-US",
    ar: "ar-AR",
    zh: "zh-CN",
    cs: "cs-CZ",
    da: "da-DK",
    nl: "nl-NL",
    fi: "fi-FI",
    fr: "fr-FR",
    de: "de-DE",
    el: "el-GR",
    hi: "hi-IN",
    hu: "hu-HU",
    it: "it-IT",
    ja: "ja-JP",
    ko: "ko-KR",
    pl: "pl-PL",
    pt: "pt-BR",
    ru: "ru-RU",
    es: "es-ES",
    sv: "sv-SE",
    tr: "tr-TR",
    vi: "vi-VN",
    az: "az-AZ",
  };

  const current = languageCodes[intl.locale] || languageCodes.en;
  const [isListening, setIsListening] = useState(false);
  const lastProcessedTranscriptShow = useRef("");
  const recognition = useRef<SpeechRecognition | null>(null);
  const [transcript, setTranscript] = useState("");
  const [draftDesc, setDraftDesc] = useState<string>("");
  const descRef = useRef<HTMLInputElement>(null);

  // Initialize SpeechRecognition
  useEffect(() => {
    if (
      !("SpeechRecognition" in window || "webkitSpeechRecognition" in window)
    ) {
      Snackbar.error("Your browser does not support speech recognition.");
      return;
    }

    recognition.current = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition)();
    recognition.current.continuous = true;
    recognition.current.interimResults = false;
    recognition.current.lang = current;

    recognition.current.onresult = (event) => {
      let combinedTranscript = "";
      for (let i = 0; i < event.results.length; i++) {
        combinedTranscript += event.results[i][0].transcript;
      }
      setTranscript(combinedTranscript);
    };

    recognition.current.onerror = (event) => {
      Snackbar.error(`Speech recognition error: ${event.error}`);
    };

    return () => {
      if (recognition.current) {
        recognition.current.stop();
        recognition.current = null;
      }
    };
  }, [current]);

  const handleStart = () => {
    if (recognition.current) {
      lastProcessedTranscriptShow.current = "";
      setTranscript("");
      recognition.current.start();
      setIsListening(true);
    }
  };

  useEffect(() => {
    if (form.values.description) {
      if (descRef.current) {
        descRef.current.value = form.values.description;
      }
      setDraftDesc(form.values.description);
    }
  }, [form.values.description]);

  const handleStop = () => {
    if (recognition.current) {
      recognition.current.stop();
      setIsListening(false);
    }
  };

  const handleReset = () => {
    setDraftDesc("");
    setTranscript("");
    lastProcessedTranscriptShow.current = "";
    setEditable(false);
    if (descRef.current) descRef.current.value = "";
  };

  useEffect(() => {
    if (transcript.length > 0) {
      const newText = transcript
        .replace(lastProcessedTranscriptShow.current, "")
        .trim();
      if (newText) {
        if (descRef.current) {
          descRef.current.value = descRef.current.value + " " + newText;
          form.values.description = descRef.current.value;
        }

        setDraftDesc((prevDescription) =>
          `${prevDescription} ${newText}`.trim()
        );
        lastProcessedTranscriptShow.current = transcript;
      }
    }
  }, [transcript]);

  return (
    <>
      {confirm ? (
        <ConfirmDialog
          title="Close window?"
          open={confirm}
          setOpen={() => setConfirm(false)}
          onConfirm={() => {
            props.setDialogue(false);
            form.clear();
            handleReset();

            setSelectedUsers([]);
          }}
        >
          Are you sure you want to close this window?
        </ConfirmDialog>
      ) : (
        <></>
      )}
      <SelectUsers
        open={Boolean(usersDialog)}
        Title={
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage id={"CASE.USERS"} defaultMessage={"CASE.USERS"} />
          </Typography>
        }
        handleClose={() => {
          setUsersDialog(null);
        }}
        handleSave={() => {}}
        onUserChange={(users) => {
          setSelectedUsers([...users]);
        }}
        users={s_users}
      />
      <PerfectScrollbar
        options={perfectScrollbarOptions}
        style={{
          position: "relative",
          maxHeight: "710px",
        }}
      >
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"md"}
          TransitionComponent={Transition}
          sx={{
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#E0F2FF",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"CASE.TODO.ADD"}
                  defaultMessage={"CASE.TODO.ADD"}
                />
              </Typography>

              <Button
                form={"todo_form"}
                autoFocus
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          {
            <DialogContent sx={{ p: "20px 44px" }}>
              <form
                id={"todo_form"}
                onSubmit={(e) => form.handleSubmit(e, save)}
              >
                <Grid container spacing={2} mb={2}>
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    {loaded ? (
                      <Box className={classes.input}>
                        <Divider sx={{ mb: 1 }}>
                          <Chip
                            label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                            sx={{
                              backgroundColor: "#0D99FF",
                              color: "#ffffff",
                            }}
                          />
                        </Divider>
                        <Controls.Input
                          name={"title"}
                          label={""}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.title = event.target.value;
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          name={"title"}
                          label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    <Box>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({ id: "CASE.USERS" })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        <FormattedMessage
                          id={"LABEL.SELECTUSER"}
                          defaultMessage={"LABEL.SELECTUSER"}
                        />
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        {s_users.map((user) => {
                          return (
                            <Box sx={{ position: "relative" }}>
                              <UserAvatar user={user} />
                              <IconButton
                                onClick={() => {
                                  setSelectedUsers(
                                    s_users.filter(
                                      (s_user) => s_user._id !== user._id
                                    )
                                  );
                                }}
                                sx={{
                                  position: "absolute",
                                  top: "-5px",
                                  right: "-5px",
                                  padding: "5px",
                                  backgroundColor: "#EC3033",
                                  "&:hover": { backgroundColor: "#EC3033" },
                                }}
                              >
                                <CloseOutlinedIcon
                                  sx={{ color: "#fff", width: 10, height: 10 }}
                                />
                              </IconButton>
                            </Box>
                          );
                        })}
                        <Button
                          onClick={() => {
                            setUsersDialog(true);
                          }}
                          sx={{
                            minWidth: "1px!important",
                            maxWidth: "44px!important",
                            height: 44,
                            borderRadius: "100%",
                            mt: 0.5,
                            p: "14px!important",
                            backgroundColor: "#0D99FF",
                          }}
                          variant="contained"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.75 10.75H13.25V3.25C13.25 2.55965 12.6904 2 12 2C11.3096 2 10.75 2.55965 10.75 3.25V10.75H3.25C2.55965 10.75 2 11.3096 2 12C2 12.6904 2.55965 13.25 3.25 13.25H10.75V20.75C10.75 21.4404 11.3096 22 12 22C12.6904 22 13.25 21.4404 13.25 20.75V13.25H20.75C21.4404 13.25 22 12.6904 22 12C22 11.3096 21.4404 10.75 20.75 10.75Z"
                              fill="white"
                            />
                          </svg>
                        </Button>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mb={2}>
                  <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                    {loaded ? (
                      <Box
                        sx={{
                          input: {
                            "& .MuiFormControl-root": {
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  top: 0,
                                  border: "1px solid #E6E6E6!important",
                                  borderRadius: 6,
                                },
                              },
                            },
                          },
                        }}
                      >
                        <Divider sx={{ mb: 1 }}>
                          <Chip
                            label={"Case&Category"}
                            sx={{
                              backgroundColor: "#0D99FF",
                              color: "#ffffff",
                            }}
                          />
                        </Divider>
                        <Box className={classes.input}>
                          <Typography variant={"body1"} sx={{ mb: 1 }}>
                            <FormattedMessage
                              id={"STANDARD.CATEGORY"}
                              defaultMessage={"STANDARD.CATEGORY"}
                            />
                          </Typography>
                          <Controls.Select
                            formId={"category_pick"}
                            name={"category"}
                            options={todo_categories?.map((t) => {
                              return {
                                id: t._id.toString(),
                                title: t.title[intl.locale],
                              };
                            })}
                            defaultValue={
                              todo_categories && todo_categories[0]._id
                            }
                            label={""}
                            onChange={(event) => {
                              if (!event.target.value) return;

                              form.values.category = event.target.value;
                            }}
                          />
                        </Box>
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Select
                          name={"category"}
                          options={[]}
                          label={intl.formatMessage({
                            id: "STANDARD.CATEGORY",
                          })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2} mb={2}>
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    {loaded ? (
                      <Box className={classes.input}>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"STANDARD.TYPE"}
                            defaultMessage={"STANDARD.TYPE"}
                          />
                        </Typography>
                        <Controls.Select
                          formId={"type_pick"}
                          name={"type"}
                          options={todo_types?.map((t) => {
                            return {
                              id: t._id.toString(),
                              title: t.title[intl.locale],
                            };
                          })}
                          defaultValue={todo_types && todo_types[0]._id}
                          label={""}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.type = event.target.value;
                            setStatusField(event.target.value);
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Select
                          name={"type"}
                          options={[]}
                          onChange={() => {}}
                          label={intl.formatMessage({ id: "STANDARD.TYPE" })}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    {loaded ? (
                      <Box className={classes.input}>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"CASE.INFORMATION.CASE"}
                            defaultMessage={"CASE.INFORMATION.CASE"}
                          />
                        </Typography>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          onChange={(e, value: any) => {
                            form.values.case = value?.id;
                          }}
                          options={
                            cases
                              ? cases.map((_case) => {
                                  return {
                                    id: _case._id,
                                    label:
                                      _case.key +
                                      ", " +
                                      intl.formatMessage({
                                        id: "CASE.INFORMATION.CASE",
                                      }) +
                                      ", users, " +
                                      _case.users.map(
                                        (u) => u.firstname + " " + u.lastname
                                      ),
                                  };
                                })
                              : []
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="" />
                          )}
                        />
                        {/*<Controls.Select*/}
                        {/*  name={"case"}*/}
                        {/*  formId={"case_pick"}*/}
                        {/*  options={*/}
                        {/*    cases &&*/}
                        {/*    cases.map((_case) => {*/}
                        {/*      return {*/}
                        {/*        id: _case._id.toString(),*/}
                        {/*        title: `${intl.formatMessage({*/}
                        {/*          id: "CASE.INFORMATION.CASE",*/}
                        {/*        })}: ${_case.key}; Users: ${_case.users.map(*/}
                        {/*          (u) => u.firstname*/}
                        {/*        )}`,*/}
                        {/*      };*/}
                        {/*    })*/}
                        {/*  }*/}
                        {/*  label={""}*/}
                        {/*  onChange={(event) => {*/}
                        {/*    if (!event.target.value) return;*/}

                        {/*    form.values.case = event.target.value;*/}
                        {/*  }}*/}
                        {/*/>*/}
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Select
                          name={"case"}
                          options={[]}
                          label={intl.formatMessage({
                            id: "CASE.INFORMATION.CASE",
                          })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2} mb={2}>
                  {status && (
                    <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                      {loaded ? (
                        <Box className={classes.input}>
                          <Typography variant={"body1"} sx={{ mb: 1 }}>
                            <FormattedMessage
                              id={"STANDARD.STATUS"}
                              defaultMessage={"STANDARD.STATUS"}
                            />
                          </Typography>
                          <Controls.Select
                            options={todo_statuses?.map((t) => {
                              return {
                                id: t._id.toString(),
                                title: t.title[intl.locale],
                              };
                            })}
                            formId={"status_pick"}
                            defaultValue={notStartedStatus}
                            name={"status"}
                            label={""}
                            onChange={(event) => {
                              if (!event.target.value) return;

                              form.values.status = event.target.value;
                            }}
                          />
                        </Box>
                      ) : (
                        <Skeleton width={"100%"}>
                          <Controls.Select
                            options={[]}
                            name={"status"}
                            label={intl.formatMessage({
                              id: "STANDARD.STATUS",
                            })}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                  )}
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={status ? 6 : 12}
                    xl={status ? 6 : 12}
                  >
                    {loaded ? (
                      <Box className={classes.input}>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"CASE.TODO.LEAD"}
                            defaultMessage={"CASE.TODO.LEAD"}
                          />
                        </Typography>
                        <Select
                          placeholder={intl.formatMessage({
                            id: "CASE.TODO.LEAD",
                          })}
                          showSearch
                          onChange={(value: string) => {
                            form.values.lead = value;
                          }}
                          onSearch={(value) => {
                            sendQuery(value);
                          }}
                          filterOption={false}
                          style={{ width: "100%" }}
                          allowClear={true}
                          options={
                            leads &&
                            leads.map((user) => {
                              return {
                                value: user._id.toString(),
                                label: user.firstname + " " + user.lastname,
                              };
                            })
                          }
                          className={classes.antSelect}
                          onClear={() => {
                            sendQuery("");
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Select
                          name={"lead"}
                          options={[]}
                          label={intl.formatMessage({ id: "CASE.TODO.LEAD" })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2} mb={2}>
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    <>
                      {loaded ? (
                        <Box className={classes.input}>
                          <Typography variant={"body1"} sx={{ mb: 1 }}>
                            <FormattedMessage
                              id={"STANDARD.DATE"}
                              defaultMessage={"STANDARD.DATE"}
                            />
                          </Typography>
                          <Space
                            className={classes.antSpace}
                            direction="vertical"
                            size={12}
                          >
                            <DatePicker
                              showTime={{ format: "HH:mm" }}
                              className={classes.datePicker}
                              dropdownClassName={classes.antDropdown}
                              format={"DD-MM-YYYY HH:mm"}
                              onChange={(value, dateString) => {
                                form.values.date = (
                                  moment(
                                    dateString,
                                    "DD-MM-YYYY HH:mm"
                                  ).valueOf() / 1000
                                ).toString();
                              }}
                            />
                          </Space>
                        </Box>
                      ) : (
                        <Skeleton width={"100%"}>
                          <DatePicker
                            showTime={{ format: "HH:mm" }}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                      {loaded ? (
                        <Box className={classes.input}>
                          <Typography variant={"body1"} sx={{ mb: 1, mt: 2.1 }}>
                            <FormattedMessage
                              id={"CASE.TODO.DEADLINEDATE"}
                              defaultMessage={"CASE.TODO.DEADLINEDATE"}
                            />
                          </Typography>
                          <Space
                            className={classes.antSpace}
                            direction="vertical"
                            size={12}
                          >
                            <DatePicker
                              showTime={{ format: "HH:mm" }}
                              format={"DD-MM-YYYY HH:mm"}
                              className={classes.datePicker}
                              dropdownClassName={classes.antDropdown}
                              onChange={(value, dateString) => {
                                form.values.deadline_date = (
                                  moment(
                                    dateString,
                                    "DD-MM-YYYY HH:mm"
                                  ).valueOf() / 1000
                                ).toString();
                              }}
                            />
                          </Space>
                        </Box>
                      ) : (
                        <Skeleton width={"100%"}>
                          <Space
                            className={classes.antSpace}
                            direction="vertical"
                            size={12}
                          >
                            <DatePicker />
                          </Space>
                        </Skeleton>
                      )}
                    </>
                  </Grid>
                  {editable && (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={editable ? 6 : 12}
                    lg={editable ? 6 : 12}
                    xl={editable ? 6 : 12}
                  >
                    {loaded ? (
                      <Box className={classes.input}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                            {editable
                              ? intl.formatMessage({
                                  id: "STANDARD.DRAFT",
                                  defaultMessage: "Draft",
                                })
                              : intl.formatMessage({
                                  id: "STANDARD.DESCRIPTION",
                                  defaultMessage: "STANDARD.DESCRIPTION",
                                })}
                          </Typography>
                          <Button
                            onClick={isListening ? handleStop : handleStart}
                            style={{
                              padding: "10px",
                              border: "none",
                              cursor: "pointer",
                            }}
                            sx={{ marginRight: "10px" }}
                            variant="outlined"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              id="Layer_1"
                              data-name="Layer 1"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                            >
                              <path
                                fill={isListening ? "red" : "green"}
                                d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,22c-5.514,0-10-4.486-10-10S6.486,2,12,2s10,4.486,10,10-4.486,10-10,10Zm4-10h2c0,2.968-2.166,5.439-5,5.916v2.084h-2v-2.084c-2.834-.477-5-2.948-5-5.916h2c0,2.206,1.794,4,4,4s4-1.794,4-4Zm-6,0v-5c0-1.105.895-2,2-2s2,.895,2,2v5c0,1.105-.895,2-2,2s-2-.895-2-2Z"
                              />
                            </svg>

                            <span
                              style={{
                                color: isListening ? "red" : "green",
                                marginLeft: "10px",
                              }}
                            >
                              {isListening
                                ? intl.formatMessage({
                                    id: "STANDARD.STOP_LISTENING",
                                    defaultMessage: "Stop Listening",
                                  })
                                : intl.formatMessage({
                                    id: "STANDARD.START_LISTENING",
                                    defaultMessage: "Start Listening",
                                  })}
                            </span>
                          </Button>

                          {!(
                            user?.role?.slug !== "moderator" &&
                            user?.role?.slug !== "super-admin"
                          ) && (
                            <>
                              <Button
                                onClick={() => {
                                  handleGrammar();
                                }}
                                sx={{ marginRight: "10px", color: "#0D99FF" }}
                              >
                                {intl.formatMessage({
                                  id: "STANDARD.GRAMMAR_CORRECTION",
                                  defaultMessage: "Grammar correction",
                                })}
                              </Button>
                              <Button
                                onClick={() => {
                                  handleHighLevelGrammar();
                                }}
                                sx={{ marginRight: "10px", color: "#0D99FF" }}
                              >
                                {intl.formatMessage({
                                  id: "STANDARD.HIGHER_LEVEL",
                                  defaultMessage: "Higher level correction",
                                })}
                              </Button>
                            </>
                          )}

                          <Button
                            onClick={() => {
                              handleReset();
                            }}
                            style={{
                              padding: "5px",
                              border: "none",
                              color: "red",
                              cursor: "pointer",
                            }}
                            sx={{ marginRight: "10px" }}
                            variant="outlined"
                          >
                            {intl.formatMessage({
                              id: "STANDARD.CLEAR",
                              defaultMessage: "Clear",
                            })}
                          </Button>
                        </Box>
                        <Controls.Input
                          multiline={true}
                          inputRef={descRef}
                          rows={5}
                          name={"description"}
                          disabled={editable}
                          label={""}
                          onChange={(event) => {
                            if (!event.target.value) {
                              lastProcessedTranscriptShow.current = "";
                              handleReset();
                            }
                            form.values.description = event.target.value;
                            setDraftDesc(form.values.description);
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          rows={5}
                          name={"description"}
                          label={intl.formatMessage({
                            id: "STANDARD.DESCRIPTION",
                          })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                  {editable && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={editable ? 6 : 12}
                      lg={editable ? 6 : 12}
                      xl={editable ? 6 : 12}
                    >
                      {loaded ? (
                        <Box className={classes.input}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              height: 44,
                            }}
                          >
                            <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                              {editable
                                ? intl.formatMessage({
                                    id: "STANDARD.ORIGINAL",
                                    defaultMessage: "Original",
                                  })
                                : intl.formatMessage({
                                    id: "STANDARD.DESCRIPTION",
                                    defaultMessage: "STANDARD.DESCRIPTION",
                                  })}
                            </Typography>
                          </Box>
                          <Controls.Input
                            multiline={true}
                            rows={5}
                            name={"description"}
                            label={""}
                            defaultValue={orgDesc}
                            onChange={(event) => {
                              if (!event.target.value) return;

                              form.values.description_org = event.target.value;
                              setOrgDesc(form.values.description_org);
                            }}
                          />
                        </Box>
                      ) : (
                        <Skeleton width={"100%"}>
                          <Controls.Input
                            rows={5}
                            name={"description"}
                            label={intl.formatMessage({
                              id: "STANDARD.DESCRIPTION",
                            })}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                  )}
                </Grid>
              </form>
            </DialogContent>
          }
        </Dialog>
      </PerfectScrollbar>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default TodoStoreDialogue;
