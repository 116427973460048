import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Grid,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { ISecureUser, IUrlQuery, IUser } from "../../../interfaces";
import { useIntl } from "react-intl";
import { IMessage } from "../messenger/messenger";
import moment from "moment/moment";
import { chatService } from "../../../services/chat.service";
import Snackbar from "../../../widgets/Snackbar";
import * as chat from "../../../store/ducks/chat.duck";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { IFile } from "../../../services/file.service";
import { userService } from "../../../services";
import Autocomplete from "@mui/material/Autocomplete";
import { Select } from "antd";
import { useLocation } from "react-router-dom";
export interface IRoom {
  _id: string;
  image: string;
  unread: number;
  files: { [key in string]: Array<IFile> } | undefined;
  name: string;
  last_message: IMessage | null;
  users: Array<IUser>;
  new_messages?: number;
}

type RoomProps = {
  lastData: Array<IRoom>;
  onChange: (room: IRoom) => void;
  onSearch?: (text: string) => void;
  active: string | null;
  onArchiveListOpen: Function;
  onBarClick?: () => void;
};

const useStyle = makeStyles((theme: Theme) => ({
  list: {
    py: 12,
  },
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  antSelect: {
    "& .ant-select-selector": {
      height: 40,
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
      backgroundColor: "#EDEFFB",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
  },
}));

export const loading = () => {
  return (
    <Grid
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  );
};

const Rooms: FC<RoomProps> = (props) => {
  const { active, lastData, onChange, onArchiveListOpen, onBarClick } = props;
  const [archiveList, setArchiveList] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    onArchiveListOpen(archiveList);
  }, [archiveList]);

  useEffect(() => {
    dispatch(chat.actions.setRooms([]));

    if (searchValue?.length) {
      dispatch(chat.actions.setSearch(searchValue));

      dispatch(
        chat.actions.getRooms({
          type: "load",
          skip: 0,
          limit: 15,
          archived: archiveList,
          search: searchValue,
        })
      );
    } else if (searchValue?.length === 0) {
      dispatch(chat.actions.setSearch(""));

      dispatch(
        chat.actions.getRooms({
          type: "load",
          skip: 0,
          limit: 15,
          archived: archiveList,
          search: "",
        })
      );
    }
  }, [searchValue]);

  const userSearch = (needle: string) => {};

  return (
    <Grid
      sx={{
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        "@media (max-width: 960px)": {
          height: active ? "unset" : "100vh",
        },
      }}
    >
      <Bar
        onClick={onBarClick}
        searchFunction={(value: string) => {
          setSearchValue(value);
        }}
      />
      <MenuSlider
        leftMenu={
          <LeftMenu
            active={active}
            lastData={lastData}
            onChange={onChange}
            open={archiveList}
            onArchiveClick={() => setArchiveList(true)}
          />
        }
        rightMenu={
          <RightMenu
            active={active}
            lastData={lastData}
            onChange={onChange}
            open={archiveList}
            onClick={() => setArchiveList(false)}
          />
        }
        active={!archiveList ? "left" : "right"}
      />
    </Grid>
  );
};

export const setRead = (room: string, query: Array<IUrlQuery> = []) => {
  return chatService
    .setRead(room, query)
    .then((data) => {
      if ("error" in data) {
        throw new Error(data.error.message);
      }

      let { data: room } = data;

      return room;
    })
    .catch((e) => {
      Snackbar.error(e.message);
    });
};

const LeftMenu: FC<{
  lastData: Array<IRoom>;
  active: string | null;
  onChange: (room: IRoom) => void;
  open: boolean;
  onArchiveClick: () => void;
  online_users?: Array<ISecureUser>;
}> = (props) => {
  const { active, onChange, onArchiveClick, open } = props;
  const [skip, setSkip] = useState<number>(0);
  const [localType, setLocalType] = useState<string>("load");
  const roomLoader = useRef<HTMLDivElement | null>(null);
  const limit = 15;
  const { rooms, lastData, roomsLoading, search } = useSelector(
    (state: RootState) => state.chat
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (skip && lastData?.length > 14) {
      dispatch(
        chat.actions.getRooms({
          limit,
          skip,
          type: localType,
          archived: false,
          search,
        })
      );
    }
  }, [skip]);

  const [rerenderTrigger, setRerenderTrigger] = useState<number>(0);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setRerenderTrigger((prev) => prev + 1);
  //   }, 10000);
  //
  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    if (!open) {
      // Fetch rooms when 'open' changes and re-renders the LeftMenu
      dispatch(
        chat.actions.getRooms({
          limit,
          skip,
          type: localType,
          archived: false,
          search,
        })
      );
    }
  }, [open, rerenderTrigger]);

  useEffect(() => {
    if (limit && roomLoader.current) {
      new IntersectionObserver(
        (entities) => {
          if (entities[0].isIntersecting) {
            setSkip((prevSkip) => prevSkip + limit);
            setLocalType("scroll");
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0,
        }
      ).observe(roomLoader.current);
    }
  }, [roomsLoading]);

  return (
    <>
      <Archive onClick={onArchiveClick} />
      {rooms && rooms.length > 0 ? (
        rooms.map((room) => {
          return (
            <List
              style={room._id === active ? { bgcolor: "white" } : {}}
              onClick={() => {
                onChange(room);
                setRead(room._id).then(() => {
                  dispatch(chat.actions.roomsHotReload());
                });
              }}
            >
              <RoomItem room={room} />
            </List>
          );
        })
      ) : (
        <Grid
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      )}

      {!roomsLoading && limit && skip + limit && (
        <Grid
          ref={roomLoader}
          sx={{ display: "flex", justifyContent: "center", p: 2 }}
        >
          {lastData?.length > 14 && <CircularProgress />}
        </Grid>
      )}
    </>
  );
};

const RightMenu: FC<{
  lastData: Array<IRoom>;
  active: string | null;
  onChange: (room: IRoom) => void;
  onClick: () => void;
  open: boolean;
}> = (props) => {
  const intl = useIntl();
  const interval = useRef<any>();
  const { active, onChange, onClick, open } = props;
  const [skip, setSkip] = useState<number>(0);
  const roomLoader = useRef<HTMLDivElement | null>(null);
  const limit = 15;
  const { rooms, lastData, roomsLoading, search } = useSelector(
    (state: RootState) => state.chat
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      dispatch(chat.actions.setRooms([]));

      setSkip(0);
      dispatch(
        chat.actions.getRooms({
          limit,
          skip: 0,
          type: "load",
          archived: true,
          search: "",
        })
      );
    }
  }, [open]);

  // useEffect(() => {
  //   if (open) {
  //     interval.current = setInterval(() => {
  //       dispatch(
  //         chat.actions.getRooms({
  //           limit,
  //           skip: 0,
  //           type: "load",
  //           archived: true,
  //           search,
  //         })
  //       );
  //     }, 5000);
  //
  //     return () => clearInterval(interval.current);
  //   }
  // }, [open]);

  useEffect(() => {
    if (skip && lastData?.length > 14) {
      dispatch(
        chat.actions.getRooms({
          limit,
          skip,
          type: "scroll",
          archived: true,
          search,
        })
      );
    }
  }, [skip]);

  useEffect(() => {
    if (limit && roomLoader.current) {
      new IntersectionObserver(
        (entities) => {
          if (entities[0].isIntersecting) {
            setSkip((prevSkip) => prevSkip + limit);
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0,
        }
      ).observe(roomLoader.current);
    }
  }, [roomsLoading]);

  return (
    <>
      <Box
        onClick={onClick}
        sx={{
          p: 1.5,
          gap: 1.5,
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <i
          className="custom_icon-left-arrow"
          style={{
            color: "#9FA5BB",
          }}
        />
        <Typography fontSize={16} color={"#3B3B3B"} fontWeight={500}>
          {intl.formatMessage({ id: "CHAT.ARCHIVED_CHATS" })}
        </Typography>
      </Box>
      {rooms && rooms.length > 0 ? (
        rooms.map((room) => {
          return (
            <List
              style={room._id === active ? { bgcolor: "white" } : {}}
              onClick={() => {
                onChange(room);
                setRead(room._id, [{ name: "archive", value: 1 }]);
              }}
            >
              <RoomItem room={room} />
            </List>
          );
        })
      ) : (
        <Grid
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      )}

      {!roomsLoading && limit && skip + limit && (
        <Grid
          ref={roomLoader}
          sx={{ display: "flex", justifyContent: "center", p: 2 }}
        >
          {lastData.length > 14 && <CircularProgress />}
        </Grid>
      )}
    </>
  );
};

const MenuSlider: FC<{
  leftMenu: JSX.Element;
  rightMenu: JSX.Element;
  active: "left" | "right";
}> = (props) => {
  const { leftMenu, rightMenu, active } = props;
  const containerRef = useRef<HTMLDivElement>();

  return (
    <>
      <Box
        ref={containerRef}
        sx={{
          overflow: "hidden",
          width: "100%",
          height: "100%",
          display: "flex",
          position: "relative",
          // overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            transition: "left 225ms linear 0ms",
            left: `${active === "left" ? "0" : "-100%"}`,
            overflowY: "scroll",
          }}
        >
          {leftMenu}
        </Box>
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            transition: "right 225ms linear 0ms",
            right: `${active === "right" ? "0" : "-100%"}`,
            overflowY: "scroll",
          }}
        >
          {rightMenu}
        </Box>
      </Box>
    </>
  );
};

const Archive: FC<{ onClick: () => void }> = (props) => {
  const { onClick } = props;
  const intl = useIntl();

  return (
    <List onClick={onClick}>
      <Grid
        item
        xs={1.7}
        xl={1.1}
        display={"flex"}
        justifyContent={"flex-start"}
        sx={{
          cursor: "pointer",
        }}
      >
        <Avatar
          variant={"rounded"}
          sx={{
            borderRadius: 2,
            backgroundColor: "#E6E6E6",
            display: "flex",
            alignItems: "center",
          }}
        >
          <i
            className="fi fi-sr-box"
            style={{
              fontSize: 21,
              color: "#9FA5BB",
              lineHeight: "0.5em",
              verticalAlign: "-0.15em",
              display: "inline-flex",
            }}
          />
        </Avatar>
      </Grid>
      <Grid container item xs={10.3} xl={10.9} sx={{ pl: 1, pt: 1 }}>
        <Grid item xs={12} xl={12}>
          <Tooltip title={intl.formatMessage({ id: "CHAT.ARCHIVED_CHATS" })}>
            <Typography
              sx={{
                fontWeight: 500,
                color: "#3B3B3B",
                fontSize: 15,
              }}
            >
              {intl.formatMessage({ id: "CHAT.ARCHIVED_CHATS" })}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={9.5}
          xl={10.5}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "80%",
          }}
        >
          <Typography
            noWrap={true}
            variant={"body2"}
            sx={{
              fontWeight: 400,
              fontSize: 12,
              color: "#9FA5BB",
            }}
          >
            {/*{getUsersInfo(users).main}*/}
          </Typography>
        </Grid>
      </Grid>
    </List>
  );
};

const Bar: FC<{
  onClick?: () => void;
  searchFunction: Function;
}> = (props) => {
  const { onClick, searchFunction } = props;
  const timeOut = useRef<any>();
  const classes = useStyle();
  const [users, setUsers] = useState<IUser[]>([]);
  const [searchValue, setSearchValue] = useState<string[]>([]);
  const [userSearch, setUserSearch] = useState<string>("");
  let { search } = useLocation();
  let query = useMemo(() => new URLSearchParams(search), [search]);
  const __user = query.get("user");
  const sendQuery = (value: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      setUserSearch(value);
    }, 500);
  };
  const { user: me } = useSelector(({ auth }: RootState) => auth);

  useEffect(() => {
    searchFunction && searchFunction(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (__user) {
      setSearchValue([__user]);
      setUserSearch(__user);
    }
  }, [__user]);

  useEffect(() => {
    if (userSearch.length > 0) {
      let queries = [
        {
          name: "pagination",
          value: "1",
        },
        {
          name: "bounded_users",
          value: "1",
        },
        {
          name: "keyword",
          value: userSearch,
        },
      ];

      userService
        .getAll(queries)
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          const { data: users } = data;

          setUsers(users);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  }, [userSearch]);

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "bounded_users",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
    ];

    userService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setUsers(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  return (
    <List style={{ px: 1.5, pt: 0 }}>
      {me?.role?.slug === "moderator" ||
        (me?.role?.slug === "super-admin" && (
          <>
            <Grid item xs={10.4} xl={10.9} sx={{ pr: 1, pt: 1 }}>
              <Box className={classes.input}>
                <Select
                  mode="multiple"
                  placeholder="Users"
                  defaultValue={__user ? [__user] : []}
                  onChange={(value) => {
                    setSearchValue(value);
                  }}
                  onSearch={(value) => {
                    sendQuery(value);
                  }}
                  filterOption={false}
                  style={{ width: "100%" }}
                  allowClear={true}
                  options={
                    users &&
                    users.map((user) => {
                      return {
                        value: user._id.toString(),
                        label: user.firstname + " " + user.lastname,
                      };
                    })
                  }
                  onClear={() => {
                    sendQuery("");
                  }}
                  className={classes.antSelect}
                  maxTagCount={"responsive"}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={1.6}
              xl={1.1}
              display={"flex"}
              justifyContent={"flex-start"}
              onClick={onClick}
            >
              <Avatar
                variant={"rounded"}
                sx={{
                  borderRadius: 2,
                  backgroundColor: "#EDEFFB",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <i
                  className="fi fi-sr-plus"
                  style={{
                    fontSize: 18,
                    color: "#9FA5BB",
                    lineHeight: "0.5em",
                    verticalAlign: "-0.15em",
                    display: "inline-flex",
                  }}
                />
              </Avatar>
            </Grid>
          </>
        ))}
    </List>
  );
};

export const getDate = (date: number) => {
  let ret;
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s",
      s: "a few seconds",
      ss: "%d seconds",
      m: "a minute",
      mm: "%d m",
      h: "an hour",
      hh: "%dh",
      d: "a day",
      dd: "%d d",
      M: "a month",
      MM: "%d months",
      y: "a year",
      yy: "%d years",
    },
  });
  if (moment().unix() - date < 1728000) {
    // less than 20 days
    ret =
      moment(new Date(date * 1000))
        .local()
        .fromNow() + " ago";
  } else {
    ret = moment(new Date(date * 1000))
      .local()
      .format("DD/MM/YYYY");
  }

  return ret;
};

const getLastMessage = (type: string): string => {
  let ret: string;

  switch (type) {
    case "document":
      ret = "document";
      break;
    case "img":
      ret = "photo";
      break;
    case "mp3":
      ret = "voice";
      break;
    default:
      ret = "";
  }

  return ret;
};

const RoomItem: FC<{ room: IRoom }> = (props) => {
  const { room } = props;
  const { user: me } = useSelector(({ auth }: RootState) => auth);

  return (
    <>
      <Grid
        item
        xs={1.7}
        xl={1.1}
        display={"flex"}
        justifyContent={"flex-start"}
      >
        {room.users.length > 2 ? (
          <Avatar
            variant={"rounded"}
            sx={{ borderRadius: 2 }}
            src={"media/groupicon.jpeg"}
          />
        ) : (
          <Avatar
            variant={"rounded"}
            sx={{ borderRadius: 2 }}
            src={room.image}
          />
        )}
      </Grid>
      <Grid container item xs={10.3} xl={10.9} sx={{ pl: 1 }}>
        <Grid item xs={9} xl={10}>
          <Tooltip title={room.last_message?.message ?? ""}>
            <Typography
              sx={{
                fontWeight: 500,
                color: "#3B3B3B",
                fontSize: 15,
              }}
            >
              {room.users?.map((_user, index) => {
                if (room.users.length == 2 && _user._id === me?._id) return;
                return (index ? ", " : "") + _user?.fullname;
              })}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={3}
          xl={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 0.5,
          }}
        >
          {room?.last_message?.created_by === me?._id && (
            <i className="custom_icon-dblcheck" style={{ height: 12 }} />
          )}
          <Typography
            fontSize={12}
            fontWeight={400}
            style={{ color: "#9FA5BB" }}
          >
            {room.last_message?._created_at &&
              getDate(room.last_message?._created_at)}
          </Typography>
        </Grid>
        <Grid
          item
          xs={9.5}
          xl={10.5}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "80%",
          }}
        >
          <Typography
            noWrap={true}
            variant={"body2"}
            sx={{
              fontWeight: 400,
              fontSize: 12,
              color: "#9FA5BB",
            }}
          >
            {room.last_message?.message ??
              getLastMessage(
                room.last_message?.documents
                  ? room.last_message?.documents[0]?.type
                  : ""
              )}
          </Typography>
        </Grid>
        {room.new_messages && room.new_messages > 0 ? (
          <Grid
            item
            xs={2.5}
            xl={1.5}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <Chip
              label={room.new_messages}
              color={"info"}
              sx={{
                "& > .MuiChip-label": {
                  p: 0,
                  paddingTop: "1px",
                },
                height: 20,
                width: 20,
              }}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

const List: FC<{ style?: {}; onClick?: () => void }> = ({
  children,
  style = {},
  onClick,
}) => {
  return (
    <Grid
      onClick={onClick}
      container
      sx={{
        borderRadius: 2,
        pr: 2.5,
        pl: 1.5,
        py: 1.5,
        cursor: "pointer",
        width: "100%",
        ...style,
      }}
      display={"flex"}
      alignItems={"center"}
    >
      {children}
    </Grid>
  );
};

export default Rooms;
