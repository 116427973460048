import React, { FC, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  ImageList,
  ImageListItem,
  Slide,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { IMessage } from "../messenger";
import { toAbsoluteUrl } from "../../../../../_metronic";
import { IFile } from "../../../../services/file.service";
import ContextMenu from "app/pages/Chat/messenger/helpers/contextMenu";
import ReplyMessage from "./replyMessage";
import { getFileExtIcon } from "../../../../utils/standart";
import moment from "moment";
import ReactAudioPlayer from "react-audio-player";
import { TransitionProps } from "@mui/material/transitions";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles((theme: Theme) => ({
  title: {},
  text: {
    marginTop: 5,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    gap: 8,
    marginBottom: "20px",
  },
  message: {},
  secondary: {
    display: "flex",
    justifyContent: "center",
    gap: 6,
  },
  time: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  status: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Message: FC<
  IMessage & {
    position: "left" | "right";
    onReplyClick: () => void;
    onDeleteClick: () => void;
    onForwardClick: () => void;
  }
> = (props) => {
  const {
    message,
    created_by,
    documents,
    replyMessage,
    received,
    position,
    _created_at,
    onReplyClick,
    onDeleteClick,
    onForwardClick,
  } = props;
  const classes = useStyle();
  const elementRef = useRef<null | HTMLDivElement>(null);
  const [ctxMenuPos, setCtxMenuPos] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const touchTimer = useRef<NodeJS.Timeout | null>(null);
  // const isMultiFileMulti = files && files.length > 1;

  const [cMOpen, setCMOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery("(max-width:960px)");

  return (
    <Grid
      className={"noselect"}
      sx={
        position === "left"
          ? {
              minWidth: 170,
              maxWidth: "85%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px 8px 8px 0px",
              backgroundColor: "#E0F2FF",
              overflow: "hidden",
              wordBreak: "break-word",
            }
          : {
              minWidth: 170,
              maxWidth: "85%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px 8px 0px 9px",
              backgroundColor: "#E6E6E6",
              overflow: "hidden",
              wordBreak: "break-word",
            }
      }
    >
      <Grid
        sx={{
          p: document ? 1 : 2,
          position: "relative",
        }}
        ref={elementRef}
        onContextMenu={(e) => {
          e.preventDefault();
          setCtxMenuPos({ x: e.clientX + 2, y: e.clientY - 6 });
          setCMOpen(true);
        }}
        onTouchStart={(e) => {
          touchTimer.current = setTimeout(() => {
            const touch = e.touches?.[0];
            if (touch) {
              setCtxMenuPos({ x: touch.clientX, y: touch.clientY });
              setCMOpen(true);
            }
          }, 500);
        }}
        onTouchEnd={() => {
          if (touchTimer.current) {
            clearTimeout(touchTimer.current);
          }
        }}
        onTouchCancel={() => {
          if (touchTimer.current) {
            clearTimeout(touchTimer.current);
          }
        }}
      >
        <Grid className={classes.title}>
          <Typography fontSize={14} color={"#0D99FF"}>
            {created_by?.firstname}
          </Typography>
        </Grid>
        {replyMessage ? (
          <ReplyMessage {...replyMessage} position={position} />
        ) : (
          <></>
        )}
        <Grid
          className={classes.text}
          sx={{
            flexDirection: documents ? "column" : "row",
            wordBreak: "break-word",
          }}
        >
          {documents ? (
            <File documents={documents} />
          ) : (
            <Text message={message} />
          )}
          {isMobile && (
            <Grid
              className={classes.secondary}
              sx={{ position: "absolute", top: "2px", right: "-20px" }}
            >
              <Button
                onClick={(e) => {
                  const touch = e.target as HTMLElement;
                  setCtxMenuPos({
                    x: touch.getBoundingClientRect().left,
                    y: touch.getBoundingClientRect().top,
                  });
                  setCMOpen(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Isolation_Mode"
                  data-name="Isolation Mode"
                  viewBox="0 0 24 24"
                  width="15"
                  height="15"
                >
                  <circle cx="12" cy="2.5" r="2.5" />
                  <circle cx="12" cy="12" r="2.5" />
                  <circle cx="12" cy="21.5" r="2.5" />
                </svg>
              </Button>
            </Grid>
          )}
          <Grid
            className={classes.secondary}
            sx={{ position: "absolute", bottom: "5px", right: "10px" }}
          >
            <Grid className={classes.time}>
              <Typography fontSize={13} color={"#6D6D6D"}>
                {moment.unix(_created_at).local().format("HH:mm")}
              </Typography>
            </Grid>
            {position == "right" && (
              <Grid className={classes.status}>
                {/*{received ? (*/}
                <i className="custom_icon-dblcheck" style={{ height: 12 }} />
                {/*) : (*/}
                {/*  <i className="fi fi-bs-check" />*/}
                {/*)}*/}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ContextMenu
        open={cMOpen}
        handleClose={() => setCMOpen(false)}
        handleCopy={() => {
          navigator.clipboard.writeText(message);
          setCMOpen(false);
        }}
        handleReply={() => {
          onReplyClick();
          setCMOpen(false);
        }}
        handleForward={() => {
          onForwardClick();
          setCMOpen(false);
        }}
        handleSelect={() => {}}
        handleDelete={() => {
          onDeleteClick();
          setCMOpen(false);
        }}
        anchorEl={elementRef.current}
        pos={ctxMenuPos}
        isDocument={!!(documents && documents.length > 0)}
      />
    </Grid>
  );
};

const Text: FC<{ message: string }> = ({ message }) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  const renderMessage = (text: string) => {
    return text?.split(urlRegex).map((part, index) =>
      urlRegex.test(part) ? (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#0D99FF", textDecoration: "underline" }}
        >
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  return (
    <Typography
      fontSize={16}
      color={"#3B3B3B"}
      sx={{
        wordBreak: "break-word",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
      }}
    >
      {renderMessage(message)}
    </Typography>
  );
};

const File: FC<{ documents: Array<IFile> }> = ({ documents }) => {
  const getComponent = (documents: Array<IFile>): JSX.Element => {
    let ret: JSX.Element = <></>;

    //if we have document inside array, we should implement it as all is docs (no matter if any image inside)
    let document = documents.find((doc) => doc.type === "document");
    let image = documents.find((doc) => doc.type === "img");
    let voice = documents.find((doc) => doc.type === "mp3");

    if (document) {
      ret = <Documents documents={documents} />;
    }

    if (image) {
      ret = <Images images={documents} />;
    }

    if (voice) {
      ret = <Audio audio={documents[0]} />;
    }
    // else if (docType === "mp3") {
    //   // ret = <Audio audios={documents} />;
    // }

    return ret;
  };

  return (
    <Grid
      sx={{
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        whiteSpace: "nowrap",
      }}
    >
      {getComponent(documents)}
    </Grid>
  );
};

const Documents: FC<{
  documents: Array<{ _id: string; name: string; url: string }>;
}> = ({ documents }) => {
  const history = useHistory();
  return (
    <>
      {documents.map((doc) => {
        return (
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            href={doc.url}
            component={"a"}
            target={"_blank"}
            onClick={() => {
              window.open(
                process.env.REACT_APP_FILES_BASE_URL +
                  "/storage/" +
                  doc._id +
                  "/" +
                  doc.name
              );
            }}
          >
            <Grid
              sx={{
                display: "flex",
                gap: 1.5,
              }}
            >
              <Grid>
                <img
                  src={toAbsoluteUrl(
                    `/media/extension/${getFileExtIcon(doc.name)}`
                  )}
                  alt={doc.name}
                />
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  maxWidth: "200px",
                }}
              >
                <Tooltip title={doc.name}>
                  <Typography
                    color={"#3B3B3B"}
                    fontSize={13}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                  >
                    {doc.name}
                  </Typography>
                </Tooltip>
                <Typography color={"#767C91"} fontSize={10}>
                  {/* put info here  */}
                  45.4 KB
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

const Images: FC<{
  images: Array<{ _id: string; name: string; base64: string }>;
}> = ({ images }) => {
  const isSingle = images.length === 1;

  const [open, setOpen] = useState<boolean>(false);
  const [multiMedia, setOpenMultiMedia] = useState<boolean>(false);
  const [source, setSource] = useState<string>("");

  const handleMedia = (source: string) => {
    setSource(source);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return isSingle ? (
    <>
      <Box
        component="img"
        sx={{
          borderRadius: 2,
          height: 233,
          width: 350,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          cursor: "pointer",
        }}
        alt={images[0].name}
        onClick={() => handleMedia(images[0].base64)}
        src={images[0].base64}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        TransitionComponent={Transition}
      >
        <DialogContent sx={{ padding: 0 }}>
          <Avatar
            sx={{
              width: "100%",
              height: "100%",
            }}
            alt={""}
            variant={"square"}
            src={source}
          />
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <Box
      sx={{
        display: "grid",
        gridGap: "4px",
        gridTemplateRows: `repeat(${images.length > 2 ? 2 : 1}, 1fr)`,
        gridTemplateColumns: `repeat(${2}, 1fr)`,
      }}
    >
      {images.map((img, index) => {
        if (index > 3) return;

        let firstImageStretched = index === 0 && images.length === 3;

        return (
          <Box
            sx={{
              position: "relative",
              height: 120 + (firstImageStretched ? 120 : 0),
              width: 120,

              maxHeight: {
                xs: 120 + (firstImageStretched ? 120 : 0),
                md: 120 + (firstImageStretched ? 120 : 0),
              },
              maxWidth: { xs: 120, md: 120 },
            }}
            style={
              firstImageStretched
                ? {
                    gridRowStart: 1,
                    gridRowEnd: 3,
                  }
                : {}
            }
          >
            <Box
              component="img"
              sx={{
                borderRadius: 2,
                cursor: "pointer",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              alt={img.name}
              onClick={() => handleMedia(img.base64)}
              src={img.base64}
            />
            {images.length > 4 && index === 3 && (
              <>
                <Box
                  style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    background: "rgba(0, 0, 0, 0.7)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    fontSize: "16px",
                    borderRadius: 8,
                  }}
                  onClick={() => setOpenMultiMedia(true)}
                >
                  +{images.length - 4}
                </Box>
                <Dialog
                  open={multiMedia}
                  onClose={() => setOpenMultiMedia(false)}
                  TransitionComponent={Transition}
                >
                  <DialogContent sx={{ padding: 0 }}>
                    <ImageList variant="quilted">
                      {images.map((img) => (
                        <ImageListItem key={img._id}>
                          <img
                            src={`${img.base64}?w=164&h=164&fit=crop&auto=format`}
                            srcSet={`${img.base64}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            alt={img.name}
                            loading="lazy"
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </DialogContent>
                </Dialog>
              </>
            )}
            <Dialog
              open={open}
              onClose={handleClose}
              fullWidth={true}
              maxWidth={"md"}
              TransitionComponent={Transition}
            >
              <DialogContent sx={{ padding: 0 }}>
                <Avatar
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                  alt={""}
                  variant={"square"}
                  src={source}
                />
              </DialogContent>
            </Dialog>
          </Box>
        );
      })}
    </Box>
  );
};

const Audio: FC<{ audio: { _id: string; name: string; url: string } }> = ({
  audio,
}) => {
  return (
    <ReactAudioPlayer
      src={
        process.env.REACT_APP_FILES_BASE_URL +
        `/storage/${audio._id}/${audio.name}?${Math.random()}`
      }
      controls
    />
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default Message;
