import React, { FC, useEffect, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import { Box, IconButton, Typography, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { GridTable } from "app/partials/layout/GridTable";
import { useTable } from "app/hooks/subresource/useTable";
import { caseService, ICase } from "app/services/case.service";
import { useIntl } from "react-intl";
import { PermissionsGate } from "app/permission/PermissionsGate";
import ConfirmDialog from "app/partials/dialogues/confirmDialogue";
import { IDocument } from "../../../services/document.service";
import { useParams } from "react-router";
import moment from "moment";
import ShowCaseDocumentFolder from "./showDocument";
import DocumentStore from "../store/documentStore";
import { makeStyles } from "@material-ui/core";
import DocumentFilter from "./filters/documentFilter";
import EditIcon from "../../../partials/icons/EditIcon";

const useStyles = makeStyles(() => ({
  container: {
    "& .MuiBox-root": {
      "& .MuiGrid-root": {
        marginBottom: "4px!important",
        marginTop: "4px!important",
      },
    },
  },
  calendar: {
    zIndex: 99999,
  },
  dateRangeInput: {
    width: "100%",
    borderRadius: 8,
    border: "none!important",
    "& .ant-picker-focused": {
      boxShadow: "none!important",
    },
    "& .ant-picker": {
      border: "1px solid #E6E6E6!important",
      height: 40,
      backgroundColor: "#fff",
      borderRadius: 8,
      width: "100%",
      color: "#000000!important",
      "& .ant-picker-input input::placeholder": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
  },
  input: {
    "& .MuiFormControl-root": {
      "& .MuiInputLabel-root": {
        top: "-5px",
      },
      height: 40,
      backgroundColor: "#fff",
      border: "1px solid #E6E6E6",
      borderRadius: 8,
      m: () => ({}),
      "& .MuiOutlinedInput-notchedOutline": {
        border: "unset",
      },
    },
  },
}));

const Document: FC<ICase> = (props) => {
  const { id } = useParams<{ id: string }>();

  const { data, xhrLoading, remove, refresh, updateQuery } =
    useTable<IDocument>({
      fetch: caseService.getDocFolders,
      delete: caseService.deleteDocFolder,
      param: id,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });

  const intl = useIntl();
  const [confirmId, setConfirmId] = useState<string | null>(null);
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const classes = useStyles();

  // @ts-ignore
  const columns: IColumns[] = [
    {
      field: "_id",
      headerName: "ID",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      field: "title",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.title}>
            <Typography variant={"inherit"}>{params.row?.title}</Typography>
          </Tooltip>
        );
      },
      minWidth: 300,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "created_at",
      valueGetter: (params) => {
        return moment.unix(params.row?._created_at).utc().format("DD/MM/YYYY");
      },
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"documents"} scope={"read"}>
              <IconButton
                onClick={() => show(params.row?._id)}
                data-cy-class={"case_document_show"}
                data-cy-case-id={props._id}
                data-cy-document-id={params.row?._id}
              >
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"documents"} scope={"delete"}>
              <IconButton
                onClick={() => {
                  handleClick(params.row?._id);
                }}
                data-cy-class={"case_document_delete"}
                data-cy-case-id={props._id}
                data-cy-document-id={params.row?._id}
              >
                <DeleteIcon sx={{ color: "error.main" }} />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  const [documentFolderId, setDocumentFolderId] = useState<string | null>(null);

  const show = (_id: string) => {
    setDocumentFolderId(_id);
  };

  return (
    <Box className={classes.container}>
      <PermissionsGate section={"documents"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete document?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(props._id, confirmId);
            }}
          >
            Are you sure you want to delete this document?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"documents"} scope={"read"}>
        {documentFolderId ? (
          <ShowCaseDocumentFolder
            _case={props._id}
            _id={documentFolderId}
            open={Boolean(documentFolderId)}
            setDialogue={() => setDocumentFolderId(null)}
            onDone={refresh}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"documents"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel: (props: { open: boolean; close: () => void }) => {
              return (
                <DocumentStore
                  case={id}
                  open={props.open}
                  setDialogue={props.close}
                  onDone={refresh}
                />
              );
            },
            filters: (props: { show: boolean }) => {
              return <DocumentFilter show={props.show} />;
            },
          }}
          search={(queries) => updateQuery(queries)}
          quickSearchToolbar={true}
        />
      </PermissionsGate>
    </Box>
  );
};

export default Document;
