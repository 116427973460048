import React, { FC } from "react";
import { Box, Grid, Theme, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { IMessage } from "../messenger";
import { toAbsoluteUrl } from "../../../../../_metronic";
import { IFile } from "../../../../services/file.service";

const useStyle = makeStyles((theme: Theme) => ({
  title: {},
  text: {
    display: "flex",
    justifyContent: "space-between",
    // alignItems: "flex-end",
    gap: 8,
    overflowY: "hidden",
  },
  message: {
    overflowY: "hidden",
    textOverflow: "ellipsis",
  },
}));

const ReplyMessage: FC<IMessage & { position: "left" | "right" }> = (props) => {
  const { message, created_by, documents, position } = props;
  const classes = useStyle();

  return (
    <Grid
      sx={
        position === "left"
          ? {
              width: "100%",
              height: document ? "45%" : "unset",
              display: "flex",
              flexDirection: "column",
              borderRadius: "4px 4px 4px 4px",
              backgroundColor: "#EDEFFB",
              borderLeft: "4px solid #3DCA81",
              marginLeft: -0.5,
              marginRight: -0.5,
              px: 0.5,
              py: 1,
              my: 0.5,
              gap: 0.5,
            }
          : {
              width: "100%",
              height: document ? "45%" : "unset",
              display: "flex",
              flexDirection: "column",
              borderRadius: "4px 4px 4px 4px",
              backgroundColor: "#EDEFFB",
              borderLeft: "4px solid #3DCA81",
              marginLeft: -0.5,
              marginRight: -0.5,
              px: 0.5,
              py: 1,
              my: 0.5,
              gap: 0.5,
            }
      }
    >
      <Grid className={classes.title}>
        <Typography fontSize={12} color={"#3DCA81"}>
          {created_by?.firstname}
        </Typography>
      </Grid>
      <Grid
        className={classes.text}
        sx={{
          flexDirection: documents ? "column" : "row",
        }}
      >
        {documents ? (
          <File documents={documents} />
        ) : (
          <Text message={message} />
        )}
      </Grid>
    </Grid>
  );
};

const Text: FC<{ message: string }> = ({ message }) => {
  const classes = useStyle();

  return (
    <Grid className={classes.message}>
      <Typography noWrap fontSize={13} color={"#3B3B3B"}>
        {message}
      </Typography>
    </Grid>
  );
};

const File: FC<{ documents: Array<IFile> }> = ({ documents }) => {
  const getComponent = (documents: Array<IFile>): JSX.Element => {
    let ret = <></>;

    if (documents.length > 0) {
    } else {
      let doc = documents[0];
      if (doc.type === "document") {
        ret = <Documents document={doc} />;
      } else if (doc.type === "img") {
        ret = <Images image={{ id: doc._id, name: doc.name, url: doc.url }} />;
      } else if (doc.type === "mp3") {
        ret = <Audio audio={{ id: doc._id, name: doc.name, url: doc.url }} />;
      }
    }

    return ret;
  };

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {getComponent(documents)}
    </Grid>
  );
};

const Documents: FC<{ document: IFile }> = ({ document }) => {
  const getFileExtIcon = (name: string) => {
    let ret;

    switch (name) {
      case "xls":
      case "xlsx":
        ret = "xlsx.svg";
        break;
      case "pdf":
        ret = "pdf.svg";
        break;
      case "mp3":
        ret = "voice.svg";
        break;
      default:
        ret = "file.svg";
        break;
    }

    return ret;
  };
  const { name, type } = document;

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Grid
        sx={{
          display: "flex",
          gap: 1.5,
        }}
      >
        <Grid sx={{ width: 40, height: 40, display: "flex" }}>
          <img
            style={{ width: "100%" }}
            src={
              type === "document" || type === "mp3"
                ? toAbsoluteUrl(
                    `/media/extension/${getFileExtIcon(
                      name.split(".")?.pop() ?? ""
                    )}`
                  )
                : process.env.REACT_APP_FILES_BASE_URL +
                  `/storage/${document._id}/${document.name}`
            }
            alt={name}
          />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography color={"#3B3B3B"} fontSize={12}>
            {name}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Images: FC<{ image: { id: string; name: string; url: string } }> = ({
  image,
}) => {
  const { name, id } = image;

  return (
    <Box
      component="img"
      sx={{
        borderRadius: 2,
        height: 40,
        width: 40,
        maxHeight: { xs: 233, md: 167 },
        maxWidth: { xs: 350, md: 250 },
      }}
      alt={name}
      src={process.env.REACT_APP_FILES_BASE_URL + `/storage/${id}/${name}`}
    />
  );
};

const Audio: FC<{ audio: { id: string; name: string; url: string } }> = ({
  audio,
}) => {
  return (
    <audio controls>
      <source
        src={
          process.env.REACT_APP_FILES_BASE_URL +
          `/storage/${audio.id}/${audio.name}`
        }
      />
      <source
        src={
          process.env.REACT_APP_FILES_BASE_URL +
          `/storage/${audio.id}/${audio.name}`
        }
        type="audio/mp3"
      />
    </audio>
  );
};

export default ReplyMessage;
