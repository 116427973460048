import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { IMessage } from "./messenger/messenger";
import { IRoom } from "./rooms/rooms";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { contentHeight } from "../../utils/window";
import { voices as dummyVoices, links } from "./dummy";
import Chat from "./chat";
import { IUser } from "../../interfaces";
import SelectUsers, { SelectedUsers } from "app/partials/SelectUsers";
import { FormattedMessage, useIntl } from "react-intl";
import * as chat from "../../store/ducks/chat.duck";
import { chatService } from "../../services/chat.service";
import Snackbar from "../../widgets/Snackbar";
import backdrop from "../../widgets/Backdrop";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Controls from "app/widgets/uncontrolled";
import { TransitionProps } from "@mui/material/transitions";
import { useForm } from "../../hooks/useForm";

const ITEM_HEIGHT = 48;

const getImage = (users: Array<IUser>): string => {
  let ret: string;
  const isGroup = users.length > 2;

  if (!isGroup) {
    const correspondent = users.find((user) => user._id !== "1");

    if (correspondent) {
      ret = correspondent.avatar?.name ?? "/";
    } else {
      ret = "/";
    }
  } else {
    ret = "/";
  }

  return ret;
};

export const getUsersInfo = (users: Array<IUser>, room: IRoom | null) => {
  let ret = { main: "", secondary: "", image: "" };
  const isGroup = users.length > 2;
  let correspondents: string = "";

  if (!isGroup) {
    users.map((user, index) => {
      correspondents += (index ? ", " : "") + user?.fullname;
    });

    if (users.length > 0) {
      ret.main = correspondents;
      ret.secondary = "online";
      ret.image = room ? room.image : "";
    }
  } else {
    ret.main = "New Group";
    ret.secondary = `${users.length} person`;
    ret.image = "media/groupicon.jpeg";
  }

  return ret;
};

const ChatPage = () => {
  const [height, setHeight] = useState<number | null>();
  const intl = useIntl();
  const form = useForm<{ message: string }>();
  const { user: me } = useSelector(({ auth }: RootState) => auth);
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [lastRooms, setLastRooms] = useState<Array<IRoom>>([]);
  const [messages, setMessages] = useState<Array<IMessage>>([]);
  const [voices, setVoices] = useState(dummyVoices);

  const [activeRoom, setActiveRoom] = useState<IRoom | null>(null);
  const [roomSearchText, setRoomSearchText] = useState<string | null>(null);
  const [profileOpen, setProfileOpen] = useState<boolean>(false);
  const [archiveOpen, setArchiveOpen] = useState<boolean>(false);
  const { rooms, type, roomsSkip, roomsLimit, archived, search } = useSelector(
    (state: RootState) => state.chat
  );
  const [selectUserDialogue, setSelectUserDialogue] = useState<boolean>(false);
  const [newRoomMessageDialogue, setNewRoomMessageDialogue] =
    useState<boolean>(false);
  const [addUsers, setAddUsers] = useState<SelectedUsers[]>([]);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [activeReplyMessage, setActiveReplyMessage] = useState<
    IMessage | undefined
  >();

  useEffect(() => {
    setHeight(contentHeight());

    window.addEventListener("resize", function (event) {
      setHeight(contentHeight());
    });

    return () =>
      window.removeEventListener("resize", function (event) {
        setHeight(contentHeight());
      });
  }, []);

  useEffect(() => {
    function changeActive(event: KeyboardEvent) {
      if (event.key === "Escape") {
        setActiveRoom(null);
      }
    }

    window.addEventListener("keydown", changeActive);

    return () => window.removeEventListener("keydown", changeActive);
  }, []);

  useEffect(() => {
    if (activeRoom) {
      let _room = rooms?.find((room) => room._id === activeRoom._id);
      if (_room) {
        setUsers(_room.users);
      }
    }
  }, [activeRoom]);

  const archiveList = (open: boolean) => {
    setArchiveOpen(open);
  };

  const updateRooms = () => {
    dispatch(chat.actions.setRooms([]));

    dispatch(
      chat.actions.getRooms({
        type,
        limit: roomsLimit,
        skip: roomsSkip,
        archived,
        search,
      })
    );
  };

  const sendMessage = (
    message?: string,
    files?: Array<File> | undefined,
    blob?: Blob | undefined
  ) => {
    if (!message && !files && !blob) return;

    if (activeRoom && me) {
      setActiveReplyMessage(undefined);

      dispatch(
        chat.actions.send(
          activeRoom,
          message ?? "",
          files,
          blob,
          me,
          activeReplyMessage?._id,
          users
        )
      );

      // setTimeout(() => {
      //   chatService
      //     .setRead(activeRoom._id)
      //     .then((data) => {
      //       if ("error" in data) {
      //         throw new Error(data.error.message);
      //       }
      //
      //       let { data: room } = data;
      //       setActiveRoom(room);
      //     })
      //     .catch((e) => {
      //       Snackbar.error(e.message);
      //     });
      // }, 1000);
    }
  };

  const onFileUpload = (documents: File[]) => {
    sendMessage("", documents);
  };

  const onVoiceUpload = (blob: Blob) => {
    sendMessage("", undefined, blob);
  };

  const handleRemoveUser = (id: string) => {
    if (activeRoom) {
      chatService.removeUser(activeRoom._id, id).then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: room } = data;

        setUsers(room.users);
        Snackbar.success("User removed");
      });
    }

    dispatch(
      chat.actions.getRooms({
        type,
        limit: roomsLimit,
        skip: roomsSkip,
        archived,
        search,
      })
    );
  };

  const onDelete = () => {
    Snackbar.info(
      "Deleting chat and it's files in progress, it can take a few seconds"
    );

    if (activeRoom) {
      chatService.deleteRoom(activeRoom._id).then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }
        setActiveRoom(null);
        setProfileOpen(false);
        dispatch(chat.actions.roomsHotReload());
      });
    }
  };

  const onArchive = () => {
    Snackbar.info(
      `${
        archiveOpen ? "Activating" : "Archiving"
      } chat in progress, it can take a few seconds`
    );

    activeRoom &&
      chatService
        .archive(activeRoom._id, archiveOpen ? "0" : "1")
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }
          setActiveRoom(null);
          dispatch(chat.actions.roomsHotReload());
        });
  };

  const handleCreateRoom = () => {
    if (!addUsers.length) return;

    let userIds: string[] = [];
    addUsers.map((user) => {
      userIds.push(user._id);
    });

    let message = form.values.message;
    chatService.createRoom(userIds, message).then((data) => {
      if ("error" in data) {
        throw new Error(data.error.message);
      }

      const { data: response } = data;

      setActiveRoom(response);

      setAddUsers([]);
      // dispatch(chat.actions.roomsHotReload());

      setUsers(response.users);
    });
  };

  return (
    <>
      <Grid
        container
        sx={{
          height: height,
        }}
      >
        {me && (
          <Chat
            me={me._id}
            Composer={{
              activeReplyMessage: activeReplyMessage,
              onReplyClose: () => {
                setActiveReplyMessage(undefined);
              },
              onReplyClick: () => {
                console.log("put here <<going to message>> function");
              },
              onFileUpload: (files: File[]) => {
                onFileUpload(files);
              },
              onVoiceUpload: (blobUrl: string, blob: Blob) => {
                onVoiceUpload(blob);
              },
              active: Boolean(activeRoom),
              sendMessage: (message: string) => {
                sendMessage(message);
              },
            }}
            Body={{
              horizontalLineDBLClick: (message) => {
                if (message) setActiveReplyMessage(message);
              },
              messages: messages,
              active: Boolean(activeRoom),
              room: activeRoom?._id ?? "",
              archiveOpen: archiveOpen,
              needResetMessages: archiveOpen,
              updateRoom: (room: IRoom) => {
                setActiveRoom(room);
                setUsers(room.users);
              },
              users: activeRoom?.users ?? [],
            }}
            RoomProps={{
              onBarClick: () => setSelectUserDialogue(true),
              lastData: lastRooms,
              active: activeRoom?._id ?? "",
              onArchiveListOpen: (open: boolean) => {
                archiveList(open);
                setActiveRoom(null);
                setActiveReplyMessage(undefined);
              },
              onClick: (room) => {
                setActiveRoom(room);
                setActiveReplyMessage(undefined);
              },
              onSearch: (text) => {
                setRoomSearchText(text);
              },
            }}
            Header={{
              onClick: () => {
                setProfileOpen(true);
                setActiveRoom(activeRoom);
              },
              onClose: () => {
                setActiveRoom(null);
              },
              room: activeRoom?._id ?? "",
              info: activeRoom
                ? getUsersInfo(users, activeRoom)
                : getUsersInfo([], null),
              onSearch: (message: IMessage) => {
                setActiveReplyMessage(message);
              },
              actionPanel: (
                <>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClick(e);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    <MenuItem
                      key={"profile"}
                      onClick={() => {
                        handleClose();
                        setProfileOpen(true);
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "25px!important" }}>
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.2381 8.6001C10.044 8.6001 11.5079 7.10772 11.5079 5.26677C11.5079 3.42582 10.044 1.93343 8.2381 1.93343C6.43222 1.93343 4.96826 3.42582 4.96826 5.26677C4.96826 7.10772 6.43222 8.6001 8.2381 8.6001Z"
                            fill="#767C91"
                          />
                          <path
                            d="M8.23825 9.71127C5.53067 9.71434 3.33651 11.9511 3.3335 14.7113C3.3335 15.0181 3.57748 15.2668 3.87846 15.2668H12.598C12.899 15.2668 13.143 15.0181 13.143 14.7113C13.14 11.9511 10.9458 9.71431 8.23825 9.71127Z"
                            fill="#767C91"
                          />
                        </svg>
                      </ListItemIcon>
                      <ListItemText>Profile</ListItemText>
                    </MenuItem>
                    {/*<MenuItem key={"mute"} onClick={handleClose}>*/}
                    {/*  <ListItemIcon sx={{ minWidth: "25px!important" }}>*/}
                    {/*    <svg*/}
                    {/*      width="16"*/}
                    {/*      height="17"*/}
                    {/*      viewBox="0 0 16 17"*/}
                    {/*      fill="none"*/}
                    {/*      xmlns="http://www.w3.org/2000/svg"*/}
                    {/*    >*/}
                    {/*      <g clip-path="url(#clip0_2096_30524)">*/}
                    {/*        <path*/}
                    {/*          d="M0.87162 0.800049C0.676358 0.604787 0.359775 0.604787 0.164513 0.800049C-0.0307491 0.995311 -0.0307491 1.31189 0.164513 1.50716L3.74933 5.09197C3.24978 5.20946 2.78816 5.46397 2.41943 5.83271C1.89869 6.35345 1.60575 7.05948 1.60486 7.79593V10.0181C1.60575 10.7546 1.89869 11.4606 2.41943 11.9814C2.94017 12.5021 3.64619 12.795 4.38263 12.7959H5.08763C6.09251 14.1886 7.59416 15.1419 9.28209 15.4587C9.36219 15.4734 9.44456 15.4704 9.52336 15.4498C9.60215 15.4291 9.67545 15.3914 9.73805 15.3393C9.80066 15.2872 9.85103 15.222 9.88562 15.1482C9.92021 15.0745 9.93816 14.994 9.9382 14.9126V11.2808L15.082 16.4247C15.2773 16.6199 15.5939 16.6199 15.7891 16.4247C15.9844 16.2294 15.9844 15.9128 15.7891 15.7176L0.87162 0.800049Z"*/}
                    {/*          fill="#767C91"*/}
                    {/*        />*/}
                    {/*        <path*/}
                    {/*          d="M5.73283 4.25158L9.9382 8.45696V2.90148C9.93816 2.82003 9.92021 2.73958 9.88562 2.66584C9.85103 2.5921 9.80066 2.52686 9.73805 2.47475C9.67545 2.42264 9.60215 2.38494 9.52336 2.36431C9.44456 2.34368 9.36219 2.34063 9.28209 2.35537C7.92481 2.61013 6.68794 3.27633 5.73283 4.25158Z"*/}
                    {/*          fill="#767C91"*/}
                    {/*        />*/}
                    {/*        <path*/}
                    {/*          d="M13.8474 12.3661L13.0476 11.5664C13.5498 10.7779 13.8225 9.8565 13.8225 8.90705C13.8225 7.59627 13.3027 6.33898 12.377 5.41093C12.2822 5.30523 12.2315 5.16725 12.2353 5.02534C12.2391 4.88342 12.297 4.74833 12.3973 4.64781C12.4975 4.54728 12.6324 4.48894 12.7743 4.48477C12.9162 4.4806 13.0544 4.53091 13.1603 4.62538C14.2943 5.76179 14.9312 7.30164 14.9312 8.90705C14.9312 10.152 14.5482 11.3576 13.8474 12.3661Z"*/}
                    {/*          fill="#767C91"*/}
                    {/*        />*/}
                    {/*        <path*/}
                    {/*          d="M11.4151 9.93384L12.241 10.7598C12.5637 10.2014 12.7378 9.56323 12.7378 8.90717C12.7378 7.92672 12.349 6.98631 11.6565 6.29217C11.6053 6.23911 11.544 6.19678 11.4762 6.16767C11.4084 6.13855 11.3355 6.12322 11.2618 6.12258C11.188 6.12194 11.1148 6.136 11.0466 6.16393C10.9783 6.19187 10.9163 6.23312 10.8641 6.28528C10.8119 6.33744 10.7707 6.39947 10.7427 6.46775C10.7148 6.53602 10.7008 6.60918 10.7014 6.68294C10.702 6.75671 10.7174 6.82961 10.7465 6.89739C10.7756 6.96517 10.8179 7.02647 10.871 7.07772C11.3552 7.56342 11.6272 8.22131 11.6272 8.90717C11.6272 9.26371 11.5537 9.61268 11.4151 9.93384Z"*/}
                    {/*          fill="#767C91"*/}
                    {/*        />*/}
                    {/*      </g>*/}
                    {/*      <defs>*/}
                    {/*        <clipPath id="clip0_2096_30524">*/}
                    {/*          <rect*/}
                    {/*            width="16"*/}
                    {/*            height="16"*/}
                    {/*            fill="white"*/}
                    {/*            transform="translate(0 0.600098)"*/}
                    {/*          />*/}
                    {/*        </clipPath>*/}
                    {/*      </defs>*/}
                    {/*    </svg>*/}
                    {/*  </ListItemIcon>*/}
                    {/*  <ListItemText>Mute</ListItemText>*/}
                    {/*</MenuItem>*/}
                    <MenuItem
                      key={"archive"}
                      onClick={() => {
                        handleClose();
                        onArchive();
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "25px!important" }}>
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.8869 1.93478H4.11072C2.57689 1.93478 1.3335 3.17818 1.3335 4.71199C1.3335 5.63227 2.07954 6.37832 2.99983 6.37832H12.9978C13.875 6.38902 14.6072 5.71129 14.6641 4.83584C14.731 3.30196 13.5418 2.00428 12.0079 1.93741C11.9676 1.93564 11.9273 1.93478 11.8869 1.93478Z"
                            fill="#767C91"
                          />
                          <path
                            d="M13.553 7.48921H2.44411C2.13735 7.48921 1.88867 7.73789 1.88867 8.04465V12.4882C1.89049 14.0213 3.13282 15.2636 4.66587 15.2654H11.3312C12.8643 15.2636 14.1066 14.0213 14.1084 12.4882V8.04465C14.1084 7.73789 13.8597 7.48921 13.553 7.48921ZM9.66487 10.2664H6.33221C6.02544 10.2664 5.77677 10.0178 5.77677 9.711C5.77677 9.40424 6.02544 9.15557 6.33221 9.15557H9.66487C9.97163 9.15557 10.2203 9.40424 10.2203 9.711C10.2203 10.0178 9.97163 10.2664 9.66487 10.2664Z"
                            fill="#767C91"
                          />
                        </svg>
                      </ListItemIcon>
                      <ListItemText>
                        {archiveOpen ? "Active" : "Archive"}
                      </ListItemText>
                    </MenuItem>
                    <MenuItem
                      key={"delete"}
                      onClick={() => {
                        handleClose();
                        onDelete();
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "25px!important" }}>
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.9998 4.15565V3.04454C10.9998 2.74986 10.8828 2.46724 10.6744 2.25887C10.466 2.05049 10.1834 1.93343 9.88873 1.93343H6.55539C6.26071 1.93343 5.97809 2.05049 5.76972 2.25887C5.56134 2.46724 5.44428 2.74986 5.44428 3.04454V4.15565H2.6665V5.26676H3.77762V13.6001C3.77762 14.0421 3.95321 14.466 4.26577 14.7786C4.57833 15.0912 5.00225 15.2668 5.44428 15.2668H10.9998C11.4419 15.2668 11.8658 15.0912 12.1783 14.7786C12.4909 14.466 12.6665 14.0421 12.6665 13.6001V5.26676H13.7776V4.15565H10.9998ZM7.6665 11.3779H6.55539V8.04454H7.6665V11.3779ZM9.88873 11.3779H8.77762V8.04454H9.88873V11.3779ZM9.88873 4.15565H6.55539V3.04454H9.88873V4.15565Z"
                            fill="#767C91"
                          />
                        </svg>
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </MenuItem>
                  </Menu>
                </>
              ),
            }}
            Profile={{
              tabs: {
                members: {
                  items: users,
                  onClick: (id) => {
                    window.open(`/users/${id}`, "_blank");
                  },
                  contextMenuItems: [
                    {
                      icon: "fi fi-sr-remove-user",
                      title: "Remove",
                      onClick: (id) => {
                        handleRemoveUser(id);
                      },
                    },
                  ],
                },
                media: {
                  items: activeRoom?.files?.photos ?? [],
                  onClick: (id) => {},
                },
                files: {
                  items: activeRoom?.files?.documents ?? [],
                  onClick: (id) => {},
                },
                links: {
                  items: links,
                  onClick: (id) => {},
                },
                voices: {
                  items: activeRoom?.files?.voices ?? [],
                  onClick: (id) => {
                    let _voices = voices.map((v) => {
                      if (v.id === id) {
                        if (v.active) {
                          v.active = false;
                          v.audio.pause();
                          v.audio.currentTime = 0;
                        } else {
                          v.active = true;
                          v.audio.play();
                        }
                      } else {
                        v.active = false;
                        v.audio.pause();
                        v.audio.currentTime = 0;
                      }

                      return v;
                    });

                    setVoices([..._voices]);
                  },
                },
              },
              actions: {
                onProfileGo: () => {},
                onMuted: () => {},
                onDelete: () => onDelete(),
              },
              info: getUsersInfo(users, activeRoom),
              handleClose: () => {
                setProfileOpen(false);
              },
              open: profileOpen,
            }}
          />
        )}
      </Grid>
      <SelectUsers
        open={selectUserDialogue}
        Title={
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage
              id={"CHAT.NEW_CHAT"}
              defaultMessage={"CHAT.NEW_CHAT"}
            />
          </Typography>
        }
        handleClose={() => {
          setSelectUserDialogue(false);
          setAddUsers([]);
        }}
        handleSave={() => {
          console.log("Users saved");
        }}
        onUserChange={(users) => setAddUsers(users)}
        CloseItem={
          <Button
            autoFocus
            color="inherit"
            type={"submit"}
            form={"document_form"}
            // onClick={handleCreateRoom}
            onClick={() => {
              setSelectUserDialogue(false);
              setNewRoomMessageDialogue(true);
            }}
            sx={{
              backgroundColor: "#0D99FF",
              borderRadius: 2,
              color: "#ffffff",
            }}
          >
            <FormattedMessage
              id={"STANDARD.NEXT"}
              defaultMessage={"STANDARD.NEXT"}
            />
          </Button>
        }
        users={[]}
      />
      <form id={"new_room_message"}>
        <Dialog
          open={newRoomMessageDialogue}
          onClose={() => {
            setNewRoomMessageDialogue(false);
            setAddUsers([]);
          }}
          fullWidth={true}
          maxWidth={"sm"}
          TransitionComponent={Transition}
          sx={{
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#E0F2FF",
              boxShadow: "none",
            }}
          >
            <Toolbar sx={{ minHeight: "48px!important" }}>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"CHAT.MESSAGE"}
                  defaultMessage={"CHAT.MESSAGE"}
                />
              </Typography>
              <Button
                form={"new_room_message"}
                autoFocus
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
                onClick={(event) => {
                  // getUsers();
                  setNewRoomMessageDialogue(false);
                  handleCreateRoom();
                  event.preventDefault();
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent sx={{ p: "16px!important" }}>
            <Controls.Input
              name={"message"}
              label={intl.formatMessage({ id: "CHAT.MESSAGE" })}
              onChange={(event) => {
                if (!event.target.value) return;

                form.values.message = event.target.value;
              }}
            />
          </DialogContent>
        </Dialog>
      </form>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ChatPage;
