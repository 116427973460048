import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import {
  Avatar,
  Box,
  Button,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  Dialog,
  IconButton,
  Stack,
  Typography,
  Tooltip,
  Zoom,
  Badge,
} from "@mui/material";
import { GridTable } from "app/partials/layout/GridTable";
import { useTable } from "app/hooks/subresource/useTable";
import { caseService } from "app/services/case.service";
import { useIntl } from "react-intl";
import { PermissionsGate } from "app/permission/PermissionsGate";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import moment from "moment";
import EditReport from "../../../partials/layout/EditReport";
import { makeStyles } from "@material-ui/core";
import ReportFilter from "./filters/reportFilter";
import Snackbar from "../../../widgets/Snackbar";
import { useForm } from "../../../hooks/useForm";
import { RootState } from "../../../store/store";
import { IReport } from "../../../services/report.service";
import { IUser } from "../../../interfaces";
import ConfirmDialog from "../../../partials/dialogues/confirmDialogue";
import ShowReport from "./showReport";
import EditIcon from "../../../partials/icons/EditIcon";
import { stringAvatar } from "../../../widgets/AvatarBackground";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import ListItem from "@mui/material/ListItem";
import { ISetting } from "../../../services/setting.service";
import UserAvatar from "../../../partials/layout/UserAvatar";

export type FormStateValues = {
  status: string | undefined;
  case: string;
  report_date: string;
};

const useStyles = makeStyles(() => ({
  container: {
    "& .MuiBox-root": {
      "& .MuiGrid-root": {
        marginBottom: "4px!important",
        marginTop: "4px!important",
      },
    },
  },
  calendar: {
    zIndex: 99999,
  },
  dateRangeInput: {
    width: "100%",
    borderRadius: 8,
    border: "none!important",
    "& .ant-picker-focused": {
      boxShadow: "none!important",
    },
    "& .ant-picker": {
      border: "1px solid #E6E6E6!important",
      height: 40,
      backgroundColor: "#fff",
      borderRadius: 8,
      width: "100%",
      color: "#000000!important",
      "& .ant-picker-input input::placeholder": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
  },
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-input": {
          padding: "11px!important",
        },
      },
      "& .MuiInputLabel-root": {
        top: "-5px",
      },
      height: 40,
      backgroundColor: "#fff",
      border: "1px solid #E6E6E6",
      borderRadius: 8,
      m: () => ({}),
      "& .MuiOutlinedInput-notchedOutline": {
        border: "unset",
      },
    },
  },
}));

const initValues = {
  status: "",
  case: "",
  report_date: moment.utc(new Date()).format("YYYY-MM-DD"),
} as FormStateValues;

const Report = () => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const classes = useStyles();
  const { user: me } = useSelector(({ auth }: RootState) => auth);

  const { data, xhrLoading, refresh, remove, updateQuery } = useTable<IReport>({
    fetch: caseService.getReports,
    delete: caseService.deleteReport,
    param: id,
    query: [
      {
        name: "per_page",
        value: 5,
      },
    ],
  });

  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const [editDialog, setEditDialog] = useState<boolean | null>(false);
  const [confirmId, setConfirmId] = useState<string | null>(null);
  const [edit, setEdit] = useState<string>("");

  // @ts-ignore
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      headerName: "ID",
      minWidth: 130,
      hide: true,
    },
    {
      field: "users",
      headerName: intl.formatMessage({ id: "STANDARD.USERS" }),
      renderCell: (params) => {
        return params.row?.users?.map((user: IUser) => {
          return <UserAvatar user={user} />;
        });
      },
      minWidth: 200,
    },
    {
      field: "created_at",
      headerName: intl.formatMessage({ id: "STANDARD.CREATED_AT" }),
      valueGetter: (params) => {
        return moment.unix(params.row?._created_at).utc().format("DD/MM/YYYY");
      },
      minWidth: 110,
    },
    {
      field: "assigned",
      headerName: intl.formatMessage({ id: "STANDARD.ASSIGNED" }),
      renderCell: (params) => {
        return (
          params.row?.assigned && (
            <Avatar
              alt="Remy Sharp"
              sx={{
                backgroundColor: "#0D99FF",
                lineHeight: "0!important",
                width: 32,
                height: 32,
              }}
              src={params.row?.assigned?.file?.base64}
            >
              {params.row?.assigned?.firstname.charAt(0)}
              {params.row?.assigned?.lastname.charAt(0)}
            </Avatar>
          )
        );
      },
      align: "center",
      headerAlign: "center",
      minWidth: 200,
    },
    {
      field: "status",
      headerName: intl.formatMessage({ id: "STANDARD.STATUS" }),
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row align-items-center">
            <span style={{ color: params.row?.status?.color }}>
              {params.row?.status?.title &&
                params.row?.status.title[intl.locale]}
            </span>
          </div>
        );
      },
      minWidth: 200,
    },
    {
      field: "history",
      headerName: intl.formatMessage({ id: "STANDARD.HISTORY" }),
      renderCell: (params) => {
        return (
          <Tooltip
            title={tooltip(
              params.row?.history && params.row?.history.length
                ? params.row?.history
                : []
            )}
            placement="bottom"
            TransitionComponent={Zoom}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#ffffff ",
                  boxShadow: "none",
                  minWidth: 400,
                },
              },
            }}
          >
            <IconButton>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6974 2.02439C11.2688 1.92443 9.83537 2.1325 8.49413 2.63454C7.15289 3.13657 5.93513 3.92085 4.92326 4.9343C3.91139 5.94775 3.12902 7.16673 2.62908 8.50875C2.12914 9.85077 1.9233 11.2845 2.02549 12.713C2.39297 18.0069 7.06939 22 12.9032 22H17.8338C18.9384 21.9987 19.9974 21.5593 20.7785 20.7782C21.5596 19.9971 21.9989 18.9382 22.0003 17.8336V12.2838C22.0314 9.71519 21.0896 7.22986 19.364 5.32686C17.6384 3.42386 15.2569 2.24408 12.6974 2.02439V2.02439ZM12.0008 6.1675C12.3323 6.1675 12.6502 6.29919 12.8846 6.5336C13.119 6.76801 13.2507 7.08593 13.2507 7.41744C13.2507 7.74894 13.119 8.06687 12.8846 8.30127C12.6502 8.53568 12.3323 8.66737 12.0008 8.66737C11.6693 8.66737 11.3514 8.53568 11.117 8.30127C10.8826 8.06687 10.7509 7.74894 10.7509 7.41744C10.7509 7.08593 10.8826 6.76801 11.117 6.5336C11.3514 6.29919 11.6693 6.1675 12.0008 6.1675ZM13.6674 17.0003C13.6674 17.2213 13.5796 17.4332 13.4233 17.5895C13.267 17.7458 13.0551 17.8336 12.8341 17.8336C12.6131 17.8336 12.4011 17.7458 12.2449 17.5895C12.0886 17.4332 12.0008 17.2213 12.0008 17.0003V12.0005H11.1675C10.9465 12.0005 10.7346 11.9127 10.5783 11.7565C10.422 11.6002 10.3342 11.3882 10.3342 11.1672C10.3342 10.9462 10.422 10.7343 10.5783 10.578C10.7346 10.4217 10.9465 10.3339 11.1675 10.3339H12.0008C12.4428 10.3339 12.8667 10.5095 13.1792 10.8221C13.4918 11.1346 13.6674 11.5585 13.6674 12.0005V17.0003Z"
                  fill="#0D99FF"
                />
              </svg>
            </IconButton>
          </Tooltip>
        );
      },
      headerAlign: "center",
      minWidth: 200,
      align: "center",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        let granted = false;

        if (me?.role?.slug !== "employee") {
          granted = true;
        }
        if (me?.role?.slug === "employee") {
          granted =
            params.row?.current_status_step === 1 ||
            params.row?.current_status_step === 3;
        }

        return (
          <>
            {granted ? (
              <IconButton
                onClick={() => {
                  setEdit(params.row?._id);
                }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <></>
            )}
            <PermissionsGate section={"case_evalreports"} scope={"delete"}>
              <>
                <IconButton
                  onClick={() => handleClick(params.row?._id)}
                  data-cy-class={"case_plan_delete"}
                  data-cy-case-id={id}
                  data-cy-plan-id={params.row?._id}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.33333V3.66667C16.5 3.22464 16.3244 2.80072 16.0118 2.48816C15.6993 2.17559 15.2754 2 14.8333 2H9.83333C9.39131 2 8.96738 2.17559 8.65482 2.48816C8.34226 2.80072 8.16667 3.22464 8.16667 3.66667V5.33333H4V7H5.66667V19.5C5.66667 20.163 5.93006 20.7989 6.3989 21.2678C6.86774 21.7366 7.50363 22 8.16667 22H16.5C17.163 22 17.7989 21.7366 18.2678 21.2678C18.7366 20.7989 19 20.163 19 19.5V7H20.6667V5.33333H16.5ZM11.5 16.1667H9.83333V11.1667H11.5V16.1667ZM14.8333 16.1667H13.1667V11.1667H14.8333V16.1667ZM14.8333 5.33333H9.83333V3.66667H14.8333V5.33333Z"
                      fill="#EC3033"
                    />
                  </svg>
                </IconButton>
              </>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const RenderRow = (
    props: ListChildComponentProps<
      Array<{
        from: IUser | null;
        to: IUser | null;
        status: ISetting | null;
        created_at: number;
      }>
    >
  ) => {
    const { index, style, data } = props;
    const intl = useIntl();

    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <Box className="kt-timeline-v2__item">
          <Box className="kt-timeline-v2__item-cricle">
            <Badge color="secondary" variant="dot" />
          </Box>
          <Box className="kt-timeline-v2__item-text kt-padding-top-5">
            <Typography color={"#000"} variant={"body1"}>
              <span>
                {data[index].status
                  ? data[index].status?.title?.[intl.locale] + " "
                  : ""}
              </span>
              <b style={{ color: "#556EE6" }}>
                {data[index].from?.firstname +
                  " " +
                  data[index].from?.lastname ?? ""}
              </b>{" "}
              {"to "}
              <b style={{ color: "#556EE6" }}>
                {data[index].to?.firstname + " " + data[index].to?.lastname ??
                  ""}
              </b>
            </Typography>
            <Typography>
              {moment
                .unix(data[index].created_at)
                .local()
                .format("DD-MM-YYYY HH:mm")}
            </Typography>
          </Box>
        </Box>
      </ListItem>
    );
  };

  const tooltip = (
    histories: {
      from: IUser | null;
      to: IUser | null;
      status: ISetting | null;
      created_at: number;
    }[]
  ) => {
    return (
      <>
        <Box
          className=" kt-timeline-v2-forcase"
          sx={{
            "&::before": {
              content: "unset",
            },
          }}
        >
          <Box className="kt-timeline-v2__items-forcase">
            <FixedSizeList
              height={260}
              width={360}
              itemSize={53}
              itemCount={histories.length}
              itemData={histories}
              overscanCount={5}
            >
              {RenderRow}
            </FixedSizeList>
          </Box>
        </Box>
      </>
    );
  };

  const form = useForm<FormStateValues>(initValues);
  const { report_statuses } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const handleGenerate = (_id: string) => {
    form.values.status = report_statuses?.find(
      (r_p) => r_p.slug_unchangeable === "in_progress"
    )?._id;
    form.values.case = _id;

    let validated = form.validate();
    caseService
      .postReport(_id, validated, [{ name: "lang", value: intl.locale }])
      .then((data) => {
        if ("error" in data) {
          Snackbar.error(data.error.message);
          return;
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.GENERATED" }));
        refresh();
      });
  };

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  return (
    <Box className={classes.container}>
      <PermissionsGate section={"case_evalreports"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete report?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(id, confirmId);
            }}
          >
            Are you sure you want to delete this case report?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <EditReport
        open={Boolean(editDialog)}
        setDialogue={() => setEditDialog(null)}
      />
      {edit && (
        <PermissionsGate section={"case_evalreports"} scope={"read"}>
          <ShowReport
            open={Boolean(edit)}
            setDialogue={() => setEdit("")}
            _case={id}
            _id={edit}
            onDone={refresh}
          />
        </PermissionsGate>
      )}
      <PermissionsGate section={"case_evalreports"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel: (props: { open: boolean; close: () => void }) => {
              return (
                <PermissionsGate section={"case_evalreports"} scope={"create"}>
                  <Dialog
                    open={props.open}
                    onClose={props.close}
                    fullWidth={true}
                    maxWidth={"xs"}
                  >
                    <DialogTitle>
                      <Typography variant={"h6"}>Generate report?</Typography>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Are you sure, that you want to generate a new report?
                        Draft will be created automatically buy pressing agree.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={props.close}>Disagree</Button>
                      <Button
                        onClick={() => {
                          handleGenerate(id);
                          props.close();
                        }}
                        autoFocus
                      >
                        Agree
                      </Button>
                    </DialogActions>
                  </Dialog>
                </PermissionsGate>
              );
            },
            filters: (props: { show: boolean }) => {
              return <ReportFilter show={props.show} />;
            },
          }}
          search={(queries) => updateQuery(queries)}
          quickSearchToolbar={true}
        />
      </PermissionsGate>
    </Box>
  );
};

export default Report;
