import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { IRole, IUrlQuery, IUser } from "app/interfaces";
import { Box, Grid, Theme } from "@mui/material";
import { DatePicker, Space } from "antd";
import "antd/dist/antd.css";
import { makeStyles } from "@material-ui/core";
import { userService } from "app/services";
import Snackbar from "app/widgets/Snackbar";
import { ICase } from "app/services/case.service";
import Controls from "app/widgets/uncontrolled";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";

const useStyle = makeStyles((theme: Theme) => ({
  rangePicker: {
    width: "100%",
    height: 40,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
}));

const UserContractFilter = (props: {
  id: string;
  show: boolean;
  updateQuery: (queries: IUrlQuery[]) => void;
  queries: Array<IUrlQuery> | undefined;
  setQuery: Dispatch<SetStateAction<IUrlQuery[] | undefined>>;
}) => {
  const intl = useIntl();
  const contentRef = useRef<HTMLDivElement>(null);
  const [from, setFrom] = useState<string>("");
  const [to, setTo] = useState<string>("");
  const classes = useStyle();
  const { RangePicker } = DatePicker;
  const [cases, setCases] = useState<Array<ICase>>();
  const { roles } = useSelector((state: RootState) => state.permission);
  const [moderators, setModerators] = useState<Array<IUser>>();

  const handleQuery = (name: string, value: string) => {
    props.updateQuery([
      {
        name,
        value,
      },
    ]);
  };

  useEffect(() => {
    userService
      .getCases(props.id, [
        {
          name: "pagination",
          value: "0",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: cases } = data;
        setCases(cases);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  useEffect(() => {
    const _moderator = roles.find((r: IRole) => r.slug === "moderator")?._id;
    if (!_moderator) return;

    userService
      .getAll([
        {
          name: "role",
          value: _moderator,
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: moderators } = data;
        setModerators(moderators);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [cases]);

  const handleFromToChange = (dateString: Array<string>) => {
    setFrom(dateString[0]);

    if (dateString[1].length > 0) setTo(dateString[1]);
  };

  useEffect(() => {
    if (from) handleQuery("from", from);

    if (to) handleQuery("to", to);
  }, [from, to]);

  return (
    <Box
      sx={{
        overflow: "hidden",
        transition: ".2s all",
        height: props.show ? contentRef?.current?.scrollHeight + "px" : 0,
      }}
      ref={contentRef}
    >
      <Grid container spacing={1} sx={{ marginBottom: 3 }}>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Space className={classes.antSpace} direction="vertical" size={12}>
            <RangePicker
              allowEmpty={[true, true]}
              className={classes.rangePicker}
              placeholder={[
                intl.formatMessage({
                  id: "STANDARD.FROM",
                  defaultMessage: "From",
                }),
                intl.formatMessage({
                  id: "STANDARD.TO",
                  defaultMessage: "To",
                }),
              ]}
              separator={"―"}
              onCalendarChange={(value, dateString) => {
                if (!dateString[0] && !dateString[1]) {
                  let _queries = props.queries?.filter(
                    (q) => q.name !== "from" && q.name !== "to"
                  );

                  props.setQuery(_queries);

                  setFrom("");
                  setTo("");

                  return;
                }

                handleFromToChange(dateString);
              }}
            />
          </Space>
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
              "& .MuiFormLabel-root": {
                top: "-5px",
              },
              "& .MuiInputBase-input": {
                padding: "9.5px 14px",
              },
            }}
            name={"case"}
            options={
              cases &&
              cases.map((_case) => {
                return {
                  id: _case._id,
                  title: _case.key,
                };
              })
            }
            label={intl.formatMessage({
              id: "CASE.INFORMATION.CASE",
            })}
            onChange={(e) => {
              handleQuery("case", e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
              "& .MuiFormLabel-root": {
                top: "-5px",
              },
              "& .MuiInputBase-input": {
                padding: "9.5px 14px",
              },
            }}
            name={"offerer"}
            options={
              moderators &&
              moderators.map((moderator) => {
                return {
                  id: moderator._id,
                  title: moderator.fullname,
                };
              })
            }
            label={intl.formatMessage({
              id: "ROLE.MODERATOR",
            })}
            onChange={(e) => {
              handleQuery("offerer", e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserContractFilter;
