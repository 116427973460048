import React, { useEffect, useMemo, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import { IconButton } from "@mui/material";
import { IUser } from "app/interfaces";
import { GridTable } from "app/partials/layout/GridTable";
import { useTable } from "app/hooks/useTable";
import { caseService, ICase } from "app/services/case.service";
import NewCase from "./store";
import { useIntl } from "react-intl";
import {
  hasPermission,
  PermissionsGate,
} from "../../permission/PermissionsGate";
import ConfirmDialog from "../../partials/dialogues/confirmDialogue";
import { usePermissions } from "../../hooks/useRole";
import CaseFilter from "./filter";
import { useHistory, useLocation } from "react-router-dom";
import EditIcon from "../../partials/icons/EditIcon";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import UserAvatar from "../../partials/layout/UserAvatar";
import RecoverIcon from "../../partials/icons/RecoverIcon";
import DeleteIcon from "app/partials/icons/DeleteIcon";
import Snackbar from "../../widgets/Snackbar";

const Cases = () => {
  const [confirmId, setConfirmId] = useState<string | null>(null);
  const [archiveConfirmId, setArchiveConfirmId] = useState<string | null>(null);
  const history = useHistory();
  const { user: me } = useSelector(({ auth }: RootState) => auth);
  let { search } = useLocation();
  let query = useMemo(() => new URLSearchParams(search), [search]);
  const parameter = query.get("f_type") ?? "";

  const { data, xhrLoading, refresh, remove, updateQuery, setQuery, queries } =
    useTable<ICase>({
      fetch: caseService.getAll,
      delete: caseService.delete,
      query: [
        {
          name: "per_page",
          value: 5,
        },
        {
          name: "f_type",
          value: parameter,
        },
      ],
    });

  const [quickCardsCount, setQuickCardsCount] = useState<{
    archived: number;
    expired: number;
    not_started: number;
  }>();

  useEffect(() => {
    caseService.getQuickCardsCount().then((data) => {
      if ("error" in data) {
        throw new Error(data.error.message);
      }

      const { data: counts } = data;

      setQuickCardsCount(counts);
    });
  }, [xhrLoading]);

  const handleRestore = (_id: string) => {
    caseService
      .restore(_id, [{ name: "per_page", value: 5 }])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        } else {
          Snackbar.success(intl.formatMessage({ id: "SNACKBAR.RECOVERED" }));
          refresh();
        }
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const permissions = usePermissions();

  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const intl = useIntl();

  const columns: IColumns[] = [
    {
      field: "_id",
      headerName: intl.formatMessage({ id: "CASE.CASENUMBER" }),
      align: "center",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
    },
    {
      field: "key",
      headerName: intl.formatMessage({ id: "CASE.CASENUMBER" }),
      align: "center",
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({ id: "CASE.USERS" }),
      field: "users",
      renderCell: (params) => {
        return params.row.users.map((user: IUser) => {
          return <UserAvatar user={user} />;
        });
      },
      minWidth: 200,
      sortable: false,
    },
    {
      headerName: intl.formatMessage({ id: "CASE.FOCUS_TYPE" }),
      field: "focus_area_type",
      valueGetter: (params) => {
        return (
          params.row?.focus_type?.title &&
          params.row?.focus_type?.title[intl.locale]
        );
      },
      minWidth: 150,
      sortable: false,
    },
    {
      headerName: intl.formatMessage({ id: "CASE.FOCUS_AREA" }),
      field: "focus_area_id",
      valueGetter: (params) => {
        return (
          params.row?.focus_area?.title &&
          params.row?.focus_area?.title[intl.locale]
        );
      },
      minWidth: 250,
      sortable: false,
    },
    {
      headerName: intl.formatMessage({
        id: "CASE.INFORMATION.COMMUNE.COMMUNE",
      }),
      field: "commune",
      valueGetter: (params) => {
        return params.row?.commune?.name;
      },
      minWidth: 200,
      sortable: false,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            {params.row?.is_archived ? (
              <>
                <IconButton
                  onClick={() => handleRestore(params.row?._id)}
                  data-cy-class={"case_recover"}
                  data-cy-id={params.row?._id}
                >
                  <RecoverIcon />
                </IconButton>
                <PermissionsGate section={"accounting"} scope={"delete"}>
                  <IconButton onClick={() => handleClick(params.row?._id)}>
                    <DeleteIcon />
                  </IconButton>
                </PermissionsGate>
              </>
            ) : (
              <>
                <PermissionsGate section={"cases"} scope={"read"}>
                  <IconButton
                    onClick={() => show(params.row?._id)}
                    data-cy-class={"case_show"}
                    data-cy={params.row?._id}
                  >
                    <EditIcon />
                  </IconButton>
                </PermissionsGate>
                <PermissionsGate section={"cases"} scope={"delete"}>
                  <IconButton
                    onClick={() => handleArchive(params.row?._id)}
                    data-cy-class={"case_delete"}
                    data-cy-id={params.row?._id}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.357,2.921l-1.416,2.3a1,1,0,0,1-1.7-1.048l1.416-2.3a3.948,3.948,0,0,1,6.692,0l4.2,6.834.441-2.365a1,1,0,0,1,1.967.366l-.5,2.67a3,3,0,0,1-3.529,2.393l-2.63-.518a1,1,0,0,1,.386-1.962l2.133.421L13.643,2.921A1.934,1.934,0,0,0,10.357,2.921ZM23.409,14.98l-.968-1.572a1,1,0,1,0-1.7,1.048l.968,1.572a1.934,1.934,0,0,1,.041,1.98A1.91,1.91,0,0,1,20.062,19H12.676l1.271-1.269a1,1,0,0,0-1.412-1.416L10.843,18A2.862,2.862,0,0,0,10,19.945v.062a2.885,2.885,0,0,0,.858,2.078l1.682,1.652a1,1,0,1,0,1.4-1.427L12.609,21h7.453a3.948,3.948,0,0,0,3.347-6.02ZM8.047,9.146a3,3,0,0,0-3.53-2.393l-2.629.518a1,1,0,1,0,.386,1.962l2.108-.415L.591,14.98A3.948,3.948,0,0,0,3.937,21H7.08a1,1,0,0,0,0-2H3.937a1.91,1.91,0,0,1-1.684-.992,1.934,1.934,0,0,1,.041-1.98L6.132,9.79l.446,2.394a1,1,0,0,0,1.967-.368Z"
                        fill="rgb(251, 183, 60)"
                      />
                    </svg>
                  </IconButton>
                </PermissionsGate>
              </>
            )}
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      sortable: false,
      minWidth: 100,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const show = (_id: string) => {
    const url = `/cases/${_id}?section=overview`;
    // window.open(url);
    history.push(url);
  };

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  const handleArchive = (_id: string) => {
    setArchiveConfirmId(_id);
  };

  const archive = (_id: string) => {
    caseService
      .archive(_id, [
        {
          name: "per_page",
          value: 5,
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        refresh();
      });
  };

  const resetCardQuery = (
    isActive: boolean,
    name: "not_started" | "expired" | "archive"
  ) => {
    let _queries = queries?.filter(
      (q) =>
        q.name !== "archive" && q.name !== "expired" && q.name !== "not_started"
    );

    if (!_queries) return;

    if (isActive && queries && _queries) {
      let __queries = _queries.filter((q) => q.name !== name);
      setQuery(__queries);
    } else {
      setQuery([
        ..._queries,
        {
          name: name,
          value: "1",
        },
      ]);
    }
  };

  return (
    <>
      <PermissionsGate section={"cases"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete Case?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(confirmId);
            }}
          >
            Are you sure you want to delete this case?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"cases"} scope={"delete"}>
        {archiveConfirmId ? (
          <ConfirmDialog
            title="Archive Case?"
            open={Boolean(archiveConfirmId)}
            setOpen={() => setArchiveConfirmId(null)}
            onConfirm={() => {
              archive(archiveConfirmId);
            }}
          >
            Are you sure you want to archive this case?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"cases"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "cases", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <NewCase
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,
            quickCards:
              me?.role.slug !== "citizen"
                ? [
                    {
                      title: intl.formatMessage({ id: "QUICK_CARD_ARCHIVED" }),
                      onClick: (isActive: boolean) =>
                        resetCardQuery(isActive, "archive"),
                      icon: "fi-sr-inbox",
                      value: quickCardsCount ? quickCardsCount.archived : 0,
                    },
                    {
                      title: intl.formatMessage({ id: "QUICK_CARD_EXPIRED" }),
                      onClick: (isActive: boolean) =>
                        resetCardQuery(isActive, "expired"),
                      icon: "fi-sr-time-delete",
                      value: quickCardsCount ? quickCardsCount.expired : 0,
                    },
                    {
                      title: intl.formatMessage({
                        id: "QUICK_CARD_NOT_STARTED",
                      }),
                      onClick: (isActive: boolean) =>
                        resetCardQuery(isActive, "not_started"),
                      icon: "fi-sr-calendar-clock",
                      value: quickCardsCount ? quickCardsCount.not_started : 0,
                    },
                  ]
                : undefined,
            filters:
              me?.role.slug !== "citizen"
                ? (props: { show: boolean }) => {
                    return (
                      <CaseFilter show={props.show} updateQuery={updateQuery} />
                    );
                  }
                : undefined,
          }}
          search={(queries) => updateQuery(queries)}
          quickSearchToolbar={true}
        />
      </PermissionsGate>
    </>
  );
};

export default Cases;
