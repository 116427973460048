import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Snackbar from "../../../widgets/Snackbar";
import { caseService, documentService } from "../../../services";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Controls from "../../../widgets/uncontrolled";
import { TransitionProps } from "@mui/material/transitions";
import { useForm } from "../../../hooks/useForm";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import moment from "moment";
import { IFinance, IUser } from "../../../interfaces";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmDialog from "../../../partials/dialogues/confirmDialogue";
import { PermissionsGate } from "../../../permission/PermissionsGate";
import { makeStyles } from "@material-ui/core";
import DatePicker from "antd/lib/date-picker";
import Space from "antd/lib/space";
import AttachmentsInfo from "./parts/attachmentsInfo";

interface FormProps {
  _case: string;
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: string;
  date: string;
  amount: string;
  direction: string;
  type: string;
  status: string;
  users: Array<IUser>;
  files?: Array<string>;
};

const initValues = {
  title: "",
  date: moment(new Date()).format("YYYY-MM-DD"),
  amount: "",
  direction: "outcome",
  type: "transfer",
  status: "",
  users: [],
  files: [],
} as FormStateValues;

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  dropzone: {
    "& .container": {
      paddingRight: "0!important",
      paddingLeft: "0!important",
      "& div": {
        padding: "70px!important",
        borderColor: "#E6E6E6!important",
        borderRadius: 2,
        backgroundColor: "#E6E6E6",
        "& p": {
          marginBottom: "0px!important",
        },
      },
      "& aside": {
        display: "none",
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
}));

const ShowFinance = (props: FormProps) => {
  const intl = useIntl();
  const classes = useStyles();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { finance_statuses } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const [finance, setFinance] = useState<IFinance>();
  const [files, setFiles] = useState<any>([]);
  const { user } = useSelector(({ auth }: RootState) => auth);

  useEffect(() => {
    form.values.files = files?.attachments?.map((file: any) => file._id);
  }, [files]);

  useEffect(() => {
    if (loading) return;

    caseService
      .getOneFinance(props._case, props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        let { data: finance } = data;
        setFinance(finance);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._case, props._id, loading]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    if (
      user?.role?.slug !== "moderator" &&
      user?.role?.slug !== "super-admin"
    ) {
      if (
        finance &&
        finance?.direction === "income" &&
        form.values.direction === "outcome"
      ) {
        Snackbar.error("Changing direction not allowed");
        return;
      }
    }
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    caseService
      .updateFinance(props._case, props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const deleteDocument = (documentId: string) => {
    setLoading(true);
    documentService
      .delete(documentId)
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
      })
      .catch((e) => {
        Snackbar.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [confirmId, setConfirmId] = useState<string | null>(null);

  useEffect(() => {
    if (finance) {
      form.values.amount = finance.amount.toString() ?? "0";
      form.values.date = moment
        .unix(+finance.date)
        .local()
        .format("YYYY-MM-DD");
      // form.values.date = finance.date;
      form.values.type = finance.type;
      form.values.direction = finance.direction;
      form.values.status = finance.status?._id.toString();
      form.values.title = finance.title;
      form.values.files = files?.file_ids?.map((file: any) => file._id);

      setFiles(finance.documents);
    }
  }, [finance]);

  return (
    <>
      <PermissionsGate section={"activity_payments"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete document?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              deleteDocument(confirmId);
            }}
          >
            Are you sure you want to delete this document?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"sm"}
          TransitionComponent={Transition}
          sx={{
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#E0F2FF",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"CASE.FINANCE.SHOW"}
                  defaultMessage={"CASE.FINANCE.SHOW"}
                />
              </Typography>
              <Button
                autoFocus
                data-cy-class={"finance_form"}
                data-cy-case-id={props._case}
                data-cy-finance-id={props._id}
                color="inherit"
                type={"submit"}
                form={"finance_form"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent sx={{ p: "20px 44px" }}>
            <form
              id={"finance_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && finance ? (
                    <Box>
                      <Box className={classes.input}>
                        <Typography variant={"body1"}>
                          <FormattedMessage
                            id={"STANDARD.TITLE"}
                            defaultMessage={"STANDARD.TITLE"}
                          />
                        </Typography>
                        <Controls.Input
                          name={"title"}
                          label={""}
                          defaultValue={finance?.title}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.title = event.target.value;
                          }}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && finance ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"STANDARD.DATE"}
                          defaultMessage={"STANDARD.DATE"}
                        />
                      </Typography>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker
                          className={classes.datePicker}
                          dropdownClassName={classes.antDropdown}
                          onChange={(value, dateString) => {
                            form.values.date = dateString;
                          }}
                          defaultValue={moment(finance?.date, "X").local()}
                        />
                      </Space>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <DatePicker />
                    </Skeleton>
                  )}
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={
                    user?.role?.slug === "moderator" ||
                    user?.role?.slug === "super-admin"
                      ? 4
                      : 12
                  }
                  xl={
                    user?.role?.slug === "moderator" ||
                    user?.role?.slug === "super-admin"
                      ? 4
                      : 12
                  }
                >
                  {loaded && finance ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"CASE.FINANCE.AMOUNT"}
                          defaultMessage={"CASE.FINANCE.AMOUNT"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"amount"}
                        label={""}
                        type={"number"}
                        defaultValue={finance?.amount}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.amount = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"amount"}
                        label={intl.formatMessage({
                          id: "CASE.FINANCE.AMOUNT",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                {user?.role?.slug === "moderator" ||
                  (user?.role?.slug === "super-admin" && (
                    <>
                      <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                        {loaded && finance ? (
                          <Box className={classes.input}>
                            <Typography variant={"body1"}>
                              <FormattedMessage
                                id={"CASE.FINANCE.DIRECTION"}
                                defaultMessage={"CASE.FINANCE.DIRECTION"}
                              />
                            </Typography>
                            <Controls.Select
                              name={"direction"}
                              formId={"direction"}
                              options={[
                                {
                                  id: "income",
                                  title: intl.formatMessage({
                                    id: "CASE.FINANCE.PLUS",
                                  }),
                                },
                                {
                                  id: "outcome",
                                  title: intl.formatMessage({
                                    id: "CASE.FINANCE.MINUS",
                                  }),
                                },
                              ]}
                              defaultValue={finance?.direction}
                              label={""}
                              onChange={(event) => {
                                if (!event.target.value) return;

                                form.values.direction = event.target.value;
                              }}
                            />
                          </Box>
                        ) : (
                          <Skeleton width={"100%"}>
                            <Controls.Select
                              name={"direction"}
                              options={[]}
                              label={intl.formatMessage({
                                id: "CASE.FINANCE.DIRECTION",
                              })}
                              onChange={() => {}}
                            />
                          </Skeleton>
                        )}
                      </Grid>
                      <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                        {loaded && finance ? (
                          <Box className={classes.input}>
                            <Typography variant={"body1"}>
                              <FormattedMessage
                                id={"STANDARD.STATUS"}
                                defaultMessage={"STANDARD.STATUS"}
                              />
                            </Typography>
                            <Controls.Select
                              name={"status"}
                              formId={"status"}
                              options={finance_statuses?.map((f_s) => {
                                return {
                                  id: f_s._id,
                                  title: f_s.title[intl.locale],
                                };
                              })}
                              defaultValue={finance?.status?._id}
                              label={""}
                              onChange={(event) => {
                                if (!event.target.value) return;
                                form.values.status = event.target.value;
                              }}
                            />
                          </Box>
                        ) : (
                          <Skeleton width={"100%"}>
                            <Controls.Select
                              name={"status"}
                              options={[]}
                              label={intl.formatMessage({
                                id: "STANDARD.STATUS",
                              })}
                              onChange={() => {}}
                            />
                          </Skeleton>
                        )}
                      </Grid>
                    </>
                  ))}

                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && finance ? (
                    <Box className={classes.dropzone}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        <FormattedMessage
                          id={"STANDARD.FILES"}
                          defaultMessage={"STANDARD.FILES"}
                        />
                      </Typography>
                      <AttachmentsInfo setParams={setFiles} params={finance} />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"files"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowFinance;
