import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import Controls from "app/widgets/uncontrolled";
import PersonIcon from "@mui/icons-material/Person";
import { TransitionProps } from "@mui/material/transitions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import * as auth from "../../store/ducks/auth.duck";
import { IUser } from "../../interfaces";
import { userService } from "../../services";
import CheckIcon from "@mui/icons-material/Check";
import LoginIcon from "@mui/icons-material/Login";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { getUserByPhone, login } from "../../crud/auth.crud";
import Snackbar from "../../widgets/Snackbar";
import { useForm } from "../../hooks/useForm";
import { useCookies } from "react-cookie";
import { stringAvatar } from "app/widgets/AvatarBackground";

type Props = {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
};

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
}));

const ChangeUser = (props: Props) => {
  const intl = useIntl();
  const [step, setStep] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const form = useForm<{ password: string }>();
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }: RootState) => auth);
  const { switchUsers } = useSelector(({ auth }: RootState) => auth);
  const [boundUsers, setBoundUsers] = useState<IUser[]>([]);
  const [recommendations, setRecommendations] = useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser | undefined>(
    undefined
  );
  const classes = useStyles();
  const [, setCookie] = useCookies();

  useEffect(() => {
    if (props.open) switchUsers && setBoundUsers(switchUsers);
  }, [props.open]);

  useEffect(() => {
    if (step === 1)
      userService.getRecommendations().then((data) => {
        if ("error" in data) throw new Error(data.error.message);

        const { data: users } = data;

        setRecommendations(users);
      });
  }, [step]);

  const handleClose = () => {
    props.setDialogue(false);
    setStep(0);
  };

  const switchUser = (userId: string) => {
    userService.switchUser({ _id: userId }).then((data) => {
      const switching = dispatch(
        auth.actions.switchToUser(data.accessToken, data.business)
      );

      if (switching) window.location.replace("/");
    });
  };

  const getIcon = (id: string) => {
    if (id === user?._id) return <CheckIcon sx={{ color: "success.main" }} />;
    else return <LoginIcon sx={{ color: "primary.main" }} />;
  };

  const handleUser = (_user: IUser) => {
    const checkUser = switchUsers?.find((user) => user._id === _user._id);

    if (checkUser) {
      switchUser(_user._id);
    } else {
      setStep(2);
      setSelectedUser(_user);
    }
  };

  const checkLogin = () => {
    if (search.length) {
      getUserByPhone(search).then((data: any) => {
        const response = data.data;

        if (data.status === "error") {
          Snackbar.error(data.description);
          return;
        }
        if (response?._id) {
          setStep(2);
          setSelectedUser(response);
        } else if (response.length > 0) {
          setStep(3);
          setRecommendations(response);
        }
      });
    }
  };

  const onSubmit = () => {
    if (selectedUser && form.values.password) {
      login(
        selectedUser._id,
        selectedUser.phone,
        form.values.password,
        selectedUser.businesses[0]._id
      ).then((data: any) => {
        if ("error" in data) {
          Snackbar.error(data.error.message);
          return;
        } else {
          const switching = dispatch(
            auth.actions.switchToUser(data.accessToken, data.business)
          );
          let date = new Date();
          date.setDate(date.getDate() + 30);

          setCookie("arc_token", data.accessToken, {
            path: "/",
            expires: date,
            httpOnly: true,
            secure: true,
          });

          if (switching) window.location.replace("/");
        }
      });
    }
  };

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.input}
            >
              <Controls.Input
                name={"user"}
                label={""}
                onChange={(event: any) => {
                  if (!event.target.value) return;

                  setSearch(event.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Button
                onClick={() => setStep(0)}
                sx={{
                  width: "100%",
                  textAlign: "center",
                  backgroundColor: "#f2f5f9",
                  borderColor: "#f2f5f9",
                  "&:hover": {
                    backgroundColor: "#f2f5f9!important",
                  },
                }}
              >
                {intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })}
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Button
                sx={{
                  width: "100%",
                  textAlign: "center",
                  backgroundColor: "primary.main",
                  borderColor: "primary.main",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "primary.main",
                  },
                }}
                onClick={checkLogin}
              >
                {intl.formatMessage({ id: "STANDARD.NEXT" })}
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.input}
            >
              <List
                sx={{
                  width: "100%",
                  backgroundColor: "background.paper",
                  pt: "0px!important",
                  pb: "0px!important",
                }}
              >
                {recommendations?.map((recommendation) => {
                  return (
                    <ListItem disablePadding>
                      <ListItemButton
                        selected={recommendation._id === user?._id}
                        data-cy-name={recommendation.firstname}
                        onClick={(event) => {
                          handleUser(recommendation);
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            style={{
                              backgroundColor:
                                recommendation?.role?.color ?? "#0D99FF",
                            }}
                            alt={recommendation.fullname}
                            sx={
                              {
                                // backgroundColor: "transparent",
                                // "& .MuiAvatar-img": {
                                //   objectFit: "scale-down",
                                // },
                              }
                            }
                            src={recommendation.file?.base64}
                          >
                            {recommendation.firstname.charAt(0)}
                            {recommendation.lastname.charAt(0)}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant={"body1"}
                              sx={{ color: "#0D99FF" }}
                            >
                              {recommendation.fullname}
                            </Typography>
                          }
                          secondary={
                            <React.Fragment>
                              <Chip
                                label={recommendation.businesses[0]?.name}
                                sx={{
                                  bgcolor: "#556EE6",
                                  color: "#fff",
                                  fontWeight: 600,
                                }}
                              />
                              <Chip
                                label={recommendation.role?.title?.en}
                                sx={{
                                  bgcolor: recommendation.role?.color,
                                  color: "#fff",
                                  fontWeight: 600,
                                }}
                              />
                            </React.Fragment>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <>
            <form
              id={"login_form"}
              onSubmit={(e) => form.handleSubmit(e, onSubmit)}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      mt: 2,
                    }}
                  >
                    <Avatar
                      style={{
                        backgroundColor: selectedUser?.role?.color ?? "#0D99FF",
                      }}
                      src={selectedUser?.file?.base64}
                      alt={selectedUser?.firstname}
                      sx={{
                        width: 140,
                        height: 140,
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      variant="square"
                    >
                      {selectedUser?.firstname.charAt(0)}
                      {selectedUser?.lastname.charAt(0)}
                    </Avatar>
                    <Typography sx={{ mt: 2 }} variant={"h5"}>
                      {selectedUser?.fullname}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.input}
                  sx={{ mb: 2, mt: 2 }}
                >
                  <Controls.Input
                    type="password"
                    margin="normal"
                    id="password"
                    // // label="Password"
                    className="kt-width-full"
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.password = event.target.value;
                    }}
                    name="password"
                    InputProps={{
                      startAdornment: (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.8331 9.02V7.83332C17.8331 4.61168 15.2215 2 11.9998 2C8.77814 2 6.1665 4.61168 6.1665 7.83332V9.02C4.64986 9.68191 3.66869 11.1786 3.6665 12.8333V17.8333C3.66924 20.1334 5.53311 21.9973 7.83314 22H16.1665C18.4665 21.9973 20.3304 20.1334 20.3331 17.8333V12.8333C20.331 11.1786 19.3498 9.68191 17.8331 9.02ZM12.8331 16.1667C12.8331 16.6269 12.4601 17 11.9998 17C11.5396 17 11.1665 16.6269 11.1665 16.1667V14.5C11.1665 14.0398 11.5396 13.6667 11.9998 13.6667C12.4601 13.6667 12.8331 14.0398 12.8331 14.5V16.1667ZM16.1665 8.66668H7.83314V7.83336C7.83314 5.53219 9.69861 3.66668 11.9998 3.66668C14.301 3.66668 16.1665 5.53215 16.1665 7.83336V8.66668Z"
                            fill="#0D99FF"
                          />
                        </svg>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ mt: 2 }}>
                  <Button
                    onClick={() => setStep(0)}
                    sx={{
                      width: "100%",
                      textAlign: "center",
                      backgroundColor: "#f2f5f9",
                      borderColor: "#f2f5f9",
                      "&:hover": {
                        backgroundColor: "#f2f5f9!important",
                      },
                    }}
                  >
                    {intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })}
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ mt: 2 }}>
                  <Button
                    form={"login_form"}
                    type={"submit"}
                    sx={{
                      width: "100%",
                      textAlign: "center",
                      backgroundColor: "primary.main",
                      borderColor: "primary.main",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                  >
                    {intl.formatMessage({ id: "AUTH.LOGIN.BUTTON" })}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        );
      case 3:
        return (
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.input}
            >
              <List
                sx={{
                  width: "100%",
                  backgroundColor: "background.paper",
                  pt: "0px!important",
                  pb: "0px!important",
                }}
              >
                {recommendations?.map((recommendation) => {
                  return (
                    <ListItem disablePadding>
                      <ListItemButton
                        selected={recommendation._id === user?._id}
                        data-cy-name={recommendation.firstname}
                        onClick={() => {
                          handleUser(recommendation);
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            style={{
                              backgroundColor:
                                recommendation?.role?.color ?? "#0D99FF",
                            }}
                            alt={recommendation.fullname}
                            sx={
                              {
                                // backgroundColor: "transparent",
                                // "& .MuiAvatar-img": {
                                //   objectFit: "scale-down",
                                // },
                              }
                            }
                            src={recommendation.file?.base64}
                          >
                            {recommendation.firstname.charAt(0)}
                            {recommendation.lastname.charAt(0)}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant={"body1"}
                              sx={{ color: "#0D99FF" }}
                            >
                              {recommendation.fullname}
                            </Typography>
                          }
                          secondary={
                            <React.Fragment>
                              <Chip
                                label={recommendation.businesses[0]?.name}
                                sx={{
                                  bgcolor: "#556EE6",
                                  color: "#fff",
                                  fontWeight: 600,
                                }}
                              />
                              <Chip
                                label={recommendation.role?.title?.en}
                                sx={{
                                  bgcolor: recommendation.role?.color,
                                  color: "#fff",
                                  fontWeight: 600,
                                }}
                              />
                            </React.Fragment>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Button
                onClick={() => setStep(0)}
                sx={{
                  width: "100%",
                  textAlign: "center",
                  backgroundColor: "#f2f5f9",
                  borderColor: "#f2f5f9",
                  "&:hover": {
                    backgroundColor: "#f2f5f9!important",
                  },
                }}
              >
                {intl.formatMessage({ id: "STANDARD.CLOSE" })}
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Button
                sx={{
                  width: "100%",
                  textAlign: "center",
                  backgroundColor: "primary.main",
                  borderColor: "primary.main",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "primary.main",
                  },
                }}
                onClick={() => setStep(1)}
              >
                {intl.formatMessage({ id: "CHANGE_ACCOUNT" })}
              </Button>
            </Grid>
          </Grid>
        );

      default:
        return (
          <>
            <List
              sx={{
                width: "100%",
                backgroundColor: "background.paper",
                pt: "0px!important",
                pb: "0px!important",
                mb: 2,
              }}
            >
              {boundUsers?.map((recommendation) => {
                return (
                  <ListItem
                    secondaryAction={getIcon(recommendation._id)}
                    disablePadding
                  >
                    <ListItemButton
                      selected={recommendation._id === user?._id}
                      data-cy-name={recommendation.firstname}
                      onClick={() => {
                        recommendation._id !== user?._id &&
                          dispatch(
                            auth.actions.updateSwitchUsers(recommendation)
                          );
                        switchUser(recommendation._id);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          style={{
                            backgroundColor:
                              recommendation?.role?.color ?? "#0D99FF",
                          }}
                          alt={recommendation.fullname}
                          sx={
                            {
                              // backgroundColor: "transparent",
                              // "& .MuiAvatar-img": {
                              //   objectFit: "scale-down",
                              // },
                            }
                          }
                          src={recommendation.file?.base64}
                        >
                          {recommendation.firstname.charAt(0)}
                          {recommendation.lastname.charAt(0)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            variant={"body1"}
                            sx={{ color: "#0D99FF" }}
                          >
                            {recommendation.fullname}
                          </Typography>
                        }
                        secondary={
                          <React.Fragment>
                            <Chip
                              label={recommendation.businesses[0]?.name}
                              sx={{
                                bgcolor: "#556EE6",
                                color: "#fff",
                                fontWeight: 600,
                              }}
                            />
                            <Chip
                              label={recommendation.role?.title?.en}
                              sx={{
                                bgcolor: recommendation.role?.color,
                                color: "#fff",
                                fontWeight: 600,
                              }}
                            />
                          </React.Fragment>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Button
                  onClick={handleClose}
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "#f2f5f9",
                    borderColor: "#f2f5f9",
                    "&:hover": {
                      backgroundColor: "#f2f5f9!important",
                    },
                  }}
                >
                  {intl.formatMessage({ id: "STANDARD.CLOSE" })}
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Button
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "primary.main",
                    borderColor: "primary.main",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                  }}
                  onClick={() => setStep(1)}
                >
                  {intl.formatMessage({ id: "CHANGE_ACCOUNT" })}
                </Button>
              </Grid>
            </Grid>
          </>
        );
    }
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        sx={{
          borderRadius: 1,
          "& .MuiDialog-container": {
            justifyContent: "center",
            alignItems: "flex-start",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#f5f5f5",
            boxShadow: "none",
            pr: 2,
          }}
        >
          <Toolbar>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              <FormattedMessage id={"CHANGE_USER"} />
            </Typography>
          </Toolbar>
        </Box>
        <DialogContent>{renderContent()}</DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ChangeUser;
