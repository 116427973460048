import React, { FC } from "react";
import { Box, Card, Typography } from "@mui/material";
import { ICase } from "app/services/case.service";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";

const CommuneInfo: FC<ICase> = (props) => {
  const intl = useIntl();
  return (
    <Card
      sx={{
        p: 2,
        borderRadius: 2,
        border: "1px solid #E6E6E6",
        height: "100%",
      }}
    >
      <Typography variant={"h5"}>
        <FormattedMessage
          id={"CASE.INFORMATION.COMMUNE.COMMUNE"}
          defaultMessage={"CASE.INFORMATION.COMMUNE.COMMUNE"}
        />
      </Typography>
      <Box
        sx={{
          p: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          width="41"
          height="40"
          viewBox="0 0 41 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M40.0315 20C40.0315 17.2856 38.8396 14.7248 36.818 13.1155C37.0517 10.4077 36.0834 7.7551 34.1636 5.83365C32.2439 3.91553 29.5979 2.94896 27.0237 3.23776C23.6616 -1.02917 16.3981 -1.1093 13.1144 3.17933C10.4034 2.93894 7.75406 3.91219 5.83428 5.83365C3.91616 7.75343 2.94792 10.4077 3.23839 12.9736C-1.02854 16.3357 -1.11034 23.5992 3.17997 26.8845C2.94625 29.5923 3.91449 32.2449 5.83428 34.1664C7.75406 36.0845 10.4034 37.0544 12.9742 36.7622C16.3363 41.0292 23.5998 41.1093 26.8835 36.8207C29.5879 37.0477 32.2422 36.0878 34.1636 34.1664C36.0818 32.2466 37.05 29.5923 36.7595 27.0264C38.8396 25.2769 40.0315 22.7161 40.0315 20.0017V20ZM29.2473 17.6061L21.3361 25.2318C19.3946 27.17 16.2178 27.1533 14.2763 25.2101L10.5169 21.7178C9.84246 21.0901 9.80239 20.0334 10.4301 19.359C11.0594 18.6829 12.1178 18.6461 12.7889 19.2722L16.5918 22.8062C17.2896 23.5024 18.3446 23.5007 18.9957 22.848L26.9286 15.2005C27.593 14.5628 28.6497 14.5812 29.2891 15.2439C29.9301 15.9067 29.9101 16.9651 29.2473 17.6044V17.6061Z"
            fill="#0D99FF"
          />
        </svg>
      </Box>
      <Box>
        <Typography variant={"body1"} sx={{ color: "#767C91" }}>
          <FormattedMessage
            id={"CASE.INFORMATION.COMMUNE.NAME"}
            defaultMessage={"CASE.INFORMATION.COMMUNE.NAME"}
          />
        </Typography>
        <Typography variant={"h6"}>{props.commune?.name}</Typography>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant={"body1"} sx={{ color: "#767C91" }}>
          <FormattedMessage
            id={"CASE.INFORMATION.COMMUNE.NOTICE_CONTRACT"}
            defaultMessage={"CASE.INFORMATION.COMMUNE.NOTICE_CONTRACT"}
          />
        </Typography>
        <Typography variant={"h6"}>
          {props.notice_commune?.title[intl.locale]}
        </Typography>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant={"body1"} sx={{ color: "#767C91" }}>
          <FormattedMessage
            id={"CASE.INFORMATION.COMMUNE.EXPIREDATE"}
            defaultMessage={"CASE.INFORMATION.COMMUNE.EXPIREDATE"}
          />
        </Typography>
        <Typography variant={"h6"}>
          {moment
            .unix(+props.expire_date_commune)
            .local()
            .format("DD-MM-YYYY")
            .toLowerCase()}
        </Typography>
      </Box>
    </Card>
  );
};

export default CommuneInfo;
