import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { Box, Divider, ClickAwayListener } from "@mui/material";
import {
  Header,
  Info,
  Actions,
  Tabs,
  MediaProps,
  FilesProps,
  LinksProps,
  VoicesProps,
  MembersProps,
} from "./index";
import { EditActions, EditHeader, EditInfo } from "./edit";

export type ChatProfileProps = {
  open: boolean;
  handleClose: () => void;
  info: { image: string; main: string; secondary: string };
  actions: {
    onProfileGo: () => void;
    onMuted: () => void;
    onDelete: () => void;
  };
  tabs: {
    media: MediaProps;
    files: FilesProps;
    links: LinksProps;
    voices: VoicesProps;
    members: MembersProps;
  };
};

const ChatProfile: FC<ChatProfileProps> = (props) => {
  const { open, handleClose, tabs, info, actions } = props;
  const [localOpen, setLocalOpen] = useState<boolean>(false);
  const [edit, setEditMode] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    /**
     * Reason for this code is that clickAwayListener cant wait for opening.
     * For example, if ChatProfile is closed, and you click somewhere, it fires onClickAway function
     * dont touch it
     */
    setTimeout(() => {
      containerRef.current?.scrollTo({ left: 0, top: 0, behavior: "auto" });
      setEditMode(false);
      setLocalOpen(open);
    }, 0);
  }, [open]);

  const scrollToTop = (top: number) => {
    containerRef.current?.scrollTo({ left: 0, top: top, behavior: "smooth" });
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (localOpen) handleClose();
      }}
    >
      <Box
        ref={containerRef}
        id={"chatProfileContainer"}
        sx={{
          display: localOpen ? "flex" : "none",
          flexDirection: "column",
          position: "absolute",
          zIndex: "99",
          width: { xs: "100%", md: "37%" },
          right: "8px",
          top: "0",
          bottom: "0",
          height: "100%",
          borderRadius: 2,
          bgcolor: "white",
          boxShadow: "-1px -1px 22px rgba(0, 0, 0, 0.12)",
          p: 1.8,
          overflowY: "scroll",
        }}
      >
        {edit ? (
          <>
            <EditHeader
              onEdit={() => {}}
              handleCancel={() => {
                setEditMode(false);
              }}
            />
            <EditInfo image={info.image} title={info.main} />
            <EditActions onDeleteClick={actions.onDelete} />
          </>
        ) : (
          <>
            <Header
              onEdit={() => {
                setEditMode(true);
              }}
              clickOnCloe={handleClose}
            />
            <Info {...info} />
            <Actions members={tabs.members} />
            <Divider
              sx={{ mx: -1.8 }}
              style={{
                borderBottomWidth: "0.5px",
              }}
            />
            <Tabs
              open={localOpen} // for tabs height
              {...tabs}
              onClick={(top) => {
                scrollToTop(top);
              }}
            />
          </>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default ChatProfile;
