import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Skeleton,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "app/widgets/uncontrolled";
import Snackbar from "app/widgets/Snackbar";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import {
  accountingService,
  businessService,
  caseService,
  userService,
} from "app/services";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "../../hooks/useForm";
import moment, { Moment } from "moment";
import { DatePicker, Space } from "antd";
import { makeStyles } from "@material-ui/core";
import { ISetting } from "../../services/setting.service";
import InvoiceItems from "./items";
import Totals from "./totals";
import { IAccounting, ITemplate } from "../../services/accounting.service";
import { IUser } from "../../interfaces";
import { IBusiness } from "../../services/business.service";
import { ICase } from "../../services/case.service";

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

interface IFormProps {
  _id: string;
  open: boolean;
  onDone: Function;
  setDialogue: Dispatch<SetStateAction<boolean>>;
}

type FormStateValues = {
  status: string;
  case: string;
  template: string;
  send_to: string;
  to_business: string | null;
  user: string;
  through: string;
  total: number;
  currency: string;
  paid_with: string;
  paid_at: string | null;
  overdue_date: string;
  description: string;
  articles: any[];
};

const InvoiceShow: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [invoice, setInvoice] = useState<IAccounting>();
  const [cases, setCases] = useState<Array<ICase>>();
  const form = useForm<FormStateValues>();
  const [users, setUsers] = useState<IUser[]>([]);
  const [businesses, setBusinesses] = useState<IBusiness[]>([]);
  const [userSearch, setUserSearch] = useState<string>("");
  const [businessSearch, setBusinessSearch] = useState<string>("");
  const [sendTo, setSendTo] = useState<string>("");
  const [currencyLabel, setCurrencyLabel] = useState<string>("$");
  const [overdueDate, setOverdueDate] = useState<Moment | undefined>(undefined);
  const [paid_date, setPaidDate] = useState<Moment | undefined>(undefined);
  const [items, setItems] = useState<any>(invoice ? invoice.items : []);
  const [fines, setFines] = useState<any>(invoice ? invoice.fines : []);
  const [data, setData] = useState<any>([]);
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [caseSearch, setCaseSearch] = useState<string>("");

  const { currencies } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const handleClose = () => {
    props.setDialogue(false);
  };

  const loadCases = () => {
    caseService
      .getAll([
        {
          name: "pagination",
          value: "0",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: cases } = data;
        setCases(cases);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    props.open && loadCases();
  }, [props.open]);

  useEffect(() => {
    accountingService
      .getOne(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }
        const { data: invoice } = data;
        setInvoice(invoice);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });

    accountingService
      .getAllTemplates()
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: templates } = data;

        setTemplates(templates);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    if (validated.through && validated.through === "iban") {
      Snackbar.info("Send invoice through IBAN function not activated yet.");
      return;
    }

    accountingService
      .update(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (invoice) {
      form.values.user = invoice.user?._id ?? "";
      form.values.status = invoice.status.value;
      form.values.template = invoice.template?._id;
      form.values.case = invoice.case?._id;
      form.values.currency = invoice.currency;
      form.values.to_business = invoice.to_business?._id ?? "";
      form.values.total = invoice.total;
      form.values.send_to = invoice.send_to;
      form.values.articles = invoice.articles;
      form.values.through = invoice.through ?? "email";
      form.values.description = invoice.description;
      form.values.overdue_date = (
        moment.unix(+invoice.overdue_date).utc().valueOf() / 1000
      ).toString();
      form.values.paid_at = invoice.paid_at
        ? (moment.unix(+invoice.paid_at).utc().valueOf() / 1000).toString()
        : null;
      form.values.paid_with = invoice.paid_with;
      setSendTo(invoice.send_to);
      setItems(invoice.items);
      setFines(invoice.fines);

      setData([...invoice.items, ...invoice.fines]);

      const invoiceCurrency = currencies?.find(
        (r: ISetting) => r.slug === invoice.currency
      )?.symbol;
      if (invoiceCurrency) {
        setCurrencyLabel(invoiceCurrency);
      }
    }
  }, [invoice]);

  const onChangeData = (items: any, type: string) => {
    if (type === "item") {
      setItems(items);
    } else {
      setFines(items);
    }
  };

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
      {
        name: "keyword",
        value: userSearch,
      },
      {
        name: "alphabetical",
        value: "true",
      },
    ];

    userService
      .getMinlist(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setUsers(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [userSearch]);

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
      {
        name: "keyword",
        value: businessSearch,
      },
      {
        name: "alphabetical",
        value: "true",
      },
    ];

    businessService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: businesses } = data;

        setBusinesses(businesses);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [businessSearch]);

  const loadOwnUsers = () => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
      {
        name: "alphabetical",
        value: "true",
      },
    ];

    userService
      .getMinlist(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setUsers(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const loadBusinesses = () => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
      {
        name: "alphabetical",
        value: "true",
      },
    ];

    businessService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: businesses } = data;

        setBusinesses(businesses);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (sendTo === "business") loadBusinesses();
    else loadOwnUsers();
  }, [sendTo]);

  useEffect(() => {
    form.values.send_to = sendTo;
  }, [sendTo]);

  useEffect(() => {
    if (overdueDate)
      form.values.overdue_date = (overdueDate.valueOf() / 1000).toString();
  }, [overdueDate]);

  useEffect(() => {
    if (paid_date)
      form.values.paid_at = (paid_date.valueOf() / 1000).toString();
  }, [paid_date]);

  useEffect(() => {
    form.values.case = caseSearch;
  }, [caseSearch]);

  const getTotal = (total: number) => {
    form.values.total = total;
  };

  useEffect(() => {
    form.values.articles = items.concat(fines);
  }, [items, fines]);

  return (
    <>
      {
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"PROJECT.SHOW"}
                  defaultMessage={"Show invoice"}
                />
              </Typography>
              <Button
                form={"invoice_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"invoice_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && invoice ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.INVOICE_TEMPLATE",
                            defaultMessage: "Invoice template",
                          })}
                          sx={{
                            backgroundColor: "#0D99FF",
                            color: "#ffffff",
                          }}
                        />
                      </Divider>
                      <Controls.Select
                        options={
                          templates &&
                          templates.map((template) => {
                            return {
                              id: template._id,
                              title: template.title ?? "",
                            };
                          })
                        }
                        formId={"status_pick"}
                        defaultValue={invoice.template?._id}
                        name={"status"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.template = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Box className={classes.input}>
                    <Divider sx={{ mb: 1 }}>
                      <Chip
                        label={intl.formatMessage({
                          id: "STANDARD.CASE",
                          defaultMessage: "Case",
                        })}
                        sx={{
                          backgroundColor: "#0D99FF",
                          color: "#ffffff",
                        }}
                      />
                    </Divider>
                    {loaded && invoice && cases ? (
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        onChange={(e, value: any, reason: string) => {
                          if (reason === "clear") {
                            setCaseSearch("");
                            return;
                          } else {
                            setCaseSearch(value?.id);
                          }
                        }}
                        options={
                          cases
                            ? cases.map((_case) => {
                                return {
                                  id: _case._id.toString(),
                                  label: `${intl.formatMessage({
                                    id: "CASE.INFORMATION.CASE",
                                  })}: ${_case.key}; Users: ${_case.users.map(
                                    (u) => u.firstname
                                  )}`,
                                };
                              })
                            : []
                        }
                        defaultValue={
                          invoice.case && {
                            id: invoice.case?._id,
                            label: `${intl.formatMessage({
                              id: "CASE.INFORMATION.CASE",
                            })}: ${
                              invoice.case?.key
                            }; Users: ${invoice.case?.users?.map(
                              (u) => u.firstname
                            )}`,
                          }
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="" />
                        )}
                      />
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          name={"title"}
                          label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && invoice && sendTo ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.SENDTO",
                            defaultMessage: "Send to",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Controls.Select
                        options={[
                          {
                            id: "business",
                            title: "Business",
                          },
                          {
                            id: "user",
                            title: "User",
                          },

                          {
                            id: "commune",
                            title: "Commune",
                          },
                        ]}
                        formId={"send_to"}
                        defaultValue={sendTo}
                        name={"send_to"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          setSendTo(event.target.value);
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                {sendTo === "business" ? (
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    {loaded && invoice ? (
                      <Box className={classes.input}>
                        <Divider sx={{ mb: 1 }}>
                          <Chip
                            label={intl.formatMessage({
                              id: "STANDARD.BUSINESS",
                              defaultMessage: "Business",
                            })}
                            sx={{
                              backgroundColor: "#0D99FF",
                              color: "#ffffff",
                            }}
                          />
                        </Divider>
                        <Controls.Select
                          options={
                            businesses
                              ? businesses.map((business) => {
                                  return {
                                    id: business._id.toString(),
                                    title: business.name,
                                  };
                                })
                              : []
                          }
                          formId={"to_business"}
                          defaultValue={invoice.to_business?._id}
                          name={"to_business"}
                          label={""}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.to_business = event.target.value;
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          name={"title"}
                          label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                ) : (
                  <></>
                )}

                {sendTo === "user" ? (
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    {loaded && invoice ? (
                      <Box className={classes.input}>
                        <Divider sx={{ mb: 1 }}>
                          <Chip
                            label={intl.formatMessage({ id: "STANDARD.USERS" })}
                            sx={{
                              backgroundColor: "#0D99FF",
                              color: "#ffffff",
                            }}
                          />
                        </Divider>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          onChange={(e, value: any, reason: string) => {
                            if (reason === "clear") {
                              setUserSearch("");
                              return;
                            } else {
                              setUserSearch(value?.id);
                              form.values.user = value?.id;
                            }
                          }}
                          defaultValue={{
                            id: invoice.user?._id,
                            label:
                              invoice.user?.firstname +
                              " " +
                              invoice.user?.lastname,
                          }}
                          options={
                            users
                              ? users.map((user) => {
                                  return {
                                    id: user._id.toString(),
                                    label: user.firstname + " " + user.lastname,
                                  };
                                })
                              : []
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="" />
                          )}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          name={"title"}
                          label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                ) : (
                  <></>
                )}

                {sendTo === "commune" ? (
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    {loaded && invoice ? (
                      <Box className={classes.input}>
                        <Divider sx={{ mb: 1 }}>
                          <Chip
                            label={intl.formatMessage({
                              id: "STANDARD.THROUGH",
                              defaultMessage: "Through",
                            })}
                            sx={{
                              backgroundColor: "#0D99FF",
                              color: "#ffffff",
                            }}
                          />
                        </Divider>
                        <Controls.Select
                          options={[
                            {
                              id: "email",
                              title: "Email",
                            },
                            {
                              id: "iban",
                              title: "IBAN",
                            },
                          ]}
                          formId={"through"}
                          defaultValue={invoice.through ?? "email"}
                          name={"through"}
                          label={""}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.through = event.target.value ?? "email";
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          name={"title"}
                          label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && invoice ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.PAYMENT_SETTING",
                            defaultMessage: "Payment setting",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Controls.Select
                        options={[]}
                        formId={"status_pick"}
                        name={"status"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.status = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && invoice ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Controls.Select
                        options={[
                          {
                            id: "created",
                            title: "Created",
                          },
                          {
                            id: "draft",
                            title: "Draft",
                          },
                          {
                            id: "paid",
                            title: "Paid",
                          },
                        ]}
                        formId={"status_pick"}
                        defaultValue={invoice.status.value}
                        name={"status"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.status = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && invoice ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.CURRENCY",
                            defaultMessage: "Currency",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Controls.Select
                        options={
                          currencies &&
                          currencies.map((currency) => {
                            return {
                              id: currency.slug,
                              title: currency.currency_title ?? "",
                            };
                          })
                        }
                        formId={"status_pick"}
                        defaultValue={invoice.currency}
                        name={"status"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          const selected = currencies?.find(
                            (r: ISetting) => r.slug === event.target.value
                          )?.symbol;
                          if (selected) {
                            setCurrencyLabel(selected);
                          }

                          form.values.currency = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && invoice ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.OVERDUEDATE",
                            defaultMessage: "Overdue date",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker
                          className={classes.datePicker}
                          value={overdueDate}
                          dropdownClassName={classes.antDropdown}
                          defaultValue={moment(
                            invoice?.overdue_date,
                            "X"
                          ).local()}
                          onChange={(value, dateString) => {
                            form.values.overdue_date = (
                              moment(dateString, "DD-MM-YYYY HH:mm").valueOf() /
                              1000
                            ).toString();
                            setOverdueDate(moment(dateString));
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            onClick={() => {
                              form.values.overdue_date = (
                                moment().local().valueOf() / 1000
                              ).toString();

                              setOverdueDate(moment().local());
                            }}
                          >
                            Today
                          </Button>
                          <Button
                            onClick={() => {
                              setOverdueDate(moment().local().add(8, "days"));
                            }}
                          >
                            + 8 day
                          </Button>
                          <Button
                            onClick={() => {
                              setOverdueDate(moment().local().add(10, "days"));
                            }}
                          >
                            + 10 day
                          </Button>
                          <Button
                            onClick={() => {
                              setOverdueDate(moment().local().add(30, "days"));
                            }}
                          >
                            + 30 day
                          </Button>
                        </Box>
                      </Space>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && invoice ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.DESCRIPTION",
                            defaultMessage: "Description",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Controls.Input
                        name={"description"}
                        label={""}
                        multiline={true}
                        defaultValue={invoice.description}
                        rows={6}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.description = event.target.value;
                        }}
                        InputProps={{
                          // @ts-ignore
                          "data-cy": "todo_description",
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && invoice ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.PAIDWITH",
                            defaultMessage: "Paid with",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Controls.Input
                        name={"paid_with"}
                        label={""}
                        defaultValue={invoice.paid_with}
                        multiline={true}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.paid_with = event.target.value;
                        }}
                        InputProps={{
                          // @ts-ignore
                          "data-cy": "todo_description",
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && invoice ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.PAIDAT",
                            defaultMessage: "Paid at",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker
                          className={classes.datePicker}
                          value={paid_date}
                          dropdownClassName={classes.antDropdown}
                          onChange={(value, dateString) => {
                            form.values.paid_at = dateString;
                            setPaidDate(moment(dateString));
                          }}
                          defaultValue={
                            invoice.paid_at
                              ? moment(invoice?.paid_at, "X").local()
                              : undefined
                          }
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            onClick={() => {
                              setPaidDate(moment().local());
                            }}
                          >
                            Today
                          </Button>
                          <Button
                            onClick={() => {
                              setPaidDate(moment().local().add(8, "days"));
                            }}
                          >
                            + 8 day
                          </Button>
                          <Button
                            onClick={() => {
                              setPaidDate(moment().local().add(10, "days"));
                            }}
                          >
                            + 10 day
                          </Button>
                          <Button
                            onClick={() => {
                              setPaidDate(moment().local().add(30, "days"));
                            }}
                          >
                            + 30 day
                          </Button>
                        </Box>
                      </Space>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && invoice && data ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.ITEMS",
                            defaultMessage: "Items",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <InvoiceItems
                        {...{
                          data: items,
                          type: "item",
                          onChangeData: onChangeData,
                          symbol: currencyLabel,
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && invoice && data ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.FINES",
                            defaultMessage: "Fines",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <InvoiceItems
                        {...{
                          data: fines,
                          type: "fine",
                          onChangeData: onChangeData,
                          symbol: currencyLabel,
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && invoice && items && fines && (
                    <Totals
                      items={items}
                      fines={fines}
                      symbol={currencyLabel}
                      total={getTotal}
                    />
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default InvoiceShow;
