import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Skeleton,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import Controls from "app/widgets/uncontrolled";
import Snackbar from "app/widgets/Snackbar";
import { userService } from "app/services";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { TransitionProps } from "@mui/material/transitions";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { AutocompletePrediction } from "app/partials/content/TimeTrack";

interface IFormProps {
  parent: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

export type FormStateValues = {
  name: string;
  firstname: string;
  lastname: string;
  birthdate: string;
  gender: string;
  email: string;
  ssn: string;
  phone: string;
  city: string;
  address: string;
  address_json: AutocompletePrediction | string;
  zipcode: string;
  password: string;
  parent: string;
  active: number;
};

const initValues = {
  name: "",
  firstname: "",
  lastname: "",
  birthdate: "",
  gender: "",
  email: "",
  ssn: "",
  phone: "",
  address: "",
  address_json: "",
  password: "",
  parent: "",
  city: "",
  active: 1,
} as FormStateValues;

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    backgroundColor: "#E0F2FF",
    boxShadow: "none",
    pr: 3,
  },
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  signature: {
    padding: "16px 0 0 16px",
    "& .kt-portlet": {
      "& .kt-portlet__head": {
        display: "none!important",
      },
      "& .kt-portlet__body": {
        border: "1px solid #E6E6E6!important",
        borderRadius: 6,
        p: 0,
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.23)",
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
}));

const SiblingStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const classes = useStyles();
  const [addressOptions, setAddressesOptions] = useState<
    Array<AutocompletePrediction>
  >([]);
  const [search, setSearch] = useState<string>("");
  const [address, setAddress] = useState<AutocompletePrediction | null>(null);

  useEffect(() => {
    if (search.length > 0) searchAddress();
  }, [search]);

  const searchAddress = () => {
    let service = new google.maps.places.AutocompleteService();

    service.getPlacePredictions(
      {
        input: search,
      },
      (res) => {
        if (res) {
          setAddressesOptions(res);
        }
      }
    );
  };

  useEffect(() => {
    if (address) {
      form.values.address = address.description;
      form.values.address_json = address;
    }
  }, [address]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    validated["parent"] = props.parent;
    userService
      .add(validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          // maxWidth={"md"}
          TransitionComponent={Transition}
          sx={{
            borderRadius: 1,
          }}
        >
          {/*test*/}
          <Box className={classes.container}>
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"USERS.ADD"}
                  defaultMessage={"USERS.ADD"}
                />
              </Typography>
              <Button
                autoFocus
                color="inherit"
                type={"submit"}
                form={"user_form"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"user_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.FIRSTNAME"}
                          defaultMessage={"USERS.FIRSTNAME"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"name"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;
                          form.values.firstname = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"lastname"}
                        label={intl.formatMessage({
                          id: "CASE.INFORMATION.COMMUNE.NAME",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.LASTNAME"}
                          defaultMessage={"USERS.LASTNAME"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"name"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;
                          form.values.lastname = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"lastname"}
                        label={intl.formatMessage({
                          id: "CASE.INFORMATION.COMMUNE.NAME",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"CASE.INFORMATION.EMAIL"}
                          defaultMessage={"CASE.INFORMATION.EMAIL"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"email"}
                        label={""}
                        type={"email"}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.email = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"email"}
                        label={intl.formatMessage({
                          id: "CASE.INFORMATION.EMAIL",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.GENDER"}
                          defaultMessage={"USERS.GENDER"}
                        />
                      </Typography>
                      <Controls.Select
                        name={"gender"}
                        formId={"gender"}
                        options={[
                          {
                            id: "male",
                            title: intl.formatMessage({ id: "USERS.MALE" }),
                          },
                          {
                            id: "female",
                            title: intl.formatMessage({ id: "USERS.FEMALE" }),
                          },
                        ]}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.gender = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"gender"}
                        options={[]}
                        label={intl.formatMessage({ id: "USERS.GENDER" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.PHONENUMBER"}
                          defaultMessage={"USERS.PHONENUMBER"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"phone"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.phone = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"phone"}
                        label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.SSN"}
                          defaultMessage={"USERS.SSN"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"ssn"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.ssn = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"ssn"}
                        label={intl.formatMessage({ id: "USERS.SSN" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.POSTNUMBER"}
                          defaultMessage={"USERS.POSTNUMBER"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"zipcode"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.zipcode = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"zipcode"}
                        label={intl.formatMessage({ id: "USERS.POSTNUMBER" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.NEW_PASSWORD"}
                          defaultMessage={"New password"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"password"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.password = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"zipcode"}
                        label={intl.formatMessage({ id: "USERS.POSTNUMBER" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"STANDARD.ADDRESS"}
                          defaultMessage={"STANDARD.ADDRESS"}
                        />
                      </Typography>
                      <Autocomplete
                        fullWidth
                        disablePortal
                        getOptionLabel={(
                          option: AutocompletePrediction | string
                        ) =>
                          typeof option === "string"
                            ? option
                            : option.description
                        }
                        filterOptions={(x) => x}
                        id="google-map"
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        options={addressOptions}
                        onChange={(
                          event: any,
                          newValue: AutocompletePrediction | null
                        ) => {
                          setAddress(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            onChange={(e) => setSearch(e.target.value)}
                            {...params}
                            value={search}
                            label={""}
                            fullWidth
                          />
                        )}
                        renderOption={(
                          props,
                          option: AutocompletePrediction
                        ) => {
                          const text = option.description;

                          return (
                            <li {...props}>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <Box
                                    component={LocationOnOutlinedIcon}
                                    sx={{ color: "text.secondary", mr: 2 }}
                                  />
                                </Grid>
                                <Grid item xs>
                                  <Typography variant="body2" color="text.main">
                                    {text}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </li>
                          );
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"place"}
                        label={intl.formatMessage({ id: "USERS.PLACE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default SiblingStore;
