import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box } from "@mui/material";
import { accountingService } from "app/services";
import { IAccounting } from "../../services/accounting.service";
import { useLocation } from "react-router-dom";
import { ReactToPrint } from "react-to-print";

interface IFormProps {
  _id: string;
  open: boolean;
  onDone: Function;
  setDialogue: Dispatch<SetStateAction<boolean>>;
}

const Invoice: FC<IFormProps> = () => {
  const [invoice, setInvoice] = useState<IAccounting>();

  let { search } = useLocation();
  let query = useMemo(() => new URLSearchParams(search), [search]);
  const hash = query.get("hash") ?? "";

  useEffect(() => {
    if (hash.length > 0) {
      accountingService
        .getPDF([
          {
            name: "hash",
            value: hash,
          },
        ])
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          const { data: invoice } = data;
          setInvoice(invoice);
        });
    }
  }, [hash]);

  const printRef = useRef<any>({});

  return invoice ? (
    <>
      <Box
        sx={{
          padding: "20px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <ReactToPrint
          bodyClass="print_content"
          content={() => printRef.current}
          trigger={() => (
            <button
              type={"button"}
              className="btn"
              style={{ backgroundColor: "#0D99FF", color: "#fff" }}
            >
              Print or get pdf
            </button>
          )}
        />
      </Box>
      <Box
        ref={printRef}
        sx={{
          "& table, td, tr, th": { border: "unset!important" },
          "& .table-bordered": { border: "unset!important" },
          "& .table-bordered td": { border: "unset!important" },
          backgroundColor: "#fff",
          // padding: "35px 0",
          display: "flex",
          justifyContent: "center",
          width: "210mm",
          height: "297mm",
          padding: "20mm",
          margin: "0 auto",
          whiteSpace: "normal",
        }}
        dangerouslySetInnerHTML={{
          __html: invoice.content.replace(
            "{INVOICE_DATE}",
            invoice._created_at
          ),
        }}
      />{" "}
    </>
  ) : (
    <></>
  );
};

export default Invoice;
