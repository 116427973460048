import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Controls from "app/widgets/uncontrolled";
import { Skeleton } from "@mui/lab";
import { noteService } from "app/services";
import { Grid, Button, Box, Avatar, Typography } from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { RootState } from "app/store/store";
import Snackbar from "app/widgets/Snackbar";
import { INote } from "app/services/note.service";
import { IRole } from "app/interfaces";
import { FormattedMessage, useIntl } from "react-intl";
import { PermissionsGate } from "app/permission/PermissionsGate";
import { useParams } from "react-router";
import UsersGroupedSelect from "../../../../partials/layout/UsersGroupedSelect";
import moment from "moment";
import { useHistory } from "react-router-dom";

interface IFormProps {
  folderId: string;
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: string;
  description: string;
  users: Array<string>;
};

const initValues = {
  title: "",
  description: "",
  users: [],
} as FormStateValues;

const ShowNote = (props: IFormProps) => {
  const { id } = useParams<{ id: string }>();
  const { folderId } = useParams<{ folderId: string }>();
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [note, setNote] = useState<INote>();
  const { roles } = useSelector((state: RootState) => state.permission);
  const [employee, setEmployee] = useState<string>();
  const [citizen, setCitizen] = useState<string>();
  const history = useHistory();

  useEffect(() => {
    if (loaded && roles.length) {
      const value = roles.find((r: IRole) => r.slug === "employee")?._id;

      if (!value) return;

      setEmployee(value);

      const _value = roles.find((r: IRole) => r.slug === "citizen")?._id;

      if (!_value) return;

      setCitizen(_value);
    }
  }, [loaded, roles]);

  useEffect(() => {
    noteService
      .getOne(folderId, id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: note } = data;
        setNote(note);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [folderId, id]);

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    noteService
      .update(folderId, id, validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        setTimeout(() => {}, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (note) {
      form.values.title = note.title;
      form.values.description = note.description;
      form.values.users = note.users.map((user) => user._id);
    }
  }, [note]);

  return (
    <PermissionsGate section={"notes"} scope={"update"}>
      <div
        style={{ padding: "20px" }}
        className="kt-portlet kt-portlet--bordered"
      >
        <form
          id={"note_form"}
          onSubmit={(e) => form.handleSubmit(e, handleSave)}
        >
          <Grid container spacing={2} mb={2}>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && note ? (
                <Controls.Input
                  name={"title"}
                  label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                  defaultValue={note?.title}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.title = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"title"}
                    label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && note ? (
                <Controls.Input
                  multiline={true}
                  rows={5}
                  name={"description"}
                  label={intl.formatMessage({ id: "STANDARD.DESCRIPTION" })}
                  defaultValue={note?.description}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.description = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    rows={5}
                    name={"description"}
                    label={intl.formatMessage({ id: "STANDARD.DESCRIPTION" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              {loaded && note ? (
                <UsersGroupedSelect
                  name={"employee"}
                  formId={"employee"}
                  _id={
                    note?.users &&
                    note?.users.find((u) => u.role.slug === "employee")?._id
                  }
                  label={intl.formatMessage({ id: "EMPLOYEE.ADD" })}
                  onChange={(value: string) => {
                    if (!value) return;

                    if (!form.values.users) {
                      form.values.users = [];
                    }
                    let employee = note?.users?.find(
                      (u) => u.role.slug === "employee"
                    )?._id;
                    form.values.users = form.values.users.filter(
                      (userId) => userId !== employee
                    );
                    form.values.users.push(value);
                  }}
                  query={[
                    {
                      name: "pagination",
                      value: "0",
                    },
                    {
                      name: "role",
                      value: employee ?? "",
                    },
                  ]}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Select
                    name={"employee"}
                    options={[]}
                    label={intl.formatMessage({ id: "EMPLOYEE.ADD" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              {loaded && note ? (
                <UsersGroupedSelect
                  name={"citizen"}
                  formId={"citizen"}
                  _id={
                    note?.users &&
                    note?.users.find((u) => u.role.slug === "citizen")?._id
                  }
                  label={intl.formatMessage({ id: "CITIZEN.ADD" })}
                  onChange={(value: string) => {
                    if (!value) return;

                    if (!form.values.users) {
                      form.values.users = [];
                    }
                    let employee = note?.users?.find(
                      (u) => u.role.slug === "citizen"
                    )?._id;
                    form.values.users = form.values.users.filter(
                      (userId) => userId !== employee
                    );
                    form.values.users.push(value);
                  }}
                  query={[
                    {
                      name: "pagination",
                      value: "0",
                    },
                    {
                      name: "role",
                      value: citizen ?? "",
                    },
                  ]}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Select
                    name={"citizen"}
                    options={[]}
                    label={intl.formatMessage({ id: "CITIZEN.ADD" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            {note?.activities && (
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                <Box className=" kt-timeline-v2-forcase">
                  <Box className="kt-timeline-v2__items-forcase">
                    {note?.activities.map((activity) => {
                      const description = intl.formatMessage({
                        id: activity.title,
                      });
                      const descriptionArray = description.split("-");
                      const title = descriptionArray[0].replace(
                        "{who}",
                        activity.replacement.title
                      );
                      return (
                        <Box className="kt-timeline-v2__item">
                          <Box className="kt-timeline-v2__item-cricle">
                            <Avatar
                              variant="circular"
                              sx={{ width: 24, height: 24 }}
                              src={activity.replacement.avatar}
                              alt={title}
                            />
                          </Box>
                          <Box className="kt-timeline-v2__item-text kt-padding-top-5">
                            <Typography variant={"body1"}>
                              <b style={{ color: "#556EE6" }}>
                                <a
                                  onClick={() => {
                                    history.push(
                                      `/users/${activity.replacement.id}`
                                    );
                                  }}
                                >
                                  {title}
                                </a>
                              </b>
                              {" - "} {descriptionArray[1]}
                            </Typography>
                            <Typography>
                              {moment
                                .unix(activity.created_at)
                                .local()
                                .format("DD-MM-YYYY HH:mm")}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
          <Button
            sx={{ mt: "20px" }}
            variant="contained"
            color="primary"
            data-cy-class={"note_update"}
            data-cy-note-id={note?._id}
            data-cy-case-id={props._id}
            type={"submit"}
            form={"note_form"}
          >
            <FormattedMessage
              id={"DIALOGUE.UPDATE"}
              defaultMessage={"DIALOGUE.UPDATE"}
            />
          </Button>
        </form>
      </div>
    </PermissionsGate>
  );
};

export default ShowNote;
