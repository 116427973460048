import React, { useEffect, useState, FC, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  Typography,
  Box,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Chip,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Stack,
  Button,
} from "@mui/material";
import { RootState } from "app/store/store";
import { TransitionProps } from "@mui/material/transitions";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import Snackbar from "app/widgets/Snackbar";
import { IRole, IUser } from "app/interfaces";
import { userService } from "app/services";
import { useParams } from "react-router";
import { stringAvatar } from "../widgets/AvatarBackground";
import { IFile } from "../services/file.service";
import UserAvatar from "./layout/UserAvatar";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

type Props = {
  open: boolean;
  handleClose: () => void;
  handleSave: Function;
  onUserChange: (users: Array<SelectedUsers>) => void;
  CloseItem?: JSX.Element;
  Title: JSX.Element;
  users?: Array<SelectedUsers>;
};

export interface SelectedUsers {
  _id: string;
  firstname: string;
  lastname: string;
  role: IRole;
  file?: IFile;
}

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-input": {
          padding: "10px!important",
          zIndex: 999,
        },
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 38,
          backgroundColor: "#E0F2FF",
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
}));

const SelectUsers: FC<Props> = (props) => {
  const { handleSave, open, handleClose, onUserChange, Title, CloseItem } =
    props;
  const classes = useStyles();
  const intl = useIntl();
  const params = useParams<{ id: string }>();
  const { user: me } = useSelector(({ auth }: RootState) => auth);
  const { roles } = useSelector((state: RootState) => state.permission);
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [role, setRole] = useState<string>();
  const [selectedUsers, selectUsers] = useState<Array<SelectedUsers>>([]);
  const [search, setSearch] = useState<string>("");

  const [user, setUser] = useState<IUser | null>();

  const [tab, setTab] = useState<string>("0");

  const timeOut = useRef<any>();

  const sendQuery = (value: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      setSearch(value);
    }, 500);
  };

  useEffect(() => {
    if (selectedUsers) onUserChange(selectedUsers);
  }, [selectedUsers]);

  useEffect(() => {
    if (props.users && open) {
      selectUsers(props.users);
    }
  }, [open]);

  useEffect(() => {
    if (params.id) {
      let url = window.location.pathname.split("/");

      if (url[url.length - 2] === "users") {
        userService
          .getOne(params.id)
          .then((response) => {
            if ("error" in response) {
              throw new Error(response.error.message);
            }

            let { data: _user } = response;
            setUser(_user);
          })
          .catch((e) => {
            Snackbar.error(e.message);
          });
      }
    }
  }, []);

  useEffect(() => {
    if (user) {
      selectUsers([
        ...(selectedUsers ?? []),
        {
          firstname: user.firstname,
          lastname: user.lastname,
          role: user.role,
          file: user.file ?? undefined,
          _id: user._id,
        },
      ]);
    }
  }, [user]);

  useEffect(() => {
    if (!role) return;

    userService
      .getMinlist([
        {
          name: "pagination",
          value: "0",
        },
        {
          name: "role",
          value: role,
        },
        {
          name: "keyword",
          value: search,
        },
        {
          name: "skip",
          value: 0,
        },
        {
          name: "limit",
          value: 10,
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;
        setUsers(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [role, search]);

  const close = () => {
    selectUsers(selectedUsers);
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        fullWidth={true}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        sx={{
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#E0F2FF",
            boxShadow: "none",
          }}
        >
          <Toolbar sx={{ minHeight: "48px!important" }}>
            {Title}
            {CloseItem ?? (
              <Button
                form={"select_user_form"}
                autoFocus
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
                onClick={() => {
                  // getUsers();
                  handleClose();
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            )}
          </Toolbar>
        </Box>
        <DialogContent sx={{ p: "16px!important" }}>
          <Box className={classes.input}>
            <TextField
              placeholder={"Search..."}
              fullWidth={true}
              onChange={(event) => {
                sendQuery(event.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    sx={{ zIndex: "999!important" }}
                    position="start"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.4219 13.2473L11.8413 10.6655C13.7722 8.08511 13.2457 4.42788 10.6653 2.49693C8.08486 0.565972 4.42764 1.09248 2.49668 3.67291C0.565728 6.25334 1.09224 9.91056 3.67267 11.8415C5.74548 13.3926 8.59245 13.3926 10.6653 11.8415L13.247 14.4232C13.5715 14.7477 14.0975 14.7477 14.4219 14.4232C14.7463 14.0988 14.7463 13.5728 14.4219 13.2484L14.4219 13.2473ZM7.19049 11.3432C4.89713 11.3432 3.03801 9.4841 3.03801 7.19074C3.03801 4.89737 4.89713 3.03826 7.19049 3.03826C9.48386 3.03826 11.343 4.89737 11.343 7.19074C11.3405 9.48306 9.48285 11.3408 7.19049 11.3432Z"
                        fill="#767C91"
                      />
                    </svg>
                  </InputAdornment>
                ),
                // @ts-ignore
                "data-cy": "search",
              }}
            />
          </Box>

          <Tabs
            value={tab}
            onChange={(event, tab) => setTab(tab)}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
            sx={{
              "& .MuiTabScrollButton-horizontal": {
                display: "none",
              },
              "& .MuiButtonBase-root": {
                mt: 0.5,
                p: 0.5,
              },
            }}
          >
            {[...roles].reverse().map((_role) => {
              if (
                (me?.role.slug === "social-worker" ||
                  me?.role.slug === "citizen") &&
                (_role.slug === "super-admin" || _role.slug === "moderator")
              ) {
                return;
              }
              return (
                <Tab
                  disableRipple={true}
                  onClick={() => {
                    setRole(_role._id);
                  }}
                  label={
                    <Chip
                      sx={{
                        width: "100%!important",
                        backgroundColor: role === _role._id ? "#0D99FF" : "",
                        border: "1px solid #0D99FF",
                        color: role === _role._id ? "#ffffff" : "#0D99FF",
                      }}
                      label={`${_role.title[intl.locale]}`}
                      variant="outlined"
                    />
                  }
                />
              );
            })}
          </Tabs>

          <Stack direction="row" spacing={1}>
            {selectedUsers
              ? selectedUsers.map((user) => {
                  return (
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ position: "relative" }}
                    >
                      <UserAvatar user={user} />
                      <IconButton
                        onClick={() => {
                          if (params.id === user._id) {
                            Snackbar.warning(
                              "Opps, you can't delete this user from case"
                            );
                          } else {
                            selectUsers(
                              selectedUsers?.filter((u) => {
                                return u._id !== user._id;
                              })
                            );
                          }
                        }}
                        sx={{
                          position: "absolute",
                          top: "-5px",
                          right: "-5px",
                          padding: "5px",
                          backgroundColor: "#EC3033",
                          "&:hover": { backgroundColor: "#EC3033" },
                        }}
                      >
                        <CloseOutlinedIcon
                          sx={{ color: "#fff", width: 10, height: 10 }}
                        />
                      </IconButton>
                    </Stack>
                  );
                })
              : ""}
          </Stack>

          <Box>
            <List
              sx={{
                width: "100%",
                paddingRight: "0px!important",
              }}
            >
              {users.map((user) => {
                if (role && user.role._id === role) {
                  return (
                    <ListItem
                      sx={{ mt: 1 }}
                      secondaryAction={
                        selectedUsers?.find((u) => u._id === user._id) ? (
                          <IconButton
                            onClick={() => {
                              selectUsers(
                                selectedUsers?.filter((u) => {
                                  return u._id !== user._id;
                                })
                              );
                            }}
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.5907 12L20.6704 4.92103C21.1099 4.48156 21.1099 3.76904 20.6704 3.3296C20.2309 2.89013 19.5184 2.89013 19.079 3.3296L12 10.4093L4.92103 3.3296C4.48156 2.89013 3.76904 2.89013 3.3296 3.3296C2.89017 3.76907 2.89013 4.48159 3.3296 4.92103L10.4093 12L3.3296 19.079C2.89013 19.5184 2.89013 20.231 3.3296 20.6704C3.76907 21.1098 4.48159 21.1099 4.92103 20.6704L12 13.5907L19.0789 20.6704C19.5184 21.1099 20.2309 21.1099 20.6704 20.6704C21.1098 20.2309 21.1098 19.5184 20.6704 19.079L13.5907 12Z"
                                fill="#EC3033"
                              />
                            </svg>
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => {
                              // add
                              if (
                                !selectedUsers?.find((s) => s._id === user._id)
                              ) {
                                selectUsers([
                                  ...(selectedUsers ?? []),
                                  {
                                    firstname: user.firstname,
                                    lastname: user.lastname,
                                    role: user.role,
                                    file: user.file ?? undefined,
                                    _id: user._id,
                                  },
                                ]);
                              }
                            }}
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20.75 10.75H13.25V3.25C13.25 2.55965 12.6904 2 12 2C11.3096 2 10.75 2.55965 10.75 3.25V10.75H3.25C2.55965 10.75 2 11.3096 2 12C2 12.6904 2.55965 13.25 3.25 13.25H10.75V20.75C10.75 21.4404 11.3096 22 12 22C12.6904 22 13.25 21.4404 13.25 20.75V13.25H20.75C21.4404 13.25 22 12.6904 22 12C22 11.3096 21.4404 10.75 20.75 10.75Z"
                                fill="#0D99FF"
                              />
                            </svg>
                          </IconButton>
                        )
                      }
                      disablePadding
                    >
                      <ListItemButton sx={{ p: "0px!important" }}>
                        <ListItemAvatar>
                          <UserAvatar user={user} />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ ml: 0.5 }}
                          primary={
                            <React.Fragment>
                              <Typography
                                variant={"body1"}
                                sx={{ color: "#0D99ff" }}
                              >
                                {user.firstname} {user.lastname}{" "}
                                {user.commune ? " - " + user.commune.name : ""}
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={<>{user.role.title[intl.locale]}</>}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                }
              })}
            </List>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default SelectUsers;
