import React, { useEffect, useState } from "react";
import { Modal, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { requestForToken } from "firebase";
import { getAccessToken, getBusiness } from "app/lib/http";
import { getRoute } from "app/config/apiRoutes";
import { RootState } from "../../store/store";
import { useIntl } from "react-intl";

export default function NotificationModal() {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const { user } = useSelector(({ auth }: RootState) => auth);

  useEffect(() => {
    if (!user) return;

    const permission = localStorage.getItem("notificationPermission");
    if (!permission || permission === "default") {
      setOpen(true);
    }
  }, [user]);

  const handleAllowNotifications = async () => {
    try {
      const permission = await Notification.requestPermission();
      localStorage.setItem("notificationPermission", permission || "error");
      setOpen(false);

      if (permission === "granted") {
        const token = await requestForToken();
        if (token) {
          await fetch(getRoute("set-device"), {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getAccessToken()}`,
              Business: getBusiness(),
            },
            body: JSON.stringify({ token }),
          });
        }
      }
    } catch (error) {
      console.error("❌ Error handling notification permission:", error);
      localStorage.setItem("notificationPermission", "error");
      setOpen(false);
    }
  };

  const handleDecline = () => {
    localStorage.setItem("notificationPermission", "denied");
    setOpen(false);
  };

  if (!user) return null;

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
          textAlign: "center",
        }}
      >
        <h2>
          {intl.formatMessage({
            id: "AccessForNotification",
            defaultMessage: "Give access for notifications",
          })}
        </h2>
        <p>
          {intl.formatMessage({
            id: "AccessInfo",
            defaultMessage:
              "Allow notifications to bring you important updates",
          })}
        </p>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAllowNotifications}
        >
          {intl.formatMessage({
            id: "AllowNotification",
            defaultMessage: "Allow",
          })}
        </Button>
        <Button
          variant="outlined"
          onClick={handleDecline}
          style={{ marginLeft: "10px" }}
        >
          {intl.formatMessage({
            id: "DeclineNotification",
            defaultMessage: "Decline",
          })}
        </Button>
      </div>
    </Modal>
  );
}
