import React, { FC, useEffect, useState } from "react";
import { ISecureUser, IUser } from "../interfaces";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
  Skeleton,
  Dialog,
  Toolbar,
  DialogContent,
  Slide,
  Tooltip,
  Button,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { FormattedMessage, useIntl } from "react-intl";
import { TransitionProps } from "@mui/material/transitions";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import Uploader from "app/pages/Users/uploader";
import { userService } from "../services";
import Snackbar from "./Snackbar";
import { IFile } from "../services/file.service";
import * as auth from "../store/ducks/auth.duck";
import { stringAvatar } from "./AvatarBackground";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "space-around",
    justifyContent: "space-evenly",
    marginLeft: "20px",
    minWidth: "30%",
    // marginTop: '10px'
  },
  avatarPopUp: {
    "& .MuiAvatar-img": {
      objectFit: "contain",
    },
  },
  text: {
    alignItems: "space-around",
    marginBottom: "10px",
  },
  iconText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "#b5b5c3",
    "& .MuiTypography-subtitle2": {
      marginLeft: "2px",
    },
  },
  ml5: {
    marginLeft: "5px!important",
  },
  p15: {
    padding: "15px",
  },
  bordered: {
    border: "1px dashed #b5b5c3",
  },
}));
const options = ["year", "month", "week"];

const ITEM_HEIGHT = 48;

const ProfileUserCard: FC<{
  user: IUser | undefined;
  onChangeFilter: Function;
}> = (props) => {
  const {
    firstname,
    lastname,
    role,
    file,
    _id,
    address,
    email,
    time,
    budget,
    total_cases,
  } = props.user ?? ({} as IUser);
  const isLoaded = Boolean(props.user);
  const [showPhoto, setShowPhoto] = useState<boolean>(false);
  const [granted, setGranted] = useState<boolean>(true);
  const [_file, setFile] = useState<IFile | null>(null);
  const dispatch = useDispatch();
  const { user: me } = useSelector(({ auth }: RootState) => auth);
  const intl = useIntl();
  const showPhotoClick = () => {
    setShowPhoto(true);
  };

  const handleShowPhotoClose = () => {
    setShowPhoto(false);
  };

  const getUsedTime = (_time: number) => {
    let h = Math.floor(_time / 3600); // Calculate hours (divide by 3600 seconds per hour)
    let m = Math.floor((_time % 3600) / 60); // Calculate minutes (remaining seconds divided by 60)

    return _time ? `${h}h ${m}m` : "0"; // Return formatted time
  };

  useEffect(() => {
    if (_file) setShowPhoto(true);
  }, [_file]);

  useEffect(() => {
    if (
      me?.role.slug === "citizen" ||
      (me?.role.slug === "social-worker" && me?._id != _id)
    )
      setGranted(false);
  }, []);

  const uploadPhoto = (file: string) => {
    userService.uploadPhoto(_id, file).then((data) => {
      if ("error" in data) {
        throw new Error(data.error.message);
      }

      Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPLOADED" }));

      if (me?._id === _id) dispatch(auth.actions.fulfillUser(data.data));

      setShowPhoto(false);
    });
  };

  const { online_users } = useSelector((state: RootState) => state.user);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [typeAnchorEl, setTypeAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const typeOpen = Boolean(typeAnchorEl);
  const [filter, setFilter] = useState<string>("year");
  const [typeFilter, setTypeFilter] = useState<string>("case");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (key: string) => {
    setFilter(key);
    setAnchorEl(null);
  };

  const handleTypeClick = (event: React.MouseEvent<HTMLElement>) => {
    setTypeAnchorEl(event.currentTarget);
  };

  const handleTypeClose = (key: string) => {
    setTypeFilter(key);
    setTypeAnchorEl(null);
  };

  useEffect(() => {
    props.onChangeFilter(filter ?? "", typeFilter ?? "");
  }, [filter, typeFilter]);

  return (
    <>
      <Box>
        <Card
          variant={"elevation"}
          elevation={0}
          sx={{
            boxShadow: "unset!important",
            borderRadius: 0,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <CardContent
            sx={{
              pt: 6,
              pb: "36px!important",
              pl: 3,
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={3.5}
                xl={3.5}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Box className={classes.container}>
                  <Box>
                    {isLoaded ? (
                      <>
                        <Badge
                          color={
                            online_users.some((e: ISecureUser) => e._id === _id)
                              ? "secondary"
                              : "primary"
                          }
                          overlap="circular"
                          sx={{
                            "& .MuiBadge-badge": online_users.some(
                              (e: ISecureUser) => e._id === _id
                            )
                              ? {
                                  backgroundColor: "#44b700",
                                  color: "#44b700",
                                  // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                                  "&::after": {
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "50%",
                                    animation:
                                      "ripple 1.2s infinite ease-in-out",
                                    border: "1px solid currentColor",
                                    content: '""',
                                  },
                                }
                              : {},
                            "& .MuiBadge-dot": {
                              bottom: "11%",
                              right: "19%",
                              border: "2px solid #fff",
                              borderRadius: "50%",
                              width: 20,
                              height: 20,
                            },
                            "@keyframes ripple": {
                              "0%": {
                                transform: "scale(.8)",
                                opacity: 1,
                              },
                              "100%": {
                                transform: "scale(2.4)",
                                opacity: 0,
                              },
                            },
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant="dot"
                        >
                          <Uploader
                            afterUpload={(file: IFile) => {
                              // uploadPhoto(file_id);
                              setFile(file);
                            }}
                            avatar={file ?? undefined}
                            accept={".png,.jpg,.jpeg,"}
                          />
                        </Badge>

                        <Dialog
                          open={showPhoto}
                          onClose={() => handleShowPhotoClose()}
                          fullWidth={true}
                          maxWidth={"sm"}
                          TransitionComponent={Transition}
                        >
                          <Box
                            className={classes.container}
                            sx={{ justifyContent: "end" }}
                          >
                            <Toolbar>
                              <Button
                                autoFocus
                                color="inherit"
                                sx={{
                                  backgroundColor: "#0D99FF",
                                  borderRadius: 2,
                                  color: "#ffffff",
                                }}
                                onClick={() => {
                                  _file && uploadPhoto(_file._id);
                                }}
                              >
                                <FormattedMessage
                                  id={"DIALOGUE.SAVE"}
                                  defaultMessage={"DIALOGUE.SAVE"}
                                />
                              </Button>
                            </Toolbar>
                          </Box>
                          <DialogContent>
                            <Avatar
                              style={{
                                backgroundColor: role?.color ?? "#0D99FF",
                              }}
                              src={_file?.base64}
                              alt={firstname}
                              sx={{
                                width: "100%",
                                height: "50vh",
                                fontSize: 80,
                              }}
                              className={classes.avatarPopUp}
                              onClick={() => {
                                showPhotoClick();
                              }}
                              variant="square"
                            />
                          </DialogContent>
                        </Dialog>
                      </>
                    ) : (
                      <Skeleton sx={{ transform: "none" }}>
                        <Avatar
                          sx={{
                            width: 160,
                            height: 160,
                          }}
                        />
                      </Skeleton>
                    )}
                  </Box>
                  <Box className={classes.box}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {isLoaded ? (
                        <>
                          <Typography
                            variant={"h4"}
                            fontSize={18}
                            fontWeight={500}
                            sx={{
                              fontWeight: "500",
                              display: "inline-block",
                            }}
                            gutterBottom={false}
                          >
                            {firstname} {lastname}
                          </Typography>
                        </>
                      ) : (
                        <Skeleton width={"100%"} />
                      )}
                    </Box>
                    <Box className={classes.iconText}>
                      {isLoaded ? (
                        <>
                          <Box sx={{ marginRight: 1.5 }}>
                            <svg
                              width="24"
                              height="25"
                              viewBox="0 0 24 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.3572 12.5C15.066 12.5 17.2619 10.2614 17.2619 7.5C17.2619 4.73858 15.066 2.5 12.3572 2.5C9.64833 2.5 7.45239 4.73858 7.45239 7.5C7.45239 10.2614 9.64833 12.5 12.3572 12.5Z"
                                fill="#9FA5BB"
                              />
                              <path
                                d="M12.3571 14.1667C8.29577 14.1714 5.00452 17.5265 5 21.6668C5 22.127 5.36598 22.5001 5.81745 22.5001H18.8968C19.3483 22.5001 19.7142 22.127 19.7142 21.6668C19.7098 17.5265 16.4185 14.1713 12.3571 14.1667Z"
                                fill="#9FA5BB"
                              />
                            </svg>
                          </Box>
                          <Typography
                            className={classes.ml5}
                            variant={"subtitle1"}
                            fontSize={13}
                            fontWeight={400}
                          >
                            {role?.title[intl.locale]}
                          </Typography>
                        </>
                      ) : (
                        <Skeleton width={"100%"} />
                      )}
                    </Box>
                    <Box className={classes.iconText}>
                      {isLoaded ? (
                        <>
                          <Box sx={{ marginRight: 1.5 }}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.3175 2C10.1124 2.00243 7.99839 2.87939 6.43911 4.43852C4.87983 5.99765 4.00265 8.11161 4 10.3167C4 12.4583 5.65833 15.81 8.92916 20.2783C9.31854 20.8117 9.82837 21.2457 10.4172 21.5449C11.0059 21.8441 11.657 22 12.3175 22C12.9779 22 13.629 21.8441 14.2178 21.5449C14.8066 21.2457 15.3164 20.8117 15.7058 20.2783C18.9766 15.81 20.635 12.4583 20.635 10.3167C20.6323 8.11161 19.7551 5.99765 18.1959 4.43852C16.6366 2.87939 14.5225 2.00243 12.3175 2ZM12.3175 13.6316C11.6582 13.6316 11.0138 13.4362 10.4656 13.0699C9.91743 12.7036 9.49018 12.183 9.23789 11.5739C8.9856 10.9648 8.91959 10.2946 9.04821 9.64802C9.17682 9.00142 9.49429 8.40748 9.96047 7.9413C10.4266 7.47513 11.0206 7.15766 11.6672 7.02904C12.3138 6.90042 12.984 6.96643 13.5931 7.21873C14.2022 7.47102 14.7228 7.89826 15.089 8.44642C15.4553 8.99459 15.6508 9.63905 15.6508 10.2983C15.6508 11.1824 15.2996 12.0302 14.6745 12.6553C14.0494 13.2805 13.2015 13.6316 12.3175 13.6316Z"
                                fill="#9FA5BB"
                              />
                            </svg>
                          </Box>
                          {address && granted && (
                            <Tooltip title={address}>
                              <Typography
                                className={classes.ml5}
                                variant={"subtitle1"}
                                fontSize={13}
                                fontWeight={400}
                              >
                                {address.substring(0, 16) + "..."}
                              </Typography>
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        <Skeleton width={"100%"} />
                      )}
                    </Box>
                  </Box>
                </Box>
                <Divider orientation="vertical" flexItem />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={2.5}
                xl={2.5}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Box
                  className={`${classes.container}`}
                  sx={{ height: 120, marginLeft: 3 }}
                >
                  <Box
                    className={classes.box}
                    sx={{ marginLeft: "unset!important" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {isLoaded ? (
                        <>
                          <Typography
                            variant={"inherit"}
                            fontSize={16}
                            fontWeight={400}
                            sx={{
                              display: "inline-block",
                            }}
                            gutterBottom={false}
                          >
                            {intl.formatMessage({ id: "AUTH.GENERAL.CONTACT" })}
                          </Typography>
                        </>
                      ) : (
                        <Skeleton width={"100%"} />
                      )}
                    </Box>
                    <Box className={classes.iconText}>
                      {isLoaded ? (
                        <>
                          <Box sx={{ marginRight: 1.5 }}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.9617 6.78516L14.9467 13.8002C14.1645 14.5804 13.1048 15.0185 12 15.0185C10.8952 15.0185 9.83552 14.5804 9.05333 13.8002L2.03833 6.78516C2.02667 6.91682 2 7.03599 2 7.16682V17.1668C2.00132 18.2715 2.44073 19.3305 3.22185 20.1116C4.00296 20.8928 5.062 21.3322 6.16667 21.3335H17.8333C18.938 21.3322 19.997 20.8928 20.7782 20.1116C21.5593 19.3305 21.9987 18.2715 22 17.1668V7.16682C22 7.03599 21.9733 6.91682 21.9617 6.78516Z"
                                fill="#9FA5BB"
                              />
                              <path
                                d="M13.7683 12.6217L21.38 5.00917C21.0113 4.39777 20.4912 3.8917 19.87 3.53978C19.2487 3.18785 18.5473 3.00195 17.8333 3H6.16666C5.45268 3.00195 4.75125 3.18785 4.13002 3.53978C3.50879 3.8917 2.98873 4.39777 2.62 5.00917L10.2317 12.6217C10.7013 13.0894 11.3371 13.3521 12 13.3521C12.6628 13.3521 13.2987 13.0894 13.7683 12.6217Z"
                                fill="#9FA5BB"
                              />
                            </svg>
                          </Box>
                          <Tooltip title={email}>
                            <Typography
                              className={classes.ml5}
                              variant={"subtitle1"}
                              fontSize={13}
                              fontWeight={400}
                            >
                              {email.length > 20
                                ? email.substring(0, 17) + ".."
                                : email}
                            </Typography>
                          </Tooltip>
                        </>
                      ) : (
                        <Skeleton width={"100%"} />
                      )}
                    </Box>
                    <Box className={classes.iconText}>
                      {isLoaded ? (
                        <>
                          <Box sx={{ marginRight: 1.5 }}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.1334 11.1661C20.9125 11.1661 20.7006 11.0784 20.5443 10.9221C20.3881 10.7659 20.3003 10.5539 20.3003 10.333C20.2985 8.5658 19.5957 6.8715 18.3461 5.62191C17.0966 4.37233 15.4023 3.66953 13.6351 3.66777C13.4141 3.66777 13.2022 3.57999 13.046 3.42375C12.8897 3.2675 12.8019 3.05558 12.8019 2.83462C12.8019 2.61365 12.8897 2.40174 13.046 2.24549C13.2022 2.08924 13.4141 2.00147 13.6351 2.00147C15.844 2.00389 17.9617 2.88245 19.5237 4.44439C21.0856 6.00633 21.9642 8.12407 21.9666 10.333C21.9666 10.5539 21.8788 10.7659 21.7226 10.9221C21.5663 11.0784 21.3544 11.1661 21.1334 11.1661ZM18.634 10.333C18.634 9.00719 18.1073 7.7357 17.1698 6.79822C16.2324 5.86074 14.9609 5.33407 13.6351 5.33407C13.4141 5.33407 13.2022 5.42185 13.046 5.5781C12.8897 5.73434 12.8019 5.94626 12.8019 6.16722C12.8019 6.38819 12.8897 6.60011 13.046 6.75635C13.2022 6.9126 13.4141 7.00038 13.6351 7.00038C14.5189 7.00038 15.3666 7.35149 15.9916 7.97647C16.6166 8.60146 16.9677 9.44912 16.9677 10.333C16.9677 10.5539 17.0555 10.7659 17.2117 10.9221C17.368 11.0784 17.5799 11.1661 17.8008 11.1661C18.0218 11.1661 18.2337 11.0784 18.39 10.9221C18.5462 10.7659 18.634 10.5539 18.634 10.333ZM20.4528 20.4674L21.2109 19.5935C21.6935 19.1093 21.9645 18.4537 21.9645 17.7701C21.9645 17.0866 21.6935 16.4309 21.2109 15.9468C21.1851 15.9209 19.1805 14.3788 19.1805 14.3788C18.6994 13.9208 18.0604 13.6658 17.3962 13.6667C16.732 13.6677 16.0936 13.9245 15.6138 14.3838L14.0258 15.7218C12.7296 15.1853 11.5521 14.3981 10.561 13.4052C9.56987 12.4124 8.78465 11.2336 8.25042 9.9364L9.58346 8.35341C10.0431 7.87366 10.3002 7.23522 10.3013 6.57081C10.3024 5.90639 10.0474 5.26711 9.58929 4.78586C9.58929 4.78586 8.04546 2.7838 8.01964 2.75797C7.54428 2.27953 6.89978 2.00732 6.22538 2.00015C5.55099 1.99297 4.90084 2.25142 4.41542 2.71964L3.4573 3.55279C-2.20313 10.1197 9.98587 22.2146 16.7694 21.9971C17.4544 22.0011 18.1333 21.8678 18.7659 21.605C19.3985 21.3423 19.9721 20.9555 20.4528 20.4674Z"
                                fill="#9FA5BB"
                              />
                            </svg>
                          </Box>
                          <Typography
                            className={classes.ml5}
                            variant={"subtitle1"}
                            fontSize={13}
                            fontWeight={400}
                          >
                            {props.user?.phone}
                          </Typography>
                        </>
                      ) : (
                        <Skeleton width={"100%"} />
                      )}
                    </Box>
                  </Box>
                </Box>
                <Divider orientation="vertical" flexItem />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                sx={{ pt: 1.5, pr: 3, pb: 1.5, pl: 3 }}
              >
                {(me?.role?.slug === "super-admin" ||
                  me?.role?.slug === "moderator" ||
                  me?._id === props.user?._id) && (
                  <>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        {isLoaded ? (
                          <>
                            <Typography
                              variant={"inherit"}
                              fontSize={16}
                              fontWeight={400}
                              sx={{
                                display: "inline-block",
                              }}
                              gutterBottom={false}
                            >
                              {intl.formatMessage({ id: "CASE.EFFICIENCY" })}
                            </Typography>
                          </>
                        ) : (
                          <Skeleton width={"100%"} />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "20px",
                        }}
                      >
                        <>
                          <IconButton
                            id="long-button"
                            aria-controls={typeOpen ? "long-menu" : undefined}
                            aria-expanded={typeOpen ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleTypeClick}
                            edge="start"
                            color="inherit"
                            disableFocusRipple={true}
                            sx={{
                              borderRadius: 0,
                              padding: "0 8px 8px 8px",
                              "&.MuiButtonBase-root:hover": {
                                backgroundColor: "transparent",
                              },
                            }}
                            aria-label="close"
                          >
                            <Typography
                              variant={"inherit"}
                              sx={{
                                fontSize: "13px",
                                textTransform: "capitalize",
                              }}
                            >
                              {typeFilter}
                            </Typography>
                            <ExpandMoreIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={typeAnchorEl}
                            open={typeOpen}
                            onClose={() => handleTypeClose(typeFilter)}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "20ch",
                              },
                            }}
                          >
                            <MenuItem
                              key={"case"}
                              selected={"case" === typeFilter}
                              sx={{
                                textTransform: "capitalize",
                              }}
                              onClick={() => {
                                handleTypeClose("case");
                              }}
                            >
                              {intl.formatMessage({
                                id: "STANDARD.CASE",
                              })}
                            </MenuItem>
                            <MenuItem
                              key={"user"}
                              selected={"user" === typeFilter}
                              sx={{
                                textTransform: "capitalize",
                              }}
                              onClick={() => {
                                handleTypeClose("user");
                              }}
                            >
                              {intl.formatMessage({
                                id: "STANDARD.USER",
                              })}
                            </MenuItem>
                          </Menu>
                        </>
                        <>
                          <IconButton
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            edge="start"
                            color="inherit"
                            disableFocusRipple={true}
                            sx={{
                              borderRadius: 0,
                              padding: "0 8px 8px 8px",
                              "&.MuiButtonBase-root:hover": {
                                backgroundColor: "transparent",
                              },
                            }}
                            aria-label="close"
                          >
                            <Typography
                              variant={"inherit"}
                              sx={{
                                fontSize: "13px",
                                textTransform: "capitalize",
                              }}
                            >
                              {filter}
                            </Typography>
                            <ExpandMoreIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={() => handleClose(filter)}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "20ch",
                              },
                            }}
                          >
                            {options.map((option) => (
                              <MenuItem
                                key={option}
                                selected={option === filter}
                                sx={{
                                  textTransform: "capitalize",
                                }}
                                onClick={() => {
                                  handleClose(option);
                                }}
                              >
                                {intl.formatMessage({
                                  id: "STANDARD." + option.toUpperCase(),
                                })}
                              </MenuItem>
                            ))}
                          </Menu>
                        </>
                      </Grid>
                    </Grid>
                    <Grid container>
                      {typeFilter == "case" ? (
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Grid container>
                            <Grid
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              item
                              sx={{ pt: 1.5 }}
                            >
                              {isLoaded ? (
                                <>
                                  <Box sx={{ display: "flex" }}>
                                    <Box
                                      sx={{
                                        pr: 1.5,
                                      }}
                                    >
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M17.8333 5.33333H16.9158C16.5283 3.43417 14.845 2 12.8333 2H11.1667C9.155 2 7.47083 3.43417 7.08417 5.33333H6.16667C3.86917 5.33333 2 7.2025 2 9.5V17.8333C2 20.1308 3.86917 22 6.16667 22H7V9.5C7 9.03917 7.37333 8.66667 7.83333 8.66667C8.29333 8.66667 8.66667 9.03917 8.66667 9.5V22H15.3333V9.5C15.3333 9.03917 15.7058 8.66667 16.1667 8.66667C16.6275 8.66667 17 9.03917 17 9.5V22H17.8333C20.1308 22 22 20.1308 22 17.8333V9.5C22 7.2025 20.1308 5.33333 17.8333 5.33333ZM8.81 5.33333C9.15417 4.36333 10.08 3.66667 11.1667 3.66667H12.8333C13.92 3.66667 14.8458 4.36333 15.19 5.33333H8.81Z"
                                          fill="#0D99FF"
                                        />
                                      </svg>
                                    </Box>
                                    <Typography
                                      variant={"inherit"}
                                      fontSize={16}
                                      fontWeight={400}
                                      sx={{
                                        display: "inline-block",
                                      }}
                                      gutterBottom={false}
                                    >
                                      {intl.formatMessage({
                                        id: "HOME.TOTALCASES",
                                      })}
                                    </Typography>
                                  </Box>
                                </>
                              ) : (
                                <Skeleton width={"100%"} />
                              )}
                            </Grid>
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Box sx={{ pt: 1.5, pl: 4.5 }}>
                                <Typography
                                  variant={"inherit"}
                                  fontSize={18}
                                  fontWeight={600}
                                  sx={{
                                    display: "inline-block",
                                  }}
                                  gutterBottom={false}
                                >
                                  {total_cases ?? 0}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )}
                      {me?.role.slug !== "social-worker" && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={typeFilter == "case" ? 4 : 6}
                            lg={typeFilter == "case" ? 4 : 6}
                            xl={typeFilter == "case" ? 4 : 6}
                          >
                            <Grid container>
                              <Grid
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                item
                                sx={{ pt: 1.5 }}
                              >
                                {isLoaded ? (
                                  <>
                                    <Box sx={{ display: "flex" }}>
                                      <Box
                                        sx={{
                                          pr: 1.5,
                                        }}
                                      >
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M10.3333 21.1667C10.3333 21.3877 10.2455 21.5997 10.0893 21.7559C9.93297 21.9122 9.72101 22 9.5 22H2.83333C2.61232 22 2.40036 21.9122 2.24408 21.7559C2.0878 21.5997 2 21.3877 2 21.1667C2 20.9457 2.0878 20.7337 2.24408 20.5774C2.40036 20.4211 2.61232 20.3333 2.83333 20.3333H9.5C9.72101 20.3333 9.93297 20.4211 10.0893 20.5774C10.2455 20.7337 10.3333 20.9457 10.3333 21.1667Z"
                                            fill="#FBB73C"
                                          />
                                          <path
                                            d="M2.83333 18.6667H7.83333C8.05435 18.6667 8.26631 18.5789 8.42259 18.4226C8.57887 18.2663 8.66666 18.0543 8.66666 17.8333C8.66666 17.6123 8.57887 17.4004 8.42259 17.2441C8.26631 17.0878 8.05435 17 7.83333 17H2.83333C2.61232 17 2.40036 17.0878 2.24408 17.2441C2.0878 17.4004 2 17.6123 2 17.8333C2 18.0543 2.0878 18.2663 2.24408 18.4226C2.40036 18.5789 2.61232 18.6667 2.83333 18.6667Z"
                                            fill="#FBB73C"
                                          />
                                          <path
                                            d="M2.83333 15.3333H6.16667C6.38768 15.3333 6.59964 15.2455 6.75592 15.0892C6.9122 14.933 7 14.721 7 14.5C7 14.279 6.9122 14.067 6.75592 13.9107C6.59964 13.7545 6.38768 13.6667 6.16667 13.6667H2.83333C2.61232 13.6667 2.40036 13.7545 2.24408 13.9107C2.0878 14.067 2 14.279 2 14.5C2 14.721 2.0878 14.933 2.24408 15.0892C2.40036 15.2455 2.61232 15.3333 2.83333 15.3333Z"
                                            fill="#FBB73C"
                                          />
                                          <path
                                            d="M12 2C9.34783 2 6.80429 3.05357 4.92893 4.92893C3.05357 6.80429 2 9.34783 2 12C2 12.0508 2.00667 12.1 2.0075 12.1508C2.27216 12.0538 2.55147 12.0028 2.83333 12H6.16667C6.57575 11.9986 6.97888 12.098 7.34046 12.2893C7.70204 12.4806 8.01096 12.758 8.23992 13.097C8.46889 13.436 8.61087 13.8262 8.65332 14.2331C8.69577 14.64 8.63739 15.051 8.48333 15.43C8.82454 15.5195 9.1427 15.6809 9.41658 15.9032C9.69045 16.1255 9.91373 16.4037 10.0715 16.7192C10.2292 17.0348 10.3179 17.3803 10.3314 17.7328C10.3449 18.0853 10.2831 18.4366 10.15 18.7633C10.4833 18.8483 10.795 19.0024 11.065 19.2154C11.335 19.4285 11.5572 19.6958 11.7174 20.0002C11.8775 20.3046 11.972 20.6392 11.9947 20.9824C12.0174 21.3256 11.9678 21.6697 11.8492 21.9925C11.9 21.9925 11.9492 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34783 20.9464 6.80429 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2V2ZM15.0892 15.0892C14.9329 15.2454 14.721 15.3331 14.5 15.3331C14.279 15.3331 14.0671 15.2454 13.9108 15.0892L11.4108 12.5892C11.2545 12.4329 11.1667 12.221 11.1667 12V7.83333C11.1667 7.61232 11.2545 7.40036 11.4107 7.24408C11.567 7.0878 11.779 7 12 7C12.221 7 12.433 7.0878 12.5893 7.24408C12.7455 7.40036 12.8333 7.61232 12.8333 7.83333V11.655L15.0892 13.9108C15.2454 14.0671 15.3331 14.279 15.3331 14.5C15.3331 14.721 15.2454 14.9329 15.0892 15.0892Z"
                                            fill="#FBB73C"
                                          />
                                        </svg>
                                      </Box>
                                      <Typography
                                        variant={"inherit"}
                                        fontSize={16}
                                        fontWeight={400}
                                        sx={{
                                          display: "inline-block",
                                        }}
                                        gutterBottom={false}
                                      >
                                        {intl.formatMessage({
                                          id: "USER.SPENTTIME",
                                        })}
                                      </Typography>
                                    </Box>
                                  </>
                                ) : (
                                  <Skeleton width={"100%"} />
                                )}
                              </Grid>
                              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box sx={{ pt: 1.5, pl: 4.5 }}>
                                  <Typography
                                    variant={"inherit"}
                                    fontSize={18}
                                    fontWeight={600}
                                    sx={{
                                      display: "inline-block",
                                    }}
                                    gutterBottom={false}
                                  >
                                    {getUsedTime(time)}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={typeFilter == "case" ? 4 : 6}
                            lg={typeFilter == "case" ? 4 : 6}
                            xl={typeFilter == "case" ? 4 : 6}
                          >
                            <Grid container>
                              <Grid
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                item
                                sx={{ pt: 1.5 }}
                              >
                                {isLoaded ? (
                                  <>
                                    <Box sx={{ display: "flex" }}>
                                      <Box
                                        sx={{
                                          pr: 1.5,
                                        }}
                                      >
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M9.5 5.33333C9.5 3.4925 12.2983 2 15.75 2C19.2017 2 22 3.4925 22 5.33333C22 7.17417 19.2017 8.66667 15.75 8.66667C12.2983 8.66667 9.5 7.17417 9.5 5.33333ZM15.75 10.3333C14.8942 10.3333 14.0825 10.2375 13.3408 10.0708C12.2083 9.22167 10.3525 8.66667 8.25 8.66667C4.79833 8.66667 2 10.1592 2 12C2 13.8408 4.79833 15.3333 8.25 15.3333C11.7017 15.3333 14.5 13.8408 14.5 12C14.5 11.9758 14.4942 11.9525 14.4933 11.9283H14.5V13.6667C14.5 15.5075 11.7017 17 8.25 17C4.79833 17 2 15.5075 2 13.6667V15.3333C2 17.1742 4.79833 18.6667 8.25 18.6667C11.7017 18.6667 14.5 17.1742 14.5 15.3333V17C14.5 18.8408 11.7017 20.3333 8.25 20.3333C4.79833 20.3333 2 18.8408 2 17V18.6667C2 20.5075 4.79833 22 8.25 22C11.7017 22 14.5 20.5075 14.5 18.6667V18.6C14.9042 18.6433 15.3217 18.6667 15.75 18.6667C19.2017 18.6667 22 17.1742 22 15.3333V13.6667C22 15.4325 19.4233 16.8742 16.1667 16.9892V15.3225C19.4233 15.2075 22 13.7667 22 12V10.3333C22 12.0992 19.4233 13.5408 16.1667 13.6558V11.9892C19.4233 11.8742 22 10.4333 22 8.66667V7C22 8.84083 19.2017 10.3333 15.75 10.3333Z"
                                            fill="#3DCA81"
                                          />
                                        </svg>
                                      </Box>
                                      <Typography
                                        variant={"inherit"}
                                        fontSize={16}
                                        fontWeight={400}
                                        sx={{
                                          display: "inline-block",
                                        }}
                                        gutterBottom={false}
                                      >
                                        {intl.formatMessage({
                                          id: "CASE.FORM.BUDGET",
                                        })}
                                      </Typography>
                                    </Box>
                                  </>
                                ) : (
                                  <Skeleton width={"100%"} />
                                )}
                              </Grid>
                              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box sx={{ pt: 1.5, pl: 4.5 }}>
                                  <Typography
                                    variant={"inherit"}
                                    fontSize={18}
                                    fontWeight={600}
                                    sx={{
                                      display: "inline-block",
                                    }}
                                    gutterBottom={false}
                                  >
                                    {budget ? budget.toFixed(2) : 0} kr
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ProfileUserCard;
