import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDaNBAaRgXENPztAi4b1H_-kQuASIqXcrI",
  authDomain: "carecompagniet-cd932.firebaseapp.com",
  projectId: "carecompagniet-cd932",
  storageBucket: "carecompagniet-cd932.firebasestorage.app",
  messagingSenderId: "963486991598",
  appId: "1:963486991598:web:457fe45274555f85bb5fff",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BEcWGWBgmd-RADYWxU1AwTD-wYaBhpqVBPOkvz_XWklRgO9tVIk1Wy5uGdWyqXGr0Lm5SCZt1DCSK5oY7Fz1w58", // Firebase'dən alınmalıdır!
    });

    if (currentToken) {
      return currentToken;
    } else {
      console.log("📛 Token Error! FCM problem!");
    }
  } catch (error) {
    console.error("📛 Error while getting token:", error);
  }
};

if ("serviceWorker" in navigator && "PushManager" in window) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    if (registrations.length === 0) {
      navigator.serviceWorker
        .register(`/firebase-messaging-sw.js?v=${new Date().getTime()}`, {
          updateViaCache: "none",
        })
        .catch((error) => {
          console.error("📛 Service Worker registered unsuccessfully:", error);
        });
    }
  });
}

onMessage(messaging, (payload) => {
  if (!("Notification" in window) || Notification.permission !== "granted")
    return;

  new Notification(payload.notification.title, {
    body: payload.notification.body,
    data: payload.data,
  });
});

export { messaging };
