import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Slide,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "app/widgets/uncontrolled";
import Snackbar from "app/widgets/Snackbar";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { caseService, robotService, userService } from "app/services";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import { DatePicker, Select, Space } from "antd";
import { ConfigProvider } from "antd/es";
import moment from "moment/moment";
import SelectUsers, { SelectedUsers } from "../../../partials/SelectUsers";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ICase } from "../../../services/case.service";
import { IUser } from "../../../interfaces";
import UserAvatar from "../../../partials/layout/UserAvatar";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

export type FormStateValues = {
  type: string;
  title: string | null;
  category: string;
  description: string;
  case: string;
  lead: string;
  users: Array<string>;
  status: string;
  start_date: string;
  end_date: string;
  repeat: {
    every: number;
    type: string;
    days?: string[];
    end: { type: string; value: string };
  };
  generation: {
    type: string;
    end: { type: string; value: number };
  };
};

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "#ffffff",
  },
  tooltip: {
    backgroundColor: "#ffffff",
    minWidth: 330,
    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.25)",
    height: 136,
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
    padding: 0,
    display: "flex",
    alignItems: "center",
    borderRadius: 8,
  },
  iconButtonMessage: {
    "&:hover": {
      backgroundColor: "#556EE6",
    },
  },
  iconButtonProfile: {
    "&:hover": {
      backgroundColor: "#E1E6FB",
    },
  },
  popper: {
    left: "-5px!important",
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

const RobotStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const form = useForm<FormStateValues>();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [repeat, setRepeat] = useState<{
    every: number;
    type: string;
    days?: string[];
    end: { type: string; value: string };
  }>({ every: 0, type: "", days: [], end: { type: "", value: "" } });

  const [generation, setGeneration] = useState<{
    type: string;
    end: { type: string; value: number };
  }>({ type: "auto", end: { type: "after", value: 1 } });
  const [daySelect, setDaySelect] = useState<boolean>(false);
  const [dateSelect, setDateSelect] = useState<boolean>(false);
  const [afterInput, setAfterInput] = useState<boolean>(false);
  const timeOut = useRef<any>();
  const [leads, setLeads] = useState<IUser[]>([]);
  const [status, setStatus] = useState<boolean>(true);
  const [userSearch, setUserSearch] = useState<string>("");
  const [usersDialog, setUsersDialog] = useState<boolean | null>(false);
  const [s_users, setSelectedUsers] = useState<Array<SelectedUsers>>([]);
  const { todo_types } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { todo_categories } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { todo_statuses } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const { roles } = useSelector((state: RootState) => state.permission);
  const [cases, setCases] = useState<Array<ICase>>();
  const notStartedStatus = todo_statuses
    ? todo_statuses.find(
        (todo_status) =>
          todo_status.slug === "not_started" ||
          todo_status.slug === "not-started"
      )?._id
    : "";

  useEffect(() => {
    if (notStartedStatus) form.values.status = notStartedStatus;
  }, [notStartedStatus]);

  const handleType = (type: string) => {
    if (type === "week") {
      setDaySelect(true);
    } else {
      setDaySelect(false);
    }
  };

  useEffect(() => {
    if (s_users) form.values.users = s_users.map((user) => user._id);
  }, [s_users]);

  useEffect(() => {
    form.values.repeat = repeat;
  }, [repeat]);

  useEffect(() => {
    form.values.generation = generation;
  }, [generation]);

  const handleEnd = (type: string) => {
    if (type === "date") {
      setDateSelect(true);
      setAfterInput(false);
    } else if (type === "after") {
      setAfterInput(true);
      setDateSelect(false);
    } else {
      setDateSelect(false);
      setAfterInput(false);
    }
  };

  const handleClose = () => {
    props.setDialogue(false);
    setSelectedUsers([]);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    robotService
      .add(validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const sendQuery = (value: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      setUserSearch(value);
    }, 500);
  };

  const UserTooltipCard = (user: SelectedUsers) => {
    return (
      <>
        <Grid
          sx={{
            width: "100%",
            maxWidth: 360,
            backgroundColor: "#fff",
            color: "#4A4A4A",
            display: "flex",
            flexDirection: "column",
            rowGap: 2,
          }}
        >
          <Grid sx={{ mt: 1, mx: 2 }}>
            <Grid container alignItems="center">
              <Grid item sm={2} xs={2} md={2} lg={2} xl={2}>
                <Avatar
                  src={user?.file?.base64}
                  style={{
                    backgroundColor: user?.role?.color ?? "#0D99FF",
                  }}
                  alt={user?.firstname}
                  sx={{
                    width: 36,
                    height: 36,
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  variant="square"
                >
                  {user?.firstname.charAt(0)}
                  {user?.lastname.charAt(0)}
                </Avatar>
              </Grid>
              <Grid item sm={10} xs={10} md={10} lg={10} xl={10}>
                <Grid
                  sx={{
                    height: 36,
                    marginLeft: 2,
                  }}
                >
                  <Typography
                    variant={"inherit"}
                    sx={{ fontSize: 13, fontWeight: 400 }}
                  >
                    {user.firstname} {user.lastname}
                  </Typography>
                  <Typography
                    variant={"inherit"}
                    sx={{ fontSize: 12, fontWeight: 400, color: "#9FA5BB" }}
                  >
                    {user?.role?.title[intl.locale]}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ m: 2 }}>
            <Grid container spacing={1}>
              <Grid item sm={5} xs={5} md={5} lg={5} xl={5}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    window.open(`/chats?user=${user._id}`);
                  }}
                  className={classes.iconButtonMessage}
                  sx={{
                    width: "100%",
                    height: 32,
                    borderRadius: 2,
                    backgroundColor: "#556EE6",
                  }}
                >
                  <svg
                    className={"mr-1"}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.4982 2.28802L4.21497 12.5685C4.43728 12.6769 4.68112 12.7338 4.92842 12.7351H6.68957C6.8369 12.7347 6.97825 12.7933 7.08212 12.8978L8.03655 13.8516C8.55413 14.3728 9.2581 14.6661 9.99258 14.6667C10.2958 14.6664 10.597 14.6167 10.8843 14.5196C11.8746 14.1949 12.5948 13.3364 12.7426 12.3048L14.6187 3.39348C14.7083 3.02217 14.6657 2.63128 14.4982 2.28802Z"
                      fill="white"
                    />
                    <path
                      d="M12.6216 1.37634L3.73191 3.24912C2.21298 3.4578 1.1508 4.85831 1.35948 6.37724C1.44191 6.97709 1.71817 7.53358 2.1462 7.96184L3.10007 8.91572C3.20434 9.01995 3.26287 9.16137 3.26276 9.30881V11.07C3.26404 11.3173 3.32098 11.5611 3.42933 11.7834L13.7109 1.50018C13.373 1.33413 12.9881 1.29038 12.6216 1.37634Z"
                      fill="white"
                    />
                  </svg>
                  <Typography
                    variant={"inherit"}
                    sx={{ fontSize: 12, fontWeight: 400, color: "#fff" }}
                  >
                    Message
                  </Typography>
                </IconButton>
              </Grid>
              <Grid item sm={5} xs={5} md={5} lg={5} xl={5}>
                <IconButton
                  onClick={() => {
                    window.open(`/users/${user._id}`);
                  }}
                  className={classes.iconButtonProfile}
                  sx={{
                    width: "100%",
                    height: 32,
                    borderRadius: 2,
                    backgroundColor: "#E1E6FB",
                  }}
                >
                  <svg
                    className={"mr-1"}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.2381 8.00001C10.044 8.00001 11.5079 6.50763 11.5079 4.66668C11.5079 2.82573 10.044 1.33334 8.2381 1.33334C6.43222 1.33334 4.96826 2.82573 4.96826 4.66668C4.96826 6.50763 6.43222 8.00001 8.2381 8.00001Z"
                      fill="#2A3042"
                    />
                    <path
                      d="M8.23807 9.11118C5.53049 9.11425 3.33633 11.351 3.33331 14.1112C3.33331 14.418 3.5773 14.6667 3.87828 14.6667H12.5978C12.8988 14.6667 13.1428 14.418 13.1428 14.1112C13.1398 11.351 10.9456 9.11422 8.23807 9.11118Z"
                      fill="#2A3042"
                    />
                  </svg>
                  <Typography
                    variant={"inherit"}
                    sx={{ fontSize: 12, fontWeight: 400, color: "#2A3042" }}
                  >
                    Go to profile
                  </Typography>
                </IconButton>
              </Grid>
              <Grid item sm={2} xs={2} md={2} lg={2} xl={2}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  className={classes.iconButtonProfile}
                  sx={{
                    width: "100%",
                    height: 32,
                    borderRadius: 2,
                    backgroundColor: "#E1E6FB",
                  }}
                >
                  <MoreHorizIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    if (todo_types) {
      form.values.type = todo_types[0]._id;
    }

    if (notStartedStatus) {
      form.values.category = notStartedStatus;
    }

    caseService
      .getAll([
        {
          name: "pagination",
          value: "0",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: cases } = data;
        setCases(cases);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);
  const setStatusField = (status: string) => {
    const slug =
      status &&
      todo_types?.find((todo_status) => todo_status._id === status)?.slug;

    if (slug === "event") {
      setStatus(false);
    } else {
      setStatus(true);
    }
  };

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
      {
        name: "keyword",
        value: userSearch,
      },
    ];

    userService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setLeads(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [userSearch]);

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
    ];

    userService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setLeads(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  return (
    <>
      <SelectUsers
        open={Boolean(usersDialog)}
        Title={
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage id={"CASE.USERS"} defaultMessage={"CASE.USERS"} />
          </Typography>
        }
        handleClose={() => {
          setUsersDialog(null);
        }}
        handleSave={() => {}}
        onUserChange={(users) => {
          setSelectedUsers([...users]);
        }}
        users={s_users}
      />
      {
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#E0F2FF",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"ROBOT.ADD"}
                  defaultMessage={"ROBOT.ADD"}
                />
              </Typography>
              <Button
                form={"robot_form"}
                autoFocus
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"robot_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                          sx={{
                            backgroundColor: "#0D99FF",
                            color: "#ffffff",
                          }}
                        />
                      </Divider>
                      <Controls.Input
                        name={"title"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.title = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Box>
                    <Divider sx={{ mb: 1 }}>
                      <Chip
                        label={intl.formatMessage({ id: "CASE.USERS" })}
                        sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                      />
                    </Divider>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"LABEL.SELECTUSER"}
                        defaultMessage={"LABEL.SELECTUSER"}
                      />
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      {s_users.map((user) => {
                        return (
                          <Box sx={{ position: "relative" }}>
                            <UserAvatar user={user} />;
                            <IconButton
                              onClick={() => {
                                setSelectedUsers(
                                  s_users.filter(
                                    (s_user) => s_user._id !== user._id
                                  )
                                );
                              }}
                              sx={{
                                position: "absolute",
                                top: "-5px",
                                right: "-5px",
                                padding: "5px",
                                backgroundColor: "#EC3033",
                                "&:hover": { backgroundColor: "#EC3033" },
                              }}
                            >
                              <CloseOutlinedIcon
                                sx={{
                                  color: "#fff",
                                  width: 10,
                                  height: 10,
                                }}
                              />
                            </IconButton>
                          </Box>
                        );
                      })}
                      <Button
                        onClick={() => {
                          setUsersDialog(true);
                        }}
                        sx={{
                          minWidth: "1px!important",
                          maxWidth: "44px!important",
                          height: 44,
                          borderRadius: "100%",
                          mt: 0.5,
                          p: "14px!important",
                          backgroundColor: "#0D99FF",
                        }}
                        variant="contained"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20.75 10.75H13.25V3.25C13.25 2.55965 12.6904 2 12 2C11.3096 2 10.75 2.55965 10.75 3.25V10.75H3.25C2.55965 10.75 2 11.3096 2 12C2 12.6904 2.55965 13.25 3.25 13.25H10.75V20.75C10.75 21.4404 11.3096 22 12 22C12.6904 22 13.25 21.4404 13.25 20.75V13.25H20.75C21.4404 13.25 22 12.6904 22 12C22 11.3096 21.4404 10.75 20.75 10.75Z"
                            fill="white"
                          />
                        </svg>
                      </Button>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box
                      sx={{
                        input: {
                          "& .MuiFormControl-root": {
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                top: 0,
                                border: "1px solid #E6E6E6!important",
                                borderRadius: 6,
                              },
                            },
                          },
                        },
                      }}
                    >
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={"Case&Category"}
                          sx={{
                            backgroundColor: "#0D99FF",
                            color: "#ffffff",
                          }}
                        />
                      </Divider>
                      <Box className={classes.input}>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"STANDARD.CATEGORY"}
                            defaultMessage={"STANDARD.CATEGORY"}
                          />
                        </Typography>
                        <Controls.Select
                          formId={"category_pick"}
                          name={"category"}
                          options={todo_categories?.map((t) => {
                            return {
                              id: t._id.toString(),
                              title: t.title[intl.locale],
                            };
                          })}
                          defaultValue={
                            todo_categories && todo_categories[0]._id
                          }
                          label={""}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.category = event.target.value;
                          }}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"category"}
                        options={[]}
                        label={intl.formatMessage({
                          id: "STANDARD.CATEGORY",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"STANDARD.TYPE"}
                          defaultMessage={"STANDARD.TYPE"}
                        />
                      </Typography>
                      <Controls.Select
                        formId={"type_pick"}
                        name={"type"}
                        options={todo_types?.map((t) => {
                          return {
                            id: t._id.toString(),
                            title: t.title[intl.locale],
                          };
                        })}
                        defaultValue={todo_types && todo_types[0]._id}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.type = event.target.value;
                          setStatusField(event.target.value);
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"type"}
                        options={[]}
                        onChange={() => {}}
                        label={intl.formatMessage({ id: "STANDARD.TYPE" })}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"CASE.INFORMATION.CASE"}
                          defaultMessage={"CASE.INFORMATION.CASE"}
                        />
                      </Typography>
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        onChange={(e, value: any) => {
                          form.values.case = value?.id;
                        }}
                        options={
                          cases
                            ? cases.map((_case) => {
                                return {
                                  id: _case._id,
                                  label:
                                    _case.key +
                                    ", " +
                                    intl.formatMessage({
                                      id: "CASE.INFORMATION.CASE",
                                    }) +
                                    ", users, " +
                                    _case.users.map(
                                      (u) => u.firstname + " " + u.lastname
                                    ),
                                };
                              })
                            : []
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="" />
                        )}
                      />
                      {/*<Controls.Select*/}
                      {/*  name={"case"}*/}
                      {/*  formId={"case_pick"}*/}
                      {/*  options={*/}
                      {/*    cases &&*/}
                      {/*    cases.map((_case) => {*/}
                      {/*      return {*/}
                      {/*        id: _case._id.toString(),*/}
                      {/*        title: `${intl.formatMessage({*/}
                      {/*          id: "CASE.INFORMATION.CASE",*/}
                      {/*        })}: ${_case.key}; Users: ${_case.users.map(*/}
                      {/*          (u) => u.firstname*/}
                      {/*        )}`,*/}
                      {/*      };*/}
                      {/*    })*/}
                      {/*  }*/}
                      {/*  label={""}*/}
                      {/*  onChange={(event) => {*/}
                      {/*    if (!event.target.value) return;*/}

                      {/*    form.values.case = event.target.value;*/}
                      {/*  }}*/}
                      {/*/>*/}
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"case"}
                        options={[]}
                        label={intl.formatMessage({
                          id: "CASE.INFORMATION.CASE",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                {status && (
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    {loaded ? (
                      <Box className={classes.input}>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"STANDARD.STATUS"}
                            defaultMessage={"STANDARD.STATUS"}
                          />
                        </Typography>
                        <Controls.Select
                          options={todo_statuses?.map((t) => {
                            return {
                              id: t._id.toString(),
                              title: t.title[intl.locale],
                            };
                          })}
                          formId={"status_pick"}
                          defaultValue={notStartedStatus}
                          name={"status"}
                          label={""}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.status = event.target.value;
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Select
                          options={[]}
                          name={"status"}
                          label={intl.formatMessage({
                            id: "STANDARD.STATUS",
                          })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                )}
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={status ? 6 : 12}
                  xl={status ? 6 : 12}
                >
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"CASE.TODO.LEAD"}
                          defaultMessage={"CASE.TODO.LEAD"}
                        />
                      </Typography>
                      <Select
                        placeholder={intl.formatMessage({
                          id: "CASE.TODO.LEAD",
                        })}
                        showSearch
                        onChange={(value: string) => {
                          form.values.lead = value;
                        }}
                        onSearch={(value) => {
                          sendQuery(value);
                        }}
                        filterOption={false}
                        style={{ width: "100%" }}
                        allowClear={true}
                        options={
                          leads &&
                          leads.map((user) => {
                            return {
                              value: user._id.toString(),
                              label: user.firstname + " " + user.lastname,
                            };
                          })
                        }
                        className={classes.antSelect}
                        onClear={() => {
                          sendQuery("");
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"lead"}
                        options={[]}
                        label={intl.formatMessage({ id: "CASE.TODO.LEAD" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"STANDARD.DESCRIPTION"}
                          defaultMessage={"STANDARD.DESCRIPTION"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"description"}
                        label={""}
                        multiline={true}
                        rows={6}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.description = event.target.value;
                        }}
                        InputProps={{
                          // @ts-ignore
                          "data-cy": "todo_description",
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <>
                    {loaded ? (
                      <Box className={classes.input} sx={{ width: "100%" }}>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"CASE.TIMETRACK.STARTDATE"}
                            defaultMessage={"CASE.TIMETRACK.STARTDATE"}
                          />
                        </Typography>
                        <Space
                          className={classes.antSpace}
                          direction="vertical"
                          size={12}
                        >
                          <DatePicker
                            showTime={{ format: "HH:mm" }}
                            className={classes.datePicker}
                            dropdownClassName={classes.antDropdown}
                            onChange={(value, dateString) => {
                              form.values.start_date = (
                                moment(
                                  dateString,
                                  "DD-MM-YYYY HH:mm"
                                ).valueOf() / 1000
                              ).toString();
                            }}
                            format={"DD-MM-YYYY HH:mm"}
                          />
                        </Space>
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <DatePicker
                          showTime={{ format: "HH:mm" }}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                    {loaded ? (
                      <Box className={classes.input} sx={{ width: "100%" }}>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"CASE.TIMETRACK.ENDDATE"}
                            defaultMessage={"CASE.TIMETRACK.ENDDATE"}
                          />
                        </Typography>
                        <Space
                          className={classes.antSpace}
                          direction="vertical"
                          size={12}
                        >
                          <DatePicker
                            showTime={{ format: "HH:mm" }}
                            className={classes.datePicker}
                            dropdownClassName={classes.antDropdown}
                            onChange={(value, dateString) => {
                              form.values.end_date = (
                                moment(
                                  dateString,
                                  "DD-MM-YYYY HH:mm"
                                ).valueOf() / 1000
                              ).toString();
                            }}
                            format={"DD-MM-YYYY HH:mm"}
                          />
                        </Space>
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Space
                          className={classes.antSpace}
                          direction="vertical"
                          size={12}
                        >
                          <DatePicker />
                        </Space>
                      </Skeleton>
                    )}
                  </>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <Divider sx={{ mb: 1 }}>
                    <Chip
                      label={"Robot"}
                      sx={{
                        backgroundColor: "#0D99FF",
                        color: "#ffffff",
                      }}
                    />
                  </Divider>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"STANDARD.EVERY"}
                          defaultMessage={"STANDARD.EVERY"}
                        />
                      </Typography>
                      <Controls.Input
                        name={""}
                        type={"number"}
                        // defaultValue={props._todo?.type?._id}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          setRepeat({
                            ...repeat,
                            every: +event.target.value,
                          });
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"type"}
                        options={[]}
                        onChange={() => {}}
                        label={intl.formatMessage({ id: "STANDARD.TYPE" })}
                      />
                    </Skeleton>
                  )}
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={daySelect ? 3 : 6}
                  xl={daySelect ? 3 : 6}
                >
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"STANDARD.TYPE"}
                          defaultMessage={"STANDARD.TYPE"}
                        />
                      </Typography>
                      <Controls.Select
                        name={"type"}
                        formId={"type"}
                        options={[
                          {
                            id: "day",
                            title: "Day",
                          },
                          {
                            id: "week",
                            title: "Week",
                          },
                          {
                            id: "month",
                            title: "Month",
                          },
                          {
                            id: "year",
                            title: "Year",
                          },
                        ]}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          handleType(event.target.value);
                          setRepeat({
                            ...repeat,
                            type: event.target.value,
                          });
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>

                {daySelect && (
                  <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
                    {loaded ? (
                      <Box className={classes.input}>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"STANDARD.TYPE"}
                            defaultMessage={"STANDARD.TYPE"}
                          />
                        </Typography>
                        <Controls.MultipleSelect
                          label={""}
                          name={"days"}
                          formId={"days"}
                          options={[
                            {
                              id: 1,
                              title: "Monday",
                            },
                            {
                              id: 2,
                              title: "Tuesday",
                            },
                            {
                              id: 3,
                              title: "Wednesday",
                            },
                            {
                              id: 4,
                              title: "Thursday",
                            },
                            {
                              id: 5,
                              title: "Friday",
                            },
                            {
                              id: 6,
                              title: "Saturday",
                            },
                            {
                              id: 7,
                              title: "Sunday",
                            },
                          ]}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            setRepeat({
                              ...repeat,
                              days: event.target.value,
                            });
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          name={"description"}
                          label={intl.formatMessage({
                            id: "STANDARD.DESCRIPTION",
                          })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"STANDARD.END"}
                          defaultMessage={"STANDARD.END"}
                        />
                      </Typography>
                      <Controls.Select
                        options={[
                          {
                            id: "never",
                            title: "Never",
                          },
                          {
                            id: "date",
                            title: "Date",
                          },
                          {
                            id: "after",
                            title: "After",
                          },
                        ]}
                        formId={"status_pick"}
                        name={"end"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          handleEnd(event.target.value);

                          setRepeat({
                            ...repeat,
                            end: {
                              type: event.target.value,
                              value: moment().format("YYYY-MM-DD"),
                            },
                          });
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        options={[]}
                        name={"status"}
                        label={intl.formatMessage({
                          id: "STANDARD.STATUS",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                {dateSelect && (
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    <>
                      {loaded ? (
                        <Box className={classes.input}>
                          <Typography variant={"body1"} sx={{ mb: 1 }}>
                            <FormattedMessage
                              id={"STANDARD.DATE"}
                              defaultMessage={"STANDARD.DATE"}
                            />
                          </Typography>
                          <Space
                            className={classes.antSpace}
                            direction="vertical"
                            size={12}
                          >
                            <DatePicker
                              className={classes.datePicker}
                              dropdownClassName={classes.antDropdown}
                              onChange={(value, dateString) => {
                                setRepeat({
                                  ...repeat,
                                  end: {
                                    type: repeat.end.type,
                                    value: dateString,
                                  },
                                });
                              }}
                            />
                          </Space>
                        </Box>
                      ) : (
                        <Skeleton width={"100%"}>
                          <Space
                            className={classes.antSpace}
                            direction="vertical"
                            size={12}
                          >
                            <DatePicker />
                          </Space>
                        </Skeleton>
                      )}
                    </>
                  </Grid>
                )}
                {afterInput && (
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    {loaded ? (
                      <Box className={classes.input}>
                        <Typography
                          variant={"body1"}
                          sx={{ mb: 1, display: "inline-block" }}
                        >
                          <FormattedMessage
                            id={"STANDARD.AFTER"}
                            defaultMessage={"STANDARD.AFTER"}
                          />
                        </Typography>
                        <Controls.Input
                          name={"title"}
                          type={"number"}
                          label={""}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            setRepeat({
                              ...repeat,
                              end: {
                                type: repeat.end.type,
                                value: event.target.value,
                              },
                            });

                            event.preventDefault();
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          name={"title"}
                          label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                )}
              </Grid>

              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <Divider sx={{ mb: 1 }}>
                    <Chip
                      label={"Generation"}
                      sx={{
                        backgroundColor: "#0D99FF",
                        color: "#ffffff",
                      }}
                    />
                  </Divider>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={daySelect ? 3 : 6}
                  xl={daySelect ? 3 : 6}
                >
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"STANDARD.TYPE"}
                          defaultMessage={"STANDARD.TYPE"}
                        />
                      </Typography>
                      <Controls.Select
                        name={"type"}
                        formId={"type"}
                        options={[
                          {
                            id: "auto",
                            title: "Auto",
                          },
                          {
                            id: "manual",
                            title: "Manual",
                          },
                        ]}
                        defaultValue={"auto"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          setGeneration({
                            ...generation,
                            type: event.target.value,
                          });
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        After occurrence
                      </Typography>
                      <Controls.Input
                        name={""}
                        type={"number"}
                        defaultValue={"1"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          setGeneration({
                            ...generation,
                            end: {
                              type: generation.end.type,
                              value: +event.target.value,
                            },
                          });
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"type"}
                        options={[]}
                        onChange={() => {}}
                        label={intl.formatMessage({ id: "STANDARD.TYPE" })}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default RobotStore;
