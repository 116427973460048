import React, { FC } from "react";
import { Grid, Theme, useScrollTrigger } from "@mui/material";
import { IUser } from "app/interfaces";
import { makeStyles } from "@material-ui/core";
import _Body, { MessengerBodyProps } from "./Body/body";
import _Header, { MessengerHeaderProps } from "./Header/header";
import _Composer, { MessengerComposerType } from "./Composer/composer";
import { IFile } from "../../../services/file.service";
import {
  Profile as _Profile,
  ChatProfileProps,
} from "../messenger/Profile/index";
import { IRoom } from "../rooms/rooms";

enum EStatus {
  undone = 0,
  done = 1,
}

export interface IMessage {
  _id: string;
  uuid: string; // it is front generated id
  replyMessage?: IMessage;
  chat: string;
  message: string;
  pending?: boolean;
  hasFile?: boolean;
  documents?: Array<IFile>;
  sent: EStatus;
  received: EStatus;
  created_by: IUser;
  _created_at: number;
  _updated_at?: number;
}

type MessengerProps = {
  Composer: MessengerComposerType;
  Header: MessengerHeaderProps;
  Body: MessengerBodyProps;
  Profile: ChatProfileProps;
  me: string;
  archiveOpen: boolean;
  updateRoom: (room: IRoom) => void;
};

const useStyle = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: 8,

    // [theme.breakpoints.down("md")]: {
    //   display: "none",
    // },
  },
  empty_container: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 8,
  },
}));

const Messenger: FC<MessengerProps> = (props) => {
  const { Composer, Body, Header, Profile, me, archiveOpen, updateRoom } =
    props;
  const { messages, horizontalLineDBLClick, active } = Body;
  const classes = useStyle();

  return (
    <Grid
      sx={{
        position: { xs: "fixed", md: "relative" },
        bottom: { xs: 0, md: "auto" },
        left: 0,
        top: 0,
        width: "100%",
        zIndex: 10,
        borderRadius: 8,
        backgroundColor: "white", // Fon rəngi
        height: "100%",
        display: active ? "block" : { md: "flex", xs: "none" },
        flexDirection: "column",
        overflow: "hidden",
      }}
      // className={messages ? classes.container : classes.empty_container}
    >
      {messages ? (
        <>
          <_Header
            onSearch={Header.onSearch}
            onClick={Header.onClick}
            actionPanel={Header.actionPanel}
            info={Header.info}
            onClose={Header.onClose}
            room={Header.room}
          />
          <_Body
            messages={messages}
            me={me}
            horizontalLineDBLClick={horizontalLineDBLClick}
            active={Body.active}
            room={Body.room}
            archiveOpen={Body.archiveOpen}
            needResetMessages={archiveOpen}
            updateRoom={(room) => updateRoom(room)}
            users={Body.users}
          />
          <_Composer {...Composer} />
        </>
      ) : (
        <img width="25%" src="/media/chat/empty.svg" />
      )}
      <_Profile {...Profile} />
    </Grid>
  );
};

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default Messenger;
