import React, { FC, useEffect } from "react";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import {
  Commune,
  Condition,
  Dates,
  Finance,
  Focus,
  Users,
} from "../store/parts";
import { IFormProps } from "../store/caseStore";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { ICase } from "../../../services/case.service";
import moment from "moment";
import Report from "../store/parts/report";
import { ISettingsState } from "../../../store/ducks/setting.duck";
import { IRole } from "../../../interfaces";
import * as _setting from "../../../store/ducks/setting.duck";

interface _IFormProps extends IFormProps {
  case: ICase;
}

const EditDialogue: FC<_IFormProps> = (props) => {
  const intl = useIntl();
  const currYear: number = new Date().getFullYear();

  useEffect(() => {
    if (props.case) {
      props.form.values.users = props.case.users.map((user) => user._id);
      props.form.values.users_titles = props.case.users_titles;
      props.form.values.commune = props.case.commune?._id;
      props.form.values.notice_commune = props.case.notice_commune?._id;
      props.form.values.expire_date_commune = moment
        .unix(+props.case.expire_date_commune)
        .local()
        .format("YYYY-MM-DD");
      props.form.values.focus_area = props.case.focus_area?._id;
      props.form.values.focus_type = props.case.focus_type?._id;
      props.form.values.report = props.case.report;
      props.form.values.report_type = props.case.report_type?._id;
      props.form.values.report_interval = props.case.report_interval?._id;
      props.form.values.employee_position = props.case.employee_position?._id;
      props.form.values.employee_rate = props.case.employee_rate;
      props.form.values.start_date_employee = moment
        .unix(+props.case.start_date_employee)
        .local()
        .format("YYYY-MM-DD");
      props.form.values.start_date_case = moment
        .unix(+props.case.start_date_case)
        .local()
        .format("YYYY-MM-DD");
      props.form.values.start_date_finance = moment
        .unix(+props.case.start_date_finance)
        .local()
        .format("YYYY-MM-DD");
      props.form.values.start_date_report = moment
        .unix(+props.case.start_date_report)
        .local()
        .format("YYYY-MM-DD");
      props.form.values.administration_work =
        props.case.administration_work / 1000 / 3600;
      props.form.values.face_to_face = props.case.face_to_face / 1000 / 3600;
      props.form.values.meeting_duration_type =
        props.case.meeting_duration_type?._id;
      props.form.values.meeting_durations =
        props.case.meeting_durations[currYear];
      props.form.values.init_budget = props.case.budget?.balance;
      props.form.values.budget_interval = props.case.budget_interval?._id;
      props.form.values.budget_limit = props.case.budget_limit;
      props.form.values.currency = props.case.budget?.currency;
    }
  }, [props.case]);

  return (
    <Grid container spacing={4}>
      <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
        <Divider sx={{ my: 2 }}>
          <Chip
            label={intl.formatMessage({ id: "CASE.FORM.FOCUSAREATYPE" })}
            sx={{ bgcolor: "#f5f5f5", color: "#000000", fontWeight: 600 }}
          />
        </Divider>
        <Focus
          {...props}
          handleChange={props.form.handleChange}
          values={props.form.values}
          touched={props.form.touched}
        />

        <Divider sx={{ my: 2 }}>
          <Chip
            label={intl.formatMessage({
              id: "CASE.INFORMATION.COMMUNE.COMMUNE",
            })}
            sx={{ bgcolor: "#f5f5f5", color: "#000000", fontWeight: 600 }}
          />
        </Divider>
        <Commune
          {...props}
          handleChange={props.form.handleChange}
          values={props.form.values}
          touched={props.form.touched}
        />

        <Report
          {...props}
          handleChange={props.form.handleChange}
          values={props.form.values}
          touched={props.form.touched}
        />

        <Divider sx={{ my: 2 }}>
          <Chip
            label={intl.formatMessage({ id: "STANDARD.DATE" })}
            sx={{ bgcolor: "#f5f5f5", color: "#000000", fontWeight: 600 }}
          />
        </Divider>
        <Dates
          {...props}
          handleChange={props.form.handleChange}
          values={props.form.values}
          touched={props.form.touched}
        />
      </Grid>

      <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
        <Divider sx={{ my: 2, mb: 2 }}>
          <Chip
            label={intl.formatMessage({ id: "STANDARD.USERS" })}
            sx={{ bgcolor: "#f5f5f5", color: "#000000", fontWeight: 600 }}
          />
        </Divider>
        <Users
          count={3}
          handleChange={props.form.handleChange}
          values={props.form.values}
          case={props.case}
          title
        />

        <Divider sx={{ my: 2 }}>
          <Chip
            label={intl.formatMessage({ id: "CASE.FORM.WORKCONDITIONS" })}
            sx={{ bgcolor: "#f5f5f5", color: "#000000", fontWeight: 600 }}
          />
        </Divider>
        <Condition
          {...props}
          handleChange={props.form.handleChange}
          values={props.form.values}
          touched={props.form.touched}
        />

        <Divider sx={{ my: 2 }}>
          <Chip
            label={intl.formatMessage({ id: "CASE.INFORMATION.FINANCE" })}
            sx={{ bgcolor: "#f5f5f5", color: "#000000", fontWeight: 600 }}
          />
        </Divider>
        <Finance
          {...props}
          handleChange={props.form.handleChange}
          values={props.form.values}
          touched={props.form.touched}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({
  settingReducer,
  permission,
}: {
  settingReducer: ISettingsState;
  permission: {
    roles: Array<IRole>;
    roles_pending: boolean;
  };
}) => {
  return {
    focus_areas: settingReducer.focus_areas ?? [],
    focus_types: settingReducer.focus_types ?? [],
    positions: settingReducer.positions ?? [],
    report_types: settingReducer.report_types ?? [],
    report_intervals: settingReducer.report_intervals ?? [],
    budget_intervals: settingReducer.budget_intervals ?? [],
    reports: [],
    meeting_duration_types: settingReducer.meeting_duration_types ?? [],
    settings_loading: settingReducer.loading ?? [],
    roles_pending: permission.roles_pending,
    roles: permission.roles,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSettings: () => dispatch(_setting.actions.get()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDialogue);
