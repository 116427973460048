import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Box, Grid } from "@mui/material";
import { Space, DatePicker } from "antd";
import { makeStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import { IUrlQuery } from "../../../../interfaces";

const useStyles = makeStyles(() => ({
  calendar: {
    zIndex: 99999,
  },
  dateRangeInput: {
    width: "100%",
    borderRadius: 8,
    border: "none!important",
    "& .ant-picker-focused": {
      boxShadow: "none!important",
    },
    "& .ant-picker": {
      border: "1px solid #E6E6E6!important",
      height: 40,
      backgroundColor: "#fff",
      borderRadius: 8,
      width: "100%",
      color: "#000000!important",
      "& .ant-picker-input input::placeholder": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
  },
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-input": {
          padding: "11px!important",
        },
      },
      "& .MuiInputLabel-root": {
        top: "-5px",
      },
      height: 40,
      backgroundColor: "#fff",
      border: "1px solid #E6E6E6",
      borderRadius: 8,
      m: () => ({}),
      "& .MuiOutlinedInput-notchedOutline": {
        border: "unset",
      },
    },
  },
}));

const NoteFilter = (props: {
  show: boolean;
  updateQuery: (queries: IUrlQuery[]) => void;
  queries: Array<IUrlQuery> | undefined;
  setQuery: Dispatch<SetStateAction<IUrlQuery[] | undefined>>;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const { RangePicker } = DatePicker;
  const intl = useIntl();
  const [from, setFrom] = useState<string>("");
  const [to, setTo] = useState<string>("");
  const handleQuery = (name: string, value: string) => {
    props.updateQuery([
      {
        name,
        value,
      },
    ]);
  };

  const handleFromToChange = (dateString: Array<string>) => {
    setFrom(dateString[0]);

    if (dateString[1].length > 0) setTo(dateString[1]);
  };

  useEffect(() => {
    if (from) handleQuery("from", from);

    if (to) handleQuery("to", to);
  }, [from, to]);

  const timeOut = useRef<any>();

  return (
    <Box
      sx={{
        overflow: "hidden",
        transition: ".2s all",
        height: props.show ? contentRef?.current?.scrollHeight + "px" : 0,
      }}
      ref={contentRef}
    >
      <Grid container spacing={1} sx={{ marginBottom: 1 }}>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Space
            direction="vertical"
            size={12}
            className={classes.dateRangeInput}
          >
            <RangePicker
              allowEmpty={[true, true]}
              placeholder={["From", "To"]}
              separator={"―"}
              dropdownClassName={classes.calendar}
              onCalendarChange={(value, dateString) => {
                if (!dateString[0] && !dateString[1]) {
                  let _queries = props.queries?.filter(
                    (q) => q.name !== "from" && q.name !== "to"
                  );

                  props.setQuery(_queries);

                  setFrom("");
                  setTo("");

                  return;
                }

                handleFromToChange(dateString);
              }}
            />
          </Space>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NoteFilter;
