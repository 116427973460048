import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router";
import { caseService } from "app/services";
import Snackbar from "app/widgets/Snackbar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Skeleton from "@mui/lab/Skeleton";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ICase } from "app/services/case.service";
import Overview from "app/pages/Cases/show/overview";
import { makeStyles } from "@material-ui/core";
import Information from "app/pages/Cases/show/information";
import Document from "app/pages/Cases/show/document";
import Plan from "app/pages/Cases/show/plan";
import Report from "app/pages/Cases/show/report";
import Finance from "app/pages/Cases/show/finance";
import Todo from "app/pages/Cases/show/todo";
import Feedback from "app/pages/Cases/show/feedback";
import Note from "app/pages/Cases/show/note";
import TimeTrack from "app/partials/content/TimeTrack";
import { ITimeTrack } from "app/services/timeTrack.service";
import CaseTimeTracks from "app/pages/Cases/show/time_track";
import { FormattedMessage, useIntl } from "react-intl";
import {
  PermissionsGate,
  PermissionsType,
} from "app/permission/PermissionsGate";
import { ISetting } from "app/services/setting.service";
import { IRole, IUser } from "app/interfaces";
import { TransitionProps } from "@mui/material/transitions";
import { communeService, ICommune } from "app/services/comunne.service";
import UserCard from "app/widgets/UserCard";
import FixedSideButton from "../../partials/layout/FixedSideButton";
import { useForm } from "../../hooks/useForm";
import { FormStateValues } from "./store";
import { useHistory, useLocation } from "react-router-dom";
import EditDialogue from "./show/edit";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { usePermissions } from "app/hooks/useRole";
import { hasPermission } from "app/permission/PermissionsGate";
import TodoStore from "../Todos/store";

interface IUpdate {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
  lang: any;
  getSettings: Function;
  users: Array<IUser>;
  focus_areas: Array<ISetting>;
  focus_types: Array<ISetting>;
  reports: Array<any>;
  report_types: Array<ISetting>;
  report_intervals: Array<ISetting>;
  meeting_duration_types: Array<ISetting>;
  budget_intervals: Array<ISetting>;
  settings_loading: boolean;
  users_loading: boolean;
  roles: Array<IRole>;
  roles_pending: boolean;
}

const useStyle = makeStyles(() => ({
  tabPanel: {
    "& .MuiTabPanel-root": {
      padding: "20px 0 0 0",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#0D99FF",
    },
    "& .MuiTabScrollButton-horizontal": {
      width: 24,
    },
  },
}));

const getIndex = (t: string): number | null => {
  let index = tabs.findIndex((tab) => tab.cy === t);
  return index === -1 ? null : index;
};

const Case: FC<IUpdate> = (props) => {
  let { search } = useLocation();
  let query = useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const form = useForm<FormStateValues>();
  const [section, setSection] = useState<string>("0");
  const [_case, setCase] = useState<ICase>();
  const [activeTimeTrack, setTimeTrack] = useState<ITimeTrack | null>();
  const [editDialogue, setEditDialogue] = useState(false);
  const [gramted, setGranted] = useState<boolean>(true);
  const [_communes, setCommunes] = useState<Array<ICommune>>([]);
  const { user: me } = useSelector(({ auth }: RootState) => auth);
  const permissions = usePermissions();

  const [type, setType] = useState(query.get("section") ?? "overview");

  const url_param = query.get("section");

  useEffect(() => {
    url_param && setType(url_param);
  }, [url_param]);

  useEffect(() => {
    let section = getIndex(type);
    if (section) {
      setSection(section.toString());
    }
  }, [type]);

  useEffect(() => {
    if (me?.role.slug == "citizen" || me?.role.slug == "social-worker")
      setGranted(false);
  }, []);

  useEffect(() => {
    communeService
      .getAll([
        {
          name: "pagination",
          value: "0",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: communes } = data;
        setCommunes(communes);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  const classes = useStyle();

  const intl = useIntl();

  useEffect(() => {
    caseService
      .getOne(id)
      .then((data) => {
        if (!("error" in data)) {
          let { data: _case } = data;
          setCase(_case);
        } else throw Error(data.error.message);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });

    if (me?.role?.slug === "super-admin" || me?.role?.slug === "moderator") {
      FixedSideButton.put(<StickyButtons />);
      FixedSideButton.show();
    }

    return () => FixedSideButton.clear();
  }, []);

  useEffect(() => {
    caseService
      .getTimeTracks(id, [
        { name: "unfinished", value: 1 },
        { name: "for", value: "tictac" },
      ])
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        let { data: timeTrack } = data;
        let unFinishedTrack = timeTrack.find((t) => !t.end_location);

        if (unFinishedTrack) setTimeTrack(unFinishedTrack);
        else setTimeTrack(null);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  const handleClose = () => {
    setEditDialogue(false);
    form.clear();
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    caseService
      .update(id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        setEditDialogue(false);
        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
        setCase(data.data);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const [_tab, setTab] = useState<string>("overview");

  useEffect(() => {
    FixedSideButton.put(<StickyButtons />);
    FixedSideButton.show();
  }, [_tab]);

  const [storeModal, setStoreModal] = useState<boolean>(false);

  const StickyButtons = () => {
    return (
      <>
        {/*  edit icon  */}
        <IconButton
          sx={{
            backgroundColor: "#0D99FF",
            position: "fixed",
            zIndex: 100,
            width: 40,
            height: 40,
            borderRadius: 0,
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            right: 0,
            top: 217,
            "&:hover": { backgroundColor: "#0D99FF" },
          }}
          onClick={() => setEditDialogue(true)}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.6223 2.31247L6.1556 10.7791C5.83222 11.1008 5.57586 11.4833 5.40135 11.9047C5.22684 12.3261 5.13764 12.7779 5.13893 13.234V14.1666C5.13893 14.3508 5.21209 14.5275 5.34233 14.6577C5.47256 14.7879 5.6492 14.8611 5.83337 14.8611H6.76601C7.2221 14.8624 7.67391 14.7732 8.09529 14.5987C8.51667 14.4242 8.89925 14.1678 9.22087 13.8444L17.6875 5.37775C18.0934 4.97092 18.3213 4.41975 18.3213 3.84511C18.3213 3.27048 18.0934 2.7193 17.6875 2.31247C17.2748 1.91795 16.7259 1.69778 16.1549 1.69778C15.584 1.69778 15.035 1.91795 14.6223 2.31247ZM16.7056 4.39581L8.23893 12.8625C7.84735 13.2517 7.3181 13.4707 6.76601 13.4722H6.52782V13.234C6.52927 12.6819 6.74835 12.1527 7.13754 11.7611L15.6042 3.29442C15.7525 3.15272 15.9498 3.07365 16.1549 3.07365C16.36 3.07365 16.5573 3.15272 16.7056 3.29442C16.8514 3.44061 16.9333 3.63865 16.9333 3.84511C16.9333 4.05158 16.8514 4.24961 16.7056 4.39581Z"
              fill="white"
            />
            <path
              d="M17.6389 7.90207C17.4547 7.90207 17.278 7.97524 17.1478 8.10547C17.0176 8.23571 16.9444 8.41234 16.9444 8.59652V12.0833H14.1666C13.6141 12.0833 13.0842 12.3028 12.6935 12.6935C12.3028 13.0842 12.0833 13.6141 12.0833 14.1667V16.9444H5.13885C4.58631 16.9444 4.05641 16.7249 3.66571 16.3342C3.27501 15.9435 3.05552 15.4136 3.05552 14.8611V5.13888C3.05552 4.58634 3.27501 4.05644 3.66571 3.66574C4.05641 3.27504 4.58631 3.05555 5.13885 3.05555H11.418C11.6022 3.05555 11.7788 2.98238 11.9091 2.85215C12.0393 2.72191 12.1125 2.54528 12.1125 2.3611C12.1125 2.17692 12.0393 2.00029 11.9091 1.87005C11.7788 1.73982 11.6022 1.66666 11.418 1.66666H5.13885C4.2183 1.66776 3.33576 2.03394 2.68483 2.68486C2.03391 3.33579 1.66773 4.21833 1.66663 5.13888L1.66663 14.8611C1.66773 15.7817 2.03391 16.6642 2.68483 17.3151C3.33576 17.966 4.2183 18.3322 5.13885 18.3333H13.0159C13.4721 18.3346 13.924 18.2454 14.3455 18.0709C14.767 17.8964 15.1497 17.6401 15.4715 17.3167L17.3159 15.4708C17.6394 15.1492 17.8958 14.7666 18.0704 14.3453C18.2451 13.9239 18.3344 13.4721 18.3333 13.016V8.59652C18.3333 8.41234 18.2601 8.23571 18.1299 8.10547C17.9997 7.97524 17.823 7.90207 17.6389 7.90207ZM14.4895 16.3347C14.2104 16.6132 13.8574 16.806 13.4722 16.8903V14.1667C13.4722 13.9825 13.5453 13.8058 13.6756 13.6756C13.8058 13.5454 13.9824 13.4722 14.1666 13.4722H16.8923C16.8064 13.8566 16.6139 14.209 16.3368 14.4889L14.4895 16.3347Z"
              fill="white"
            />
          </svg>
        </IconButton>
      </>
    );
  };

  return (
    <PermissionsGate section={"cases"} scope={"read"}>
      <>
        <form
          id={"case_update_form"}
          onSubmit={(e) => form.handleSubmit(e, handleSave)}
        >
          <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={editDialogue}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <Box
              sx={{
                position: "relative",
                backgroundColor: "#f5f5f5",
                boxShadow: "none",
                pr: 2,
              }}
            >
              <Toolbar>
                <Typography
                  sx={{ flex: 1, color: "#000000" }}
                  variant="h6"
                  component="div"
                >
                  <FormattedMessage
                    id={"CASE.EDIT"}
                    defaultMessage={"CASE.EDIT"}
                  />
                </Typography>
                <Button
                  form={"case_update_form"}
                  autoFocus
                  color="inherit"
                  type={"submit"}
                  sx={{
                    backgroundColor: "#0D99FF",
                    borderRadius: 2,
                    color: "#ffffff",
                  }}
                >
                  <FormattedMessage
                    id={"DIALOGUE.SAVE"}
                    defaultMessage={"DIALOGUE.SAVE"}
                  />
                </Button>
              </Toolbar>
            </Box>
            <DialogContent>
              {_case && (
                <EditDialogue
                  form={form}
                  saveUsers={() => {}}
                  case={_case}
                  communes={_communes}
                  {...props}
                />
              )}
            </DialogContent>
          </Dialog>
        </form>
        <Box>
          {!_case ? (
            <>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={6} lg={3} xl={3}>
                  <Skeleton width={"100%"} height={"120px"} />
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={3} xl={3}>
                  <Skeleton width={"100%"} height={"120px"} />
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={3} xl={3}>
                  <Skeleton width={"100%"} height={"120px"} />
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={3} xl={3}>
                  <Skeleton width={"100%"} height={"120px"} />
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container spacing={2}>
              {_case.users.map((user) => {
                return (
                  <Grid item sm={12} xs={12} md={6} lg={3} xl={3}>
                    <UserCard
                      _id={user._id}
                      file={user && user.file}
                      firstname={user.firstname}
                      lastname={user.lastname}
                      role={user.role}
                    />
                  </Grid>
                );
              })}
              {!_case ? (
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={3}
                  xl={3}
                  sx={{ pt: "0!important" }}
                >
                  <Skeleton width={"100%"} height={"117px"} />
                </Grid>
              ) : activeTimeTrack === undefined ||
                !gramted ||
                me?.role.slug === "social-worker" ? (
                ""
              ) : (
                <Grid item sm={12} xs={12} md={6} lg={3} xl={3}>
                  <TimeTrack
                    data={activeTimeTrack}
                    related={{ _id: id }}
                    focus_type={_case.focus_type?.title["en"] ?? ""}
                    focus_area={_case.focus_area?.title["en"] ?? ""}
                    type={"case"}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Box>

        <Box className={classes.tabPanel}>
          {!_case ? (
            <Skeleton width={"100%"} height={"300px"} />
          ) : (
            <TabContext value={section}>
              <Box
                sx={{
                  padding: "20px 0 0 0",
                }}
              >
                <Paper
                  sx={{
                    border: "1px solid #E6E6E6",
                    borderRadius: 2,
                  }}
                >
                  <TabList
                    variant="scrollable"
                    // scrollButtons="auto"
                    onChange={(e, newValue) => setSection(newValue)}
                    aria-label="basic tabs example"
                  >
                    {tabs.map((tab, index) => {
                      if (
                        !gramted &&
                        (tab.cy === "activity_payments" || tab.cy === "todos")
                      )
                        return;
                      if (
                        me?.role.slug === "social-worker" &&
                        (tab.cy === "activity_payments" ||
                          tab.cy === "todos" ||
                          tab.cy === "time_records")
                      )
                        return;

                      if (
                        permissions &&
                        !hasPermission(permissions, tab.cy, "read")
                      )
                        return;

                      return (
                        <Tab
                          label={
                            <Typography
                              fontSize={14}
                              color={
                                section === index.toString()
                                  ? "#0D99FF"
                                  : "#6D6D6D"
                              }
                              textTransform={"none"}
                            >
                              {intl.formatMessage({ id: tab.lang })}
                            </Typography>
                          }
                          value={index.toString()}
                          data-cy-id={tab.cy}
                          onClick={() => {
                            setTab(tab.cy);
                            history.push(`/cases/${id}?section=${tab.cy}`);
                          }}
                        />
                      );
                    })}
                  </TabList>
                </Paper>
              </Box>
              {tabs.map((tab, index) => {
                if (!gramted && tab.cy === "activity_payments") return;
                if (
                  me?.role.slug === "social-worker" &&
                  (tab.cy === "activity_payments" ||
                    tab.cy === "todos" ||
                    tab.cy === "time_records")
                )
                  return;
                return (
                  <TabPanel value={index.toString()}>
                    {tab.component(_case)}
                  </TabPanel>
                );
              })}
            </TabContext>
          )}
        </Box>
      </>
    </PermissionsGate>
  );
};

const tabs: Array<{
  lang: string;
  cy: any;
  component: (_case: ICase) => JSX.Element;
}> = [
  {
    lang: "CASE.OVERVIEW",
    cy: "cases",
    component: (_case: ICase) => (
      <Overview
        case={_case}
        tabChange={(section: string) => getIndex(section)}
      />
    ),
  },
  {
    lang: "CASE.INFORMATION",
    cy: "cases",
    component: (_case: ICase) => <Information {..._case} />,
  },
  {
    lang: "CASE.DOCUMENTS",
    cy: "documents",
    component: (_case: ICase) => <Document {..._case} />,
  },
  {
    lang: "CASE.TRADINGPLANS",
    cy: "case_tradingplans",
    component: (_case: ICase) => <Plan {..._case} />,
  },
  {
    lang: "CASE.STATUSREPORT",
    cy: "case_evalreports",
    component: (_case: ICase) => <Report {..._case} />,
  },
  {
    lang: "CASE.ACTIVTYPAYMENT",
    cy: "activity_payments",
    component: (_case: ICase) => <Finance {..._case} />,
  },
  {
    lang: "CASE.TODOS",
    cy: "todos",
    component: (_case: ICase) => <Todo {..._case} />,
  },
  {
    lang: "CASE.FEEDBACKS",
    cy: "feedbacks",
    component: (_case: ICase) => <Feedback {..._case} />,
  },
  {
    lang: "CASE.NOTES",
    cy: "notes",
    component: (_case: ICase) => <Note {..._case} />,
  },
  {
    lang: "CASE.TIMEREGISTRATION",
    cy: "case_timerecords",
    component: (_case: ICase) => <CaseTimeTracks {..._case} />,
  },
];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default Case;
