import React, { FC, useEffect, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import { caseService } from "app/services";
import { useParams } from "react-router";
import moment from "moment";
import TimeTrackStore from "../store/timeTrackStore";
import { useTable } from "app/hooks/subresource/useTable";
import { GridTable } from "app/partials/layout/GridTable";
import ShowTimeTrack from "./showTimeTrack";
import { useIntl } from "react-intl";
import { PermissionsGate } from "app/permission/PermissionsGate";
import ConfirmDialog from "app/partials/dialogues/confirmDialogue";
import { makeStyles } from "@material-ui/core";
import TimeTrackFilter from "./filters/timeTrackFilter";
import { ICase } from "../../../services/case.service";
import EditIcon from "../../../partials/icons/EditIcon";
import UserAvatar from "../../../partials/layout/UserAvatar";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const useStyles = makeStyles(() => ({
  container: {
    "& .MuiBox-root": {
      "& .MuiGrid-root": {
        // marginBottom: "4px!important",
        // marginTop: "4px!important",
      },
    },
  },
  f16: {
    fontSize: 16,
  },
  gray: {
    color: "gray",
  },
}));

const CaseTimeTracks: FC<ICase> = () => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const classes = useStyles();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [deleteConfrom, setDeleteConfirm] = useState<boolean>(false);
  const { user: me } = useSelector(({ auth }: RootState) => auth);

  const { data, xhrLoading, refresh, remove, updateQuery } = useTable<any>({
    fetch: caseService.getTimeTracks,
    delete: caseService.deleteTimeTrack,
    param: id,
    query: [
      {
        name: "per_page",
        value: 5,
      },
    ],
  });

  const [confirmId, setConfirmId] = useState<string | null>(null);
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);

  // @ts-ignore
  const columns: IColumns[] = [
    {
      field: "_id",
      headerName: "ID",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      minWidth: 50,
    },
    {
      field: "created_by",
      headerName: intl.formatMessage({ id: "STANDARD.CREATED_BY" }),
      renderCell: (params) => {
        return <UserAvatar user={params.row?.created_by} />;
      },
      minWidth: 100,
    },
    {
      headerName: intl.formatMessage({ id: "USERS.PLACE" }),
      field: "place",
      renderCell: (params) => {
        return (
          <div className="kt-timeline-v2 todo-timeline ps ps--active-y">
            <div className="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-warning" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  <Tooltip title={params.row?.start_location}>
                    <Typography variant="inherit">
                      {params.row?.start_location?.substring(0, 16) + ".."}
                    </Typography>
                  </Tooltip>
                </div>
              </div>
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-primary todo-timeline-circle-icon" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  <Tooltip title={params.row?.end_location}>
                    <Typography variant="inherit">
                      {params.row?.end_location
                        ? params.row?.end_location?.substring(0, 16) + ".."
                        : intl.formatMessage({
                            id: "NO_DATA",
                            defaultMessage: "No data",
                          })}
                    </Typography>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        );
      },
      minWidth: 200,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "date",
      renderCell: (params) => {
        return (
          <div className="kt-timeline-v2 todo-timeline ps ps--active-y">
            <div className="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-success" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  {moment
                    .unix(params.row?.start_date_time)
                    .utc()
                    .format("DD/MM/YYYY HH:mm")}
                </div>
              </div>
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-danger todo-timeline-circle-icon" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  {params.row?.end_date_time
                    ? moment
                        .unix(params.row?.end_date_time)
                        .utc()
                        .format("DD/MM/YYYY HH:mm")
                    : intl.formatMessage({
                        id: "NO_DATA",
                        defaultMessage: "No data",
                      })}
                </div>
              </div>
            </div>
          </div>
        );
      },
      minWidth: 150,
    },
    {
      field: "elapse",
      headerName: intl.formatMessage({ id: "CASE.TIMETRACK.ELLAPSETIME" }),
      renderCell: (params) => {
        let elapse = params.row?.end_date_time - params.row?.start_date_time;
        let second = elapse % 60;
        let minute = Math.floor((elapse % 3600) / 60);
        let hour = Math.floor((elapse % 86400) / 3600);
        let result = "";

        result += hour >= 1 ? hour + "h " : "";
        result += minute > 0 ? minute + "m " : "";
        result += second > 0 ? second + "s " : "";

        return result;
      },
      minWidth: 120,
    },
    {
      field: "type",
      headerName: intl.formatMessage({ id: "STANDARD.TYPE" }),
      renderCell: (params) => {
        return (
          params.row?.type?.title && (
            <Tooltip title={params.row?.type?.title[intl.locale]}>
              <Typography variant="inherit">
                {params.row?.type?.title[intl.locale].length > 10
                  ? params.row?.type?.title[intl.locale]?.substring(0, 10) +
                    ".."
                  : params.row?.type?.title[intl.locale]}
              </Typography>
            </Tooltip>
          )
        );
      },
      minWidth: 110,
    },
    {
      field: "platform",
      headerName: intl.formatMessage({ id: "STANDARD.PLATFORM" }),
      align: "center",
      renderCell: (params) => {
        let platform;

        // if (params.row?.platform === "app") {
        //   platform = `smartphone`;
        // } else if (params.row?.platform === "web") {
        platform = `computer ${classes.gray}`;
        // }

        return (
          <Tooltip
            title={
              params.row?.platform?.charAt(0).toUpperCase() +
              params.row?.platform?.slice(1)
            }
          >
            <i className={`fi fi-sr-${platform} ${classes.f16}`} />
          </Tooltip>
        );
      },
      minWidth: 50,
    },
    {
      field: "created_at",
      headerName: intl.formatMessage({ id: "STANDARD.CREATED_AT" }),
      valueGetter: (params) => {
        return moment
          .unix(params.row?._created_at)
          .local()
          .format("DD.MM.YYYY HH:mm");
      },
      minWidth: 130,
    },
    {
      field: "_updated_at",
      headerName: intl.formatMessage({ id: "STANDARD.UPDATED_AT" }),
      renderCell: (params) => {
        return params.row?._updated_at ? (
          moment
            .unix(params.row?._updated_at)
            .local()
            .format("DD.MM.YYYY HH:mm")
        ) : (
          <Tooltip title={intl.formatMessage({ id: "STANDARD.NOT_UPDATED" })}>
            <Typography variant="inherit">
              {intl.formatMessage({ id: "STANDARD.NOT_UPDATED" })}
            </Typography>
          </Tooltip>
        );
      },
      minWidth: 110,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"case_timerecords"} scope={"read"}>
              <>
                {moment
                  .unix(params.row?._created_at)
                  .local()
                  .isSame(moment(), "month") ? (
                  <IconButton
                    onClick={() => show(params.row?._id)}
                    data-cy-class={"case_time_track_show"}
                    data-cy-case-id={id}
                    data-cy-id={params.row?._id}
                  >
                    <EditIcon />
                  </IconButton>
                ) : (
                  (me?.role?.slug === "super-admin" ||
                    me?.role?.slug === "moderator") && (
                    <IconButton
                      onClick={() => show(params.row?._id)}
                      data-cy-class={"case_time_track_show"}
                      data-cy-case-id={id}
                      data-cy-id={params.row?._id}
                    >
                      <EditIcon />
                    </IconButton>
                  )
                )}
              </>
            </PermissionsGate>
            <PermissionsGate section={"case_timerecords"} scope={"delete"}>
              <>
                {moment
                  .unix(params.row?._created_at)
                  .local()
                  .isSame(moment(), "month") ? (
                  <IconButton
                    onClick={() => handleClick(params.row?._id)}
                    data-cy-class={"case_time_track_delete"}
                    data-cy-case-id={id}
                    data-cy-id={params.row?._id}
                  >
                    <DeleteIcon sx={{ color: "error.main" }} />
                  </IconButton>
                ) : (
                  (me?.role?.slug === "super-admin" ||
                    me?.role?.slug === "moderator") && (
                    <IconButton
                      onClick={() => handleClick(params.row?._id)}
                      data-cy-class={"case_time_track_delete"}
                      data-cy-case-id={id}
                      data-cy-id={params.row?._id}
                    >
                      <DeleteIcon sx={{ color: "error.main" }} />
                    </IconButton>
                  )
                )}
              </>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 110,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [timeTrackId, setTimeTrackId] = useState<string | null>(null);

  const show = (_id: string) => {
    setTimeTrackId(_id);
  };

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  const archive = (ids: string[]) => {
    caseService.deleteTimeTracks(id, { ids }).then((data) => {
      if ("error" in data) {
        throw new Error(data.error.message);
      }

      refresh();
    });
  };

  return (
    <Box className={classes.container}>
      <PermissionsGate section={"case_timerecords"} scope={"delete"}>
        <>
          {confirmId ? (
            <ConfirmDialog
              title="Delete time registration?"
              open={Boolean(confirmId)}
              setOpen={() => setConfirmId(null)}
              onConfirm={() => {
                remove(id, confirmId);
              }}
            >
              Are you sure you want to delete this case time registration?
            </ConfirmDialog>
          ) : (
            <></>
          )}
          {deleteConfrom ? (
            <ConfirmDialog
              title={`Delete ${selectedIds.length} time tracks ?`}
              open={deleteConfrom}
              setOpen={() => setDeleteConfirm(false)}
              onConfirm={() => {
                archive(selectedIds);
              }}
            >
              Are you sure you want to delete these activity payments?
            </ConfirmDialog>
          ) : (
            <></>
          )}
        </>
      </PermissionsGate>
      <PermissionsGate section={"case_timerecords"} scope={"read"}>
        {timeTrackId ? (
          <ShowTimeTrack
            _case={id}
            _id={timeTrackId}
            open={Boolean(timeTrackId)}
            setDialogue={() => setTimeTrackId(null)}
            onDone={refresh}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"case_timerecords"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          query={(queries) => updateQuery(queries)}
          deleteOption={true}
          _deleteAction={(ids) => {
            setSelectedIds(ids);
            setDeleteConfirm(true);
          }}
          filterToolbar={{
            actionPanel: (props: { open: boolean; close: () => void }) => {
              return (
                <TimeTrackStore
                  _id={id}
                  open={props.open}
                  setDialogue={props.close}
                  onDone={refresh}
                />
              );
            },
            filters: (props: { show: boolean }) => {
              return <TimeTrackFilter show={props.show} />;
            },
          }}
          search={(queries) => updateQuery(queries)}
          quickSearchToolbar={true}
        />
      </PermissionsGate>
    </Box>
  );
};

export default CaseTimeTracks;
