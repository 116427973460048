import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Snackbar from "app/widgets/Snackbar";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { accountingService } from "app/services";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "app/hooks/useForm";
import { makeStyles } from "@material-ui/core";
import { ITemplate } from "app/services/accounting.service";

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
  dropzone: {
    "& .container": {
      paddingRight: "0!important",
      paddingLeft: "0!important",
      "& div": {
        padding: "70px!important",
        borderColor: "#E6E6E6!important",
        borderRadius: 2,
        backgroundColor: "#E6E6E6",
        "& p": {
          marginBottom: "0px!important",
        },
      },
      "& aside": {
        display: "none",
      },
    },
  },
}));

interface IFormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
}

type FormStateValues = {
  title: string;
  description: string;
};

const ShowDraft: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [template, setTemplate] = useState<ITemplate>();
  const form = useForm<FormStateValues>();

  const handleClose = () => {
    props.setDialogue(false);
  };

  useEffect(() => {
    accountingService
      .getOneTemplate(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }
        const { data: template } = data;
        setTemplate(template);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    accountingService
      .updateTemplate(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        props.setDialogue(false);
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (template) {
      form.values.title = template.title;
      form.values.description = template.content;
    }
  }, [template]);

  return (
    <>
      {
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "210mm",
                height: "297mm",
                padding: "20mm",
                margin: "0 auto",
              },
            },
          }}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"TEMPLATE.SHOW"}
                  defaultMessage={"Show template"}
                />
              </Typography>
            </Toolbar>
          </Box>
          <DialogContent
            sx={{
              width: "210mm",
              height: "297mm",
              padding: "20mm",
              margin: "0 auto",
            }}
          >
            <form
              id={"template_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && template && (
                    <Box
                      sx={{
                        "& .table-bordered": { border: "unset!important" },
                        "& .table-bordered td": { border: "unset!important" },
                        backgroundColor: "#fff",
                        // padding: "35px 0",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "210mm",
                        // height: "297mm",
                        padding: "20mm",
                        margin: "0 auto",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: template.content,
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowDraft;
