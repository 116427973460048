import React, { Dispatch, FC, SetStateAction } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "app/widgets/uncontrolled";
import Snackbar from "app/widgets/Snackbar";
import { accountingService } from "app/services";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import copy from "clipboard-copy";

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  dropzone: {
    "& .container": {
      paddingRight: "0!important",
      paddingLeft: "0!important",
      "& div": {
        padding: "70px!important",
        borderColor: "#E6E6E6!important",
        borderRadius: 2,
        backgroundColor: "#E6E6E6",
        "& p": {
          marginBottom: "0px!important",
        },
      },
      "& aside": {
        display: "none",
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
  iconButtonAdd: {
    "&:hover": {
      backgroundColor: "#556EE6!important",
    },
  },
}));

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

export type FormStateValues = {
  title: string;
  description: string;
};

const initValues = {
  title: "",
  description: "",
} as FormStateValues;

const TemplateStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    accountingService
      .addTemplate(validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      {
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage id={"ADD"} defaultMessage={"Add"} />
              </Typography>
              <Button
                form={"template_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"template_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={3} lg={3} xl={3}>
                  {loaded ? (
                    <Box
                      onClick={() => {
                        copy("{ITEMS}").then(() => {
                          Snackbar.info("Copied");
                        });
                      }}
                      className={classes.input}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#80808036",
                        padding: "10px 0",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant={"body1"}>{`{ITEMS}`}</Typography>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={3} lg={3} xl={3}>
                  {loaded ? (
                    <Box
                      onClick={() => {
                        copy("{INVOICE_NUMBER}").then(() => {
                          Snackbar.info("Copied");
                        });
                      }}
                      className={classes.input}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#80808036",
                        padding: "10px 0",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant={"body1"}
                      >{`{INVOICE_NUMBER}`}</Typography>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={3} lg={3} xl={3}>
                  {loaded ? (
                    <Box
                      onClick={() => {
                        copy("{INVOICE_DATE}").then(() => {
                          Snackbar.info("Copied");
                        });
                      }}
                      className={classes.input}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#80808036",
                        padding: "10px 0",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant={"body1"}
                      >{`{INVOICE_DATE}`}</Typography>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={3} lg={3} xl={3}>
                  {loaded ? (
                    <Box
                      onClick={() => {
                        copy("{REG_NUMBER}").then(() => {
                          Snackbar.info("Copied");
                        });
                      }}
                      className={classes.input}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#80808036",
                        padding: "10px 0",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant={"body1"}
                      >{`{REG_NUMBER}`}</Typography>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={3} lg={3} xl={3}>
                  {loaded ? (
                    <Box
                      onClick={() => {
                        copy("{ACCOUNT_NUMBER}").then(() => {
                          Snackbar.info("Copied");
                        });
                      }}
                      className={classes.input}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#80808036",
                        padding: "10px 0",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant={"body1"}
                      >{`{ACCOUNT_NUMBER}`}</Typography>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={3} lg={3} xl={3}>
                  {loaded ? (
                    <Box
                      onClick={() => {
                        copy("{SOCIAL_WORKER}").then(() => {
                          Snackbar.info("Copied");
                        });
                      }}
                      className={classes.input}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#80808036",
                        padding: "10px 0",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant={"body1"}
                      >{`{SOCIAL_WORKER}`}</Typography>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={3} lg={3} xl={3}>
                  {loaded ? (
                    <Box
                      onClick={() => {
                        copy("{COMMUNE}").then(() => {
                          Snackbar.info("Copied");
                        });
                      }}
                      className={classes.input}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#80808036",
                        padding: "10px 0",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant={"body1"}>{`{COMMUNE}`}</Typography>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={3} lg={3} xl={3}>
                  {loaded ? (
                    <Box
                      onClick={() => {
                        copy("{COMMUNE_ADDRESS}").then(() => {
                          Snackbar.info("Copied");
                        });
                      }}
                      className={classes.input}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#80808036",
                        padding: "10px 0",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant={"body1"}
                      >{`{COMMUNE_ADDRESS}`}</Typography>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={3} lg={3} xl={3}>
                  {loaded ? (
                    <Box
                      onClick={() => {
                        copy("{COMMUNE_CVR}").then(() => {
                          Snackbar.info("Copied");
                        });
                      }}
                      className={classes.input}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#80808036",
                        padding: "10px 0",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant={"body1"}
                      >{`{COMMUNE_CVR}`}</Typography>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={3} lg={3} xl={3}>
                  {loaded ? (
                    <Box
                      onClick={() => {
                        copy("{COMMUNE_EAN}").then(() => {
                          Snackbar.info("Copied");
                        });
                      }}
                      className={classes.input}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#80808036",
                        padding: "10px 0",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant={"body1"}
                      >{`{COMMUNE_EAN}`}</Typography>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={3} lg={3} xl={3}>
                  {loaded ? (
                    <Box
                      onClick={() => {
                        copy("{SIBLINGS}").then(() => {
                          Snackbar.info("Copied");
                        });
                      }}
                      className={classes.input}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#80808036",
                        padding: "10px 0",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant={"body1"}>{`{SIBLINGS}`}</Typography>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={3} lg={3} xl={3}>
                  {loaded ? (
                    <Box
                      onClick={() => {
                        copy("{USER_ID}").then(() => {
                          Snackbar.info("Copied");
                        });
                      }}
                      className={classes.input}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#80808036",
                        padding: "10px 0",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant={"body1"}>{`{USER_ID}`}</Typography>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={3} lg={3} xl={3}>
                  {loaded ? (
                    <Box
                      onClick={() => {
                        copy("{DUE_DATE}").then(() => {
                          Snackbar.info("Copied");
                        });
                      }}
                      className={classes.input}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#80808036",
                        padding: "10px 0",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant={"body1"}>{`{DUE_DATE}`}</Typography>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={3} lg={3} xl={3}>
                  {loaded ? (
                    <Box
                      onClick={() => {
                        copy("{BILL_TO}").then(() => {
                          Snackbar.info("Copied");
                        });
                      }}
                      className={classes.input}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#80808036",
                        padding: "10px 0",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant={"body1"}>{`{BILL_TO}`}</Typography>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.TITLE",
                            defaultMessage: "Title",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Controls.Input
                        name={"title"}
                        label={""}
                        multiline={true}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.title = event.target.value;
                        }}
                        InputProps={{
                          // @ts-ignore
                          "data-cy": "todo_description",
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.CONTENT",
                            defaultMessage: "Content",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>

                      <Controls.TinyEditor
                        onChange={(data: any) => {
                          form.values.description = data;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default TemplateStore;
