import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IUser,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { queryToString } from "../utils/http";
import { ISetting } from "./setting.service";
import { ICase } from "./case.service";

export interface IStatistic {}

const INDEX = API_ROUTES.statistics;
const HOME_CONTRACTSTOTAL = API_ROUTES.contracts_total;
const HOME_TODOSTOTAL = API_ROUTES.todos_total;
const HOME_EVENTSTOTAL = API_ROUTES.events_total;
const HOME_CASESTOTAL = API_ROUTES.cases_total;
const HOME_OFFERTOTAL = API_ROUTES.offers_total;
const HOME_ACTIVE_TIME_TRACK_USERS = API_ROUTES.active_time_track_users;

export interface IContractTotal {
  accepted: { color: string; title: { [key: string]: string }; count: number };
  pending: { color: string; title: { [key: string]: string }; count: number };
  expired: { color: string; title: { [key: string]: string }; count: number };
}

export interface IOfferTotal {
  offer_accepted: {
    color: string;
    title: { [key: string]: string };
    count: number;
  };
  waiting_for_reply: {
    color: string;
    title: { [key: string]: string };
    count: number;
  };
  offer_canselled: {
    color: string;
    title: { [key: string]: string };
    count: number;
  };
}

export interface ITodoEventTotal {
  total: { [key in "expired" | "notexpired"]: number };
  data: {
    [key in "expired" | "notexpired"]: Array<{
      category: string;
      count: number;
      title: { [key: string]: string };
      color: string;
    }>;
  };
}
export interface ICaseTotal {
  total: number;
  data: Array<{
    focus_type: string;
    color: string;
    count: number;
    title: { [key: string]: string };
  }>;
  spentTime: number;
  spentMoney: number;
}

export interface ITimeTrackWithUsers {
  _id: string;
  start_date_time: number;
  end_date_time: number;
  start_location: string;
  end_location: string;
  case: ICase;
  type: ISetting;
  _created_at: string;
  created_by: IUser;
  _updated_at: string;
}

export interface IStatisticService {
  getAll(
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IStatistic> | IRestApiError>;
  getContractsTotal(
    query?: Array<IUrlQuery>
  ): Promise<
    IContractTotal | { error: string | { code: number; message: string } }
  >;
  getTodosTotal(
    query?: Array<IUrlQuery>
  ): Promise<
    ITodoEventTotal | { error: string | { code: number; message: string } }
  >;
  getEventsTotal(
    query?: Array<IUrlQuery>
  ): Promise<
    ITodoEventTotal | { error: string | { code: number; message: string } }
  >;
  getCasesTotal(
    query?: Array<IUrlQuery>
  ): Promise<
    ICaseTotal | { error: string | { code: number; message: string } }
  >;
  getOffersTotal(
    query?: Array<IUrlQuery>
  ): Promise<
    IOfferTotal | { error: string | { code: number; message: string } }
  >;
  getActiveTimeTrackWithUsers(
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ITimeTrackWithUsers> | IRestApiError>;
}

const statisticService: IStatisticService = {
  async getAll(query?: Array<IUrlQuery>) {
    let queriesString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async getContractsTotal(query?: Array<IUrlQuery>) {
    let queriesString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(HOME_CONTRACTSTOTAL)}${queriesString}`, "get");
  },

  async getTodosTotal(query?: Array<IUrlQuery>) {
    let queriesString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(HOME_TODOSTOTAL)}${queriesString}`, "get");
  },

  async getEventsTotal(query?: Array<IUrlQuery>) {
    let queriesString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(HOME_EVENTSTOTAL)}${queriesString}`, "get");
  },

  async getCasesTotal(query?: Array<IUrlQuery>) {
    let queriesString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(HOME_CASESTOTAL)}${queriesString}`, "get");
  },

  async getOffersTotal(query?: Array<IUrlQuery>) {
    let queriesString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(HOME_OFFERTOTAL)}${queriesString}`, "get");
  },

  async getActiveTimeTrackWithUsers(query?: Array<IUrlQuery>) {
    let queriesString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(HOME_ACTIVE_TIME_TRACK_USERS)}${queriesString}`,
      "get"
    );
  },
};

export { statisticService };
